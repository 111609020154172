import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styles from './checkbox.module.scss'

export const Checkbox = forwardRef(
  ({ error, id, name, isRequired, label, onChange, checked }, forwardedRef) => (
    <div className={classNames(styles.formCheck, !!error && styles.error)}>
      <input
        checked={checked}
        className={styles.checkbox}
        id={id}
        key={name}
        name={name}
        onChange={onChange}
        type="checkbox"
        ref={forwardedRef}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.label} htmlFor={id}>
        <span className={styles.icon}>
          <svg viewBox="0,0,20,20">
            <path d="M3 10 L 7 15 L 17 4" />
          </svg>
        </span>
        {!!label && (
          <span className={styles.text}>
            {label}
            {isRequired && '*'}{' '}
          </span>
        )}
      </label>
    </div>
  ),
)

Checkbox.defaultProps = {
  checked: false,
  error: undefined,
  label: '',
  isRequired: undefined,
  onChange: () => null,
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
