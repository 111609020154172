export const MetricUnit = {
  DISTANCE: 'distance',
  DURATION: 'duration',
  SPEED: 'speed',
}
Object.freeze(MetricUnit)

export const MetricSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}
Object.freeze(MetricSize)
