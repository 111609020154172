import PropTypes from 'prop-types'

const LocationType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
})

export default LocationType
