import { useCallback } from 'react'
import {
  getLengthFactor,
  getRelativePercentFromAbsolute,
  getRelativePercentFromCircleCoordinates,
  getWidthFromLength,
  getXfactorFromAngle,
  getYfactorFromAngle,
} from './geometry'

export const useSetTargetPosition = ({
  target,
  followField,
  setTargetCoords,
  setTargetAngle,
  setTargetWidth,
  setPropertyValues,
}) =>
  useCallback(
    (posX, posY, isAbsolute = true) => {
      if (!target?.style) {
        return
      }

      let relativePosition = {
        x: posX,
        y: posY,
      }

      if (isAbsolute) {
        relativePosition = getRelativePercentFromAbsolute({
          absX: posX,
          absY: posY,
          followField,
        })
      }

      const mouseDelta = {
        x: relativePosition.x - 0.5,
        y: relativePosition.y - 0.5,
      }

      const angle = Math.atan2(mouseDelta.y, mouseDelta.x)
      let length =
        Math.sqrt(mouseDelta.x * mouseDelta.x + mouseDelta.y * mouseDelta.y) /
        0.5

      let targetX
      let targetY
      if (length <= 1) {
        targetX = relativePosition.x
        targetY = relativePosition.y
      } else {
        length = 1
        const relativeTargetPosition = getRelativePercentFromCircleCoordinates({
          angle,
          length,
        })
        targetX = relativeTargetPosition.x
        targetY = relativeTargetPosition.y
      }

      const targetWidth = getWidthFromLength(length)
      setTargetWidth(targetWidth)

      if (!(targetWidth >= 2 * Math.PI)) {
        setTargetAngle(angle)
      }

      setPropertyValues(() => ({
        x: getXfactorFromAngle(angle) * getLengthFactor(length),
        y: getYfactorFromAngle(angle) * getLengthFactor(length),
      }))

      setTargetCoords(targetX, targetY)

      target.style.setProperty('--x', `${targetX * 100}%`)
      target.style.setProperty('--y', `${targetY * 100}%`)
    },
    [
      target?.style,
      followField,
      setTargetAngle,
      setTargetWidth,
      setPropertyValues,
      setTargetCoords,
    ],
  )
