/* eslint-disable no-console,no-unused-vars,max-len */
import { postPorsche } from '../api/index'
import { createWaypoint } from './routeSlice'

export async function postGpxFileForWaypoints(file) {
  // @TODO: Produced the following error:
  //
  // ```
  // {
  //   "timestamp": "2024-02-16T03:09:58.822Z",
  //   "status": 500,
  //   "path": "/error",
  //   "error": "IOException",
  //   "details": {
  //     "message": "org.apache.tomcat.util.http.fileupload.FileUploadException: the request was rejected because no multipart boundary was found"
  //   }
  // }
  // ```
  const response = await postPorsche('route-proposals/gpx', {
    body: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return {
    waypoints: [],
    polyline: '',
    decodedPolyline: [],
  }
}
