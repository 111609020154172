import { create } from 'zustand'
import { postImageWp } from '../../lib'

export const useImageStatusStore = create((set, get) => ({
  image: {
    loading: false,
    error: null,
    data: {
      file: null,
      response: null,
    },
  },
  postImage: async ({ file, sessionId }) => {
    get().loadImage({ file })

    let response
    try {
      response = await postImageWp({ file, sessionId })
      get().setImage({ response })
    } catch (error) {
      get().setImageError({ error })
    }

    return response
  },
  loadImage: ({ file }) =>
    set((state) => ({
      ...state,
      image: {
        loading: true,
        error: null,
        data: { file, response: null },
      },
    })),
  setImage: ({ response }) =>
    set((state) => ({
      ...state,
      image: {
        loading: false,
        error: null,
        data: { ...state.image.data, response },
      },
    })),
  setImageError: ({ file, error }) => {
    set((state) => ({
      ...state,
      image: {
        error,
        loading: false,
        data: {
          ...state.image.data,
          file: file || state.image.data.file,
          response: null,
        },
      },
    }))
  },
}))
