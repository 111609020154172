import PropTypes from 'prop-types'
import MetricType from '../Metric/MetricType'

const MetricsType = {
  lengthInMeters: PropTypes.shape(MetricType),
  drivingTimeSeconds: PropTypes.shape(MetricType),
  avgSpeedKmh: PropTypes.shape(MetricType),
}

export default MetricsType
