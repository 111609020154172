// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl from '!mapbox-gl'

const MAPBOX_DRIVING_API = 'https://api.mapbox.com/matching/v5/mapbox/driving'

export async function getClosestStreetCoordForWaypoint({ lat, lng }) {
  const getParams = [
    'radiuses=50;50',
    'geometries=geojson',
    `access_token=${mapboxgl.accessToken}`,
  ].join('&')

  // mapbox api expects a list of waypoints with lat and lng, but we only
  // want to send one waypoint, so we send the same one twice
  const data = await fetch(
    `${MAPBOX_DRIVING_API}/${lng},${lat};${lng},${lat}?${getParams}`,
  ).then((response) => response.json())

  return data.code === 'Ok'
    ? {
        lng: data.tracepoints[0].location[0],
        lat: data.tracepoints[0].location[1],
      }
    : { lat, lng }
}
