/* eslint-disable react/button-has-type */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './button.module.scss'

export const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}
Object.freeze(SIZE)

export const STYLE_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
}
Object.freeze(STYLE_TYPE)

export const TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
}
Object.freeze(TYPE)

export function Button({
  children,
  className,
  disabled,
  onClick,
  styleType = STYLE_TYPE.PRIMARY,
  size,
  type,
  iconOnly,
  ...props
}) {
  return (
    <button
      {...props}
      disabled={disabled}
      className={classnames(styles.button, styleType, className, {
        [styles.small]: size === SIZE.SMALL,
        [styles.primary]: styleType === STYLE_TYPE.PRIMARY,
        [styles.secondary]: styleType === STYLE_TYPE.SECONDARY,
        [styles.tertiary]: styleType === STYLE_TYPE.TERTIARY,
        [styles.iconOnly]: iconOnly,
      })}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  className: '',
  disabled: false,
  type: TYPE.BUTTON,
  onClick: () => null,
  styleType: STYLE_TYPE.PRIMARY,
  size: SIZE.LARGE,
  iconOnly: false,
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([SIZE.SMALL, SIZE.MEDIUM, SIZE.LARGE]),
  styleType: PropTypes.oneOf([
    STYLE_TYPE.PRIMARY,
    STYLE_TYPE.SECONDARY,
    STYLE_TYPE.TERTIARY,
  ]),
  type: PropTypes.oneOf([TYPE.BUTTON, TYPE.SUBMIT]),
  iconOnly: PropTypes.bool,
}
