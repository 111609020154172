import PropTypes from 'prop-types'
import { MinusNoCircleIcon, PlusNoCircleIcon } from '../../../assets/index'
import { ButtonControl } from './ButtonControl'
import classes from './Zoom.module.scss'

export function Zoom({ onZoomIn, onZoomOut }) {
  return (
    <div className={classes.container}>
      <ButtonControl onClick={onZoomIn}>
        <PlusNoCircleIcon />
      </ButtonControl>

      <ButtonControl onClick={onZoomOut}>
        <MinusNoCircleIcon />
      </ButtonControl>
    </div>
  )
}

Zoom.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
}
