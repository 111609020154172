import { useIntl } from 'react-intl-phraseapp'
import classes from './WizardGpxFailure.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

export function WizardGpxFailure() {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.container}>
      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.importing_headline',
            defaultMessage: 'Importing route failed',
          })}
        </WizardStepHeadline>
      </div>

      <p className={classes.loadingDescription}>
        {formatMessage({
          id: 'importdialog.file_import_failure_description',
          defaultMessage:
            // eslint-disable-next-line max-len
            'Das Importieren der Route ist fehlgeschlagen. Bitte versuchen Sie es spaeter or mit einer anderen Datei erneut.',
        })}
      </p>
    </div>
  )
}
