// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl from '!mapbox-gl'
// eslint-disable-next-line import/order
import MapboxGeocoder from '@mapbox/mapbox-sdk/services/geocoding'

export const getWaypointTitleFromLatLng = async ({ lat, lng }) => {
  const geocodingService = MapboxGeocoder({ accessToken: mapboxgl.accessToken })
  const response = await geocodingService
    .reverseGeocode({
      query: [lng, lat],
      limit: 1,
    })
    .send()

  if (
    response.body &&
    response.body.features &&
    response.body.features.length > 0
  ) {
    return response.body.features[0].place_name
  }
  throw new Error('No results found')
}
