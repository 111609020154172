import ReactDOM from 'react-dom/client'
import { ReduxProvider } from '../../../components/index'
import { MapControls } from '../MapControls/index'

export class MapboxControls {
  _container = null
  _map = null
  _reactRoot = null

  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapbox-controls')

    this._reactRoot = ReactDOM.createRoot(this._container).render(
      <ReduxProvider>
        <MapControls />
      </ReduxProvider>,
    )

    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._reactRoot.unmount()
    this._map = undefined
  }
}
