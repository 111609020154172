import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { InputError } from '../../../assets'
import styles from './textArea.module.scss'

export const TextArea = forwardRef(
  (
    {
      // TODO: Add autoCompleteType (compare to LocationType.js)
      autoComplete = 'on',
      className,
      error,
      id,
      name,
      isRequired,
      label,
      onBlur,
      onChange,
      placeholder,
      rows,
      value,
    },
    ref,
  ) => (
    <div className={classNames(styles.formGroup, className)}>
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-xs font-bold tracking-wider text-mono-dark-grey focus:text-default-blue"
        >
          {label}
          {isRequired && '*'}
        </label>
      )}
      <div className={styles.wrap}>
        <textarea
          className={classNames(styles.textarea, !!error && styles.error)}
          autoComplete={autoComplete}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          rows={rows}
          required={isRequired}
          value={value}
        />
        <div className={styles.icons}>
          <i className={classNames(styles.errorIcon, !error && 'hidden')}>
            <InputError />
          </i>
        </div>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  ),
)

TextArea.defaultProps = {
  autoComplete: 'on',
  className: '',
  error: undefined,
  isRequired: undefined,
  label: '',
  placeholder: undefined,
  onBlur: () => null,
  onChange: () => null,
  rows: 2,
  value: undefined,
}

TextArea.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'new-password', 'off']),
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
}
