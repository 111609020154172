import React from 'react'
import { Metric } from '../Metric'
import styles from './metrics.module.scss'
import MetricsType from './MetricsType'

export function Metrics({ lengthInMeters, drivingTimeSeconds, avgSpeedKmh }) {
  return (
    <div className={styles.metrics}>
      {!!lengthInMeters && <Metric {...lengthInMeters} />}
      {!!drivingTimeSeconds && <Metric {...drivingTimeSeconds} />}
      {!!avgSpeedKmh && <Metric {...avgSpeedKmh} />}
    </div>
  )
}

Metrics.defaultProps = {
  lengthInMeters: undefined,
  drivingTimeSeconds: undefined,
  avgSpeedKmh: undefined,
}

Metrics.propTypes = MetricsType
