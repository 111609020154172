import { configureStore as configureStoreOrig } from '@reduxjs/toolkit'
import pipe from 'lodash/fp/pipe'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './rootReducer'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    // We don't want to persist that state as it contains a mapbox instance as
    // well as state that has to be set every time the app boots
    'mapbox',
    'contextMenu',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const pastStateSlices = parseInt(
  process.env.REACT_APP_REDUX_DEVTOOLS_PAST_STATE_SLICES,
  10,
)
const futureStateSlices = parseInt(
  process.env.REACT_APP_REDUX_DEVTOOLS_FUTURE_STATE_SLICES,
  10,
)
const sanitizeCoordsState = (state) => {
  if (!state) return undefined

  const pastRouteStates = state.route.past
    .slice(-1 * pastStateSlices)
    .map((pastSlice) => ({
      ...pastSlice,
      routeData: {
        ...pastSlice.routeData,
        coords: `sanitized: ${
          pastSlice.routeData.coords?.length || 0
        } coordinates`,
      },
    }))

  const futureRouteStates = state.route.future
    .slice(-1 * futureStateSlices)
    .map((futureSlice) => ({
      ...futureSlice,
      routeData: {
        ...futureSlice.routeData,
        coords: `sanitized: ${
          futureSlice.routeData.coords?.length || 0
        } coordinates`,
      },
    }))

  const presentRouteState = {
    ...state.route.present,
    routeData: {
      ...state.route.present.routeData,
      coords: `sanitized: ${
        state.route.present.routeData.coords?.length || 0
      } coordinates`,
    },
  }

  const nextState = {
    ...state,
    route: {
      ...state.route,
      present: presentRouteState,
      past: pastRouteStates,
      future: futureRouteStates,
    },
  }

  return nextState
}

const sanitizeMapboxState = (state) => {
  if (!state) return state

  const { mapbox, ...nextState } = state
  return nextState
}

const stateSanitizer = pipe(sanitizeCoordsState, sanitizeMapboxState)

export const configureStore = (persist = true) => {
  const store = configureStoreOrig({
    devTools: {
      actionsDenylist: ['mapbox', 'waypointUserInput'],
      actionSanitizer: (action) => {
        let nextAction = action

        if (action.type === 'route/postRouteProposalTrip/fulfilled') {
          nextAction = {
            ...action,
            payload: {
              ...action.payload,
              proposedRoute: {
                ...action.payload.proposedRoute,
                geometry: {
                  ...action.payload.proposedRoute.geometry,
                  polyline: action.payload.proposedRoute.geometry.length,
                },
              },
            },
          }
        }

        if (action.type === 'persist/REHYDRATE') {
          nextAction = {
            ...action,
            payload: stateSanitizer(action.payload),
          }
        }

        return nextAction
      },
      stateSanitizer,
    },
    reducer: persist ? persistedReducer : rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
  })

  if (!persist) {
    return store
  }

  const persistor = persistStore(store)
  return { store, persistor }
}
