import { useIntl } from 'react-intl-phraseapp'
import classes from './WizardGpxLoading.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

function LoadingIcon() {
  return (
    <svg
      width="69"
      height="73"
      viewBox="0 0 69 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M64.4757 20.8706C55.7277 5.71868 36.353 0.527239 21.2011 9.27522C6.04916 18.0232 0.857719 37.3979 9.6057 52.5498C18.3537 67.7018 37.7284 72.8932 52.8803 64.1452"
        stroke="url(#paint0_linear_5214_32849)"
        strokeWidth="9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5214_32849"
          x1="65.1354"
          y1="-6.68987"
          x2="51.1066"
          y2="82.3764"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.214965" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function WizardGpxLoading() {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.container}>
      <div className={classes.loadingIcon}>
        <LoadingIcon />
      </div>

      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.importing_headline',
            defaultMessage: 'Importing route',
          })}
        </WizardStepHeadline>
      </div>

      <p className={classes.loadingDescription}>
        {formatMessage({
          id: 'importdialog.file_importing_description',
          defaultMessage:
            'Lehn dich einen Augenblick zurück, während wir deine Route vorbereiten.',
        })}
      </p>
    </div>
  )
}
