import { useCallback, useState } from 'react'

export const useTarget = () => {
  const [{ target, targetAngle, targetWidth, targetX, targetY }, setState] =
    useState({
      target: null,
      targetX: 0.5,
      targetY: 0.5,
      targetAngle: 0,
      targetWidth: 2 * Math.PI,
    })

  const setTargetCoords = useCallback(
    (x, y) =>
      setState((prevState) => ({ ...prevState, targetX: x, targetY: y })),
    [],
  )

  const setTarget = useCallback(
    (nextTarget) =>
      setState((prevState) => ({ ...prevState, target: nextTarget })),
    [],
  )

  const setTargetAngle = useCallback(
    (nextAngle) =>
      setState((prevState) => ({ ...prevState, targetAngle: nextAngle })),
    [],
  )

  const setTargetWidth = useCallback(
    (nextWidth) =>
      setState((prevState) => ({ ...prevState, targetWidth: nextWidth })),
    [],
  )

  return {
    target,
    targetX,
    targetY,
    targetAngle,
    targetWidth,
    setTarget,
    setTargetCoords,
    setTargetAngle,
    setTargetWidth,
  }
}
