import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postRouteProposal, routeSelectors } from '../lib'

export function useContinueLoadingFromPreviousSession() {
  const dispatch = useDispatch()
  const latLangWaypoints = useSelector(routeSelectors.getLatLangWaypoints)
  const coordinates = useSelector(routeSelectors.getCoords)
  const isLoadingCoords = useSelector(routeSelectors.getLoadingCoords)

  // initialize trip/roundtrip
  useEffect(
    () => {
      // When coordinates have been persisted, no need to fetch them
      // But only if loading is not true. There's a change that a user
      // reloads the page after having started to load a new route.
      // In that case we want to load the route
      if (coordinates && !isLoadingCoords) {
        return
      }

      const hasTwoOrMoreWaypoints = latLangWaypoints?.length > 1

      if (
        // When there are at least two waypoints
        hasTwoOrMoreWaypoints
      ) {
        dispatch(postRouteProposal())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
