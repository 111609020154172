import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { UnisizeEnd } from '../../assets'
import {
  getClosestStreetCoordForWaypoint,
  routeActions,
  useDispatchAndComputeRoute,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'

const { addNewEnd } = routeActions

export function NewDestinationButton({
  location,
  waypointTitle,
  onNewDestinationSet,
}) {
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formatMessage } = useIntl()

  const addNewEndAndClose = async () => {
    const nextLocation = await getClosestStreetCoordForWaypoint(location).catch(
      () => location,
    )

    dispatchAndComputeRoute(
      addNewEnd({
        lat: nextLocation.lat,
        lng: nextLocation.lng,
        title: waypointTitle,
      }),
    )

    onNewDestinationSet()
  }

  return (
    <Button size="small" className={classes.button} onClick={addNewEndAndClose}>
      <UnisizeEnd className={classes.buttonIcon} />
      <p className={classes.buttonText}>
        {formatMessage({
          id: 'contextmenu.new_destination',
          defaultMessage: 'new destination',
        })}
      </p>
    </Button>
  )
}

NewDestinationButton.defaultProps = {
  location: {
    lat: 0,
    lng: 0,
  },
}

NewDestinationButton.propTypes = {
  waypointTitle: PropTypes.string.isRequired,
  onNewDestinationSet: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}
