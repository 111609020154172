// eslint-disable-next-line import/order
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax,import/no-unresolved
import { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  contextMenuActions,
  contextMenuSelectors,
  getClosestStreetCoordForWaypoint,
  getWaypointTitleFromLatLng,
  mapboxSelectors,
  ReduxProvider,
  routeActions,
  routeSelectors,
  store,
  useDispatchAndComputeRoute,
} from '../../lib'
import { WaypointMarker } from './WaypointMarker/index'
import classes from './WaypointMarker/WaypointMarker.module.scss'

export function useDrawWaypointMarkers() {
  const dispatch = useDispatch()
  const map = useSelector(mapboxSelectors.getMapbox)
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const latLngWaypoints = useSelector(routeSelectors.getLatLangWaypoints)
  const isChangingTerrain = useSelector(mapboxSelectors.getIsChangingTerrain)
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()

  // if only one waypoint is set we don't want to compute the route
  const routeNotReadyToCompute = latLngWaypoints.length < 2

  // We don't want the useEffect to run forever recursively
  // as this value would trigger a rerun
  const renderedMarkers = useRef([])

  // if a waypoint is dragged and dropped, we need to update the route
  const onDragEnd = useCallback(
    async (event) => {
      event.target._element.classList.remove(classes.dragging)
      let location = event.target.getLngLat()
      try {
        location = await getClosestStreetCoordForWaypoint(location)
      } catch (e) {} // eslint-disable-line no-empty

      const waypointId = event.target.getElement().firstChild.dataset.id
      const action = routeActions.editWaypoint({
        id: waypointId,
        changes: {
          ...location,
          title: await getWaypointTitleFromLatLng(location),
        },
      })

      if (routeNotReadyToCompute) {
        dispatch(action)
      } else {
        dispatchAndComputeRoute(action)
      }

      dispatch(contextMenuActions.stopDragging())
    },
    [dispatchAndComputeRoute, dispatch, routeNotReadyToCompute],
  )

  useEffect(() => {
    // We don't want to trigger this while changing a terrain,
    // we need to redraw the markers AFTER the terrain has been drawn
    if (!isChangingTerrain && map) {
      const nextMarkers = waypoints
        .filter(({ lng, lat }) => lng && lat)
        .map(({ lng, lat, id }, index, array) => {
          const el = document.createElement('div')
          el.className = classes.waypointMarkerContainer
          const reactRoot = ReactDOM.createRoot(el)
          reactRoot.render(
            <ReduxProvider>
              <WaypointMarker
                id={id}
                index={index === array.length - 1 ? -1 : index}
                location={{ lng, lat }}
              />
            </ReduxProvider>,
          )

          const marker = new mapboxgl.Marker({
            element: el,
            draggable: false,
            clickTolerance: 3,
          })
            .setLngLat([lng, lat])
            .addTo(map)

          marker.getElement().addEventListener('mousedown', (event) => {
            const contextMenuOpen = contextMenuSelectors.getIsOpen(
              store.getState(),
            )

            // NOTE: This used to be `if (event.button === 0 && !loadingCoords) {`.
            // If there's an issue with drag'n'drop while loading a trip,
            // this is the way to re-enable that logic
            // Check if left mouse button was clicked
            if (event.button === 0 && !contextMenuOpen) {
              marker.setDraggable(true)
            }
          })

          marker.on('dragstart', () => {
            marker.getElement().classList.add(classes.isDragging)
            // mark contextmenu as dragging to stop it from opening up unasked
            dispatch(contextMenuActions.startDragging())
          })

          marker.on('dragend', (event) => {
            onDragEnd(event)
          })

          return { reactRoot, marker }
        })

      renderedMarkers.current.forEach(({ marker, reactRoot }) => {
        // Can't synchronously unmount during re-render. This is a fix,
        // not a workaround as react doesn't like two simultaneous rerender.
        // The setTimeout moves the unmount to the end of JS' event loop and
        // therefore out of the current, "synchronous" react rerender
        setTimeout(() => {
          /*
           * We need to unmount the react tree. "Otherwise, the components inside
           * the removed root won’t know to clean up and free up global resources
           * like subscriptions."
           * https://react.dev/reference/react-dom/client/createRoot#root-unmount
           */
          reactRoot.unmount()
          marker.remove()
        }, 0)
      })

      renderedMarkers.current = nextMarkers
    }
  }, [isChangingTerrain, waypoints, map, onDragEnd, dispatch])
}
