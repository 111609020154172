import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { IconButton, Icons } from '../../../components'
import { useImageStatusStore } from '../useImageStatusStore'
import { DescriptionField } from './DescriptionField'
import classes from './Form.module.scss'
import { NameField } from './NameField'
import { PrefillWithSuggestionsCheckbox } from './PrefillWithSuggestionsCheckbox'
import { PublishCheckbox } from './PublishCheckbox'
import { RouteImageSelector } from './RouteImageSelector'

const renderButtonIcon = ({ errors, isSubmitting, isSubmitSuccessful }) => {
  if (isSubmitting && !errors.length) {
    return Icons.LOADING
  }

  if (isSubmitSuccessful && !errors.length) {
    return Icons.COMPLETE
  }

  return Icons.DOWNLOAD
}

export function Form({ onSubmit }) {
  const imageStatus = useImageStatusStore((state) => state.image)
  const form = useFormContext()
  const { formatMessage } = useIntl()

  const { errors, isSubmitting /* , isSubmitSuccessful */ } = form.formState
  const isSubmitSuccessful = false

  return (
    <form className={classes.root} onSubmit={form.handleSubmit(onSubmit)}>
      <div className={classes.inputs}>
        <div className={classes.prefillWithSuggestion}>
          <PrefillWithSuggestionsCheckbox />
        </div>
        <NameField />
        <DescriptionField />
      </div>

      <div className={classes.routeImageSelector}>
        <RouteImageSelector />
      </div>

      <h4 className={classes.publish}>
        {formatMessage({ id: 'savedialog.publish', defaultMessage: 'publish' })}
      </h4>

      <div className={classes.checkBox}>
        <Controller
          control={form.control}
          name="publish"
          render={PublishCheckbox}
        />
      </div>

      <IconButton
        type="submit"
        disabled={imageStatus.loading || isSubmitting || isSubmitSuccessful}
        icon={renderButtonIcon({ errors, isSubmitting, isSubmitSuccessful })}
      >
        {formatMessage({ id: 'savedialog.save', defaultMessage: 'save' })}
      </IconButton>
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
