import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import styles from './metric.module.scss'
import { MetricSize, MetricUnit } from './MetricEnum'
import MetricType from './MetricType'

export function Metric({ size, label, unit, value }) {
  const { formatMessage } = useIntl()
  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
  })

  useEffect(() => {
    if (value && unit === MetricUnit.DURATION) {
      const durationMinutes = Math.round(value / 60)
      setDuration({
        hours:
          durationMinutes >= 1200
            ? Math.round(durationMinutes / 60)
            : Math.floor(durationMinutes / 60),
        minutes: durationMinutes >= 1200 ? 0 : durationMinutes % 60,
      })
    }
  }, [unit, value])

  const renderValue = (cDuration, cUnit, cValue) => {
    switch (cUnit) {
      case MetricUnit.DISTANCE:
        return `${new Intl.NumberFormat().format(Math.round(cValue / 1000))} km`
      case MetricUnit.DURATION:
        if (cDuration.hours === 0 && cDuration.minutes === 0) {
          return <span>0 min</span>
        }
        return (
          <>
            {cDuration.hours > 0 && <span>{cDuration.hours}h</span>}
            {cDuration.hours > 0 && cDuration.minutes > 0 && ' '}
            {cDuration.minutes > 0 && <span>{cDuration.minutes} min</span>}
            {}
          </>
        )

      case MetricUnit.SPEED:
        return `${cValue} km/h `
      default:
        return null
    }
  }

  const renderLabel = (cLabel, cUnit) => {
    let text = cLabel
    if (cLabel === undefined) {
      switch (cUnit) {
        case MetricUnit.DISTANCE:
          text = formatMessage({
            id: 'detailedroutefacts.distance',
            defaultMessage: 'distance',
          })
          break
        case MetricUnit.DURATION:
          text = formatMessage({
            id: 'detailedroutefacts.duration',
            defaultMessage: 'duration',
          })
          break
        case MetricUnit.SPEED:
          text = `Ø ${formatMessage({
            id: 'detailedroutefacts.speed',
            defaultMessage: 'speed',
          })}`
          break
        default:
          text = ''
      }
    }
    return text
  }

  return (
    <div className={styles.metric}>
      <p
        className={classNames(
          styles.value,
          size === MetricSize.MEDIUM && styles.medium,
          size === MetricSize.LARGE && styles.large,
        )}
      >
        {renderValue(duration, unit, value)}
      </p>
      <p className={styles.label}>{renderLabel(label, unit)}</p>
    </div>
  )
}

Metric.defaultProps = {
  size: 'small',
}

Metric.propTypes = MetricType
