import { useSelector } from 'react-redux'
import { mapboxSelectors, routeSelectors } from '../../lib'

/**
 * The proximity parameter is used to bias search results to a particular
 * area. In this case we're using the user's current location or the map's
 * center if the user hasn't allowed access to their location. As soon as
 * a start waypoint is set, we'll use that as the proximity.
 */
export function useProximity(type) {
  const map = useSelector(mapboxSelectors.getMapbox)
  const user = useSelector((state) => state.user)
  const waypointList = useSelector(routeSelectors.getWaypoints)

  if (waypointList[0].lng && type !== 'start') {
    return `${waypointList[0].lng}, ${waypointList[0].lat}`
  }

  if (user.location.lat && user.location.lng) {
    return `${user.location.lng}, ${user.location.lat}`
  }

  return map?.getCenter().toArray().join(', ')
}
