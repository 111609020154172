import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { postRouteProposal } from './routeSlice'

export const useDispatchAndComputeRoute = () => {
  const dispatch = useDispatch()

  return useCallback(
    async (action) => {
      await dispatch({
        ...action,
        meta: {
          ...action.meta, // works when undefined
          willPostRoute: true,
        },
      })

      return dispatch(postRouteProposal())
    },
    [dispatch],
  )
}
