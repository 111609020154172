import PropTypes from 'prop-types'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FullScreenIcon, FullScreenWhiteIcon } from '../../../assets/index'
import {
  getBrowserFullscreenElementProp,
  mapboxSelectors,
  mapboxSlice,
} from '../../../lib/index'
import { ButtonControl } from './ButtonControl'

export function FullScreen({ active, onFullScreenToggle }) {
  const dispatch = useDispatch()
  const isFullScreen = useSelector(mapboxSelectors.getIsFullScreen)

  useLayoutEffect(() => {
    document.onfullscreenchange = () => {
      const nextIsFullScreen =
        document[getBrowserFullscreenElementProp()] != null

      if (isFullScreen === nextIsFullScreen) {
        return
      }

      dispatch(
        mapboxSlice.actions.setIsFullScreen({
          isFullScreen: nextIsFullScreen,
        }),
      )
    }

    return () => {
      document.onfullscreenchange = undefined
    }
  })

  return (
    <div>
      <ButtonControl active={active} onClick={onFullScreenToggle}>
        {active ? <FullScreenWhiteIcon /> : <FullScreenIcon />}
      </ButtonControl>
    </div>
  )
}

FullScreen.propTypes = {
  active: PropTypes.bool.isRequired,
  onFullScreenToggle: PropTypes.func.isRequired,
}
