import classnames from 'classnames'
import PropTypes from 'prop-types'
import { ButtonControl } from './ButtonControl'
import classes from './ThreeD.module.scss'

export function ThreeD({ active, on3DToggle }) {
  return (
    <div
      className={classnames(classes.container, {
        [classes.active]: active,
      })}
    >
      <ButtonControl onClick={on3DToggle} active={active}>
        3D
      </ButtonControl>
    </div>
  )
}

ThreeD.propTypes = {
  active: PropTypes.bool.isRequired,
  on3DToggle: PropTypes.func.isRequired,
}
