/* eslint-disable react/button-has-type */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './closeButton.module.scss'

export function CloseButton({ disabled, onClick }) {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      disabled={disabled}
      className={classnames(styles.closeButton, {
        [styles.disabled]: disabled,
      })}
      type="button"
      onClick={onClick}
    />
  )
}

CloseButton.defaultProps = {
  disabled: false,
  onClick: () => null,
}

CloseButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
