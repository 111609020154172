import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import importDialogImage from '../../assets/images/import-dialog.png'
import { Modal } from '../../components'
import classes from './ImportDialog.module.scss'
import { WizardGmapsImport } from './WizardGmapsImport'
import { WizardGpxFailure } from './WizardGpxFailure'
import { WizardGpxImport } from './WizardGpxImport'
import { WizardGpxLoading } from './WizardGpxLoading'
import { WizardGpxSuccess } from './WizardGpxSuccess'
import { WizardStart } from './WizardStart'
import { WizardStep } from './WizardStep'

const WIZARD_STEPS = {
  start: 'START',
  importGmaps: 'IMPORT_GMAPS',
  importGpx: 'IMPORT_GPX',
  importGpxFailure: 'IMPORT_GPX_FAILURE',
  importGpxLoading: 'IMPORT_GPX_LOADING',
  importGpxSuccess: 'IMPORT_GPX_SUCCESS',
}

export function ImportDialog({ onClose }) {
  const [wizardStep, setWizardStep] = useState(WIZARD_STEPS.start)
  const isStep = (step) => step === wizardStep

  return createPortal(
    <Modal onClose={onClose}>
      <div className={classes.importDialog}>
        <div className={classes.image}>
          <img
            src={importDialogImage}
            alt="This is a depiction from picsum.photos"
          />
        </div>

        <div className={classes.importer}>
          <div className={classes.wizardSteps}>
            <WizardStep
              isPrevScreen={WIZARD_STEPS.start !== wizardStep}
              isCurrentScreen={isStep(WIZARD_STEPS.start)}
            >
              <WizardStart
                onImportGmapsClick={() =>
                  setWizardStep(WIZARD_STEPS.importGmaps)
                }
                onImportGpxClick={() => setWizardStep(WIZARD_STEPS.importGpx)}
              />
            </WizardStep>

            <WizardStep
              isPrevScreen={false} // last screen in this sequence
              isCurrentScreen={isStep(WIZARD_STEPS.importGmaps)}
              onBack={() => setWizardStep(WIZARD_STEPS.start)}
            >
              <WizardGmapsImport onClose={onClose} />
            </WizardStep>

            <WizardStep
              isPrevScreen={[
                WIZARD_STEPS.importGpxLoading,
                WIZARD_STEPS.importGpxFailure,
                WIZARD_STEPS.importGpxSuccess,
              ].includes(wizardStep)} // last screen in this sequence
              isCurrentScreen={isStep(WIZARD_STEPS.importGpx)}
              onBack={() => setWizardStep(WIZARD_STEPS.start)}
            >
              <WizardGpxImport
                onLoad={() => setWizardStep(WIZARD_STEPS.importGpxLoading)}
                onError={() => setWizardStep(WIZARD_STEPS.importGpxFailure)}
                onDone={() => setWizardStep(WIZARD_STEPS.importGpxSuccess)}
              />
            </WizardStep>

            <WizardStep
              isPrevScreen={[
                WIZARD_STEPS.importGpxSuccess,
                WIZARD_STEPS.importGpxFailure,
              ].includes(wizardStep)}
              isCurrentScreen={isStep(WIZARD_STEPS.importGpxLoading)}
            >
              <WizardGpxLoading />
            </WizardStep>

            <WizardStep
              isPrevScreen={false} // Can't go any further from here, just back
              isCurrentScreen={isStep(WIZARD_STEPS.importGpxFailure)}
              onBack={() => setWizardStep(WIZARD_STEPS.importGpx)}
            >
              <WizardGpxFailure />
            </WizardStep>

            <WizardStep
              isPrevScreen={false} // last screen in this sequence
              isCurrentScreen={isStep(WIZARD_STEPS.importGpxSuccess)}
            >
              <WizardGpxSuccess onClose={onClose} />
            </WizardStep>
          </div>
        </div>
      </div>
    </Modal>,
    document.body,
  )
}

ImportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}
