import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { TextArea } from '../../../components'
import classes from './NameField.module.scss'

export function NameField() {
  const form = useFormContext()
  const { formatMessage } = useIntl()
  const { errors } = form.formState

  return (
    <TextArea
      className={classes.nameField}
      name="name"
      id="name"
      label={formatMessage({
        id: 'savedialog.name',
        defaultMessage: 'name',
      })}
      error={errors?.name?.message}
      placeholder={formatMessage({
        id: 'savedialog.placeholder_question_name_of_road',
        defaultMessage: "What's the name of this road?",
      })}
      rows={2}
      {...form.register('name')}
    />
  )
}
