import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax,import/no-unresolved

// eslint-disable-next-line import/order
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getBrowserFullscreenElementProp } from '../fullscreen/index'
import { routeSelectors } from '../route'
import { terrains } from './terrains'

const name = 'mapbox'
const getRootState = (state) => state[name]
const initialTerrain = 'lightMode'

export const mapboxSelectors = {
  getMapboxContainer: (state) => getRootState(state).mapboxContainer,
  getMapbox: (state) => getRootState(state).mapbox,
  getTerrainsAdded: (state) => getRootState(state).terrainsAdded,
  getIsAddingTerrains: (state) => getRootState(state).isAddingTerrains,
  getIs3D: (state) => getRootState(state).is3D,
  getIsFullScreen: (state) => getRootState(state).isFullScreen,
  getIsChangingTerrain: (state) => getRootState(state).isChangingTerrain,
}

export const mapboxSlice = createSlice({
  name,
  initialState: {
    mapboxContainer: null,
    mapbox: null,
    terrainsAdded: false,
    isAddingTerrains: false,
    isChangingTerrain: false,
    is3D: false,
    isFullScreen: false,
  },
  reducers: {
    setMapbox: (state, { payload }) => {
      state.mapbox = payload.mapbox
      state.mapboxContainer = payload.mapboxContainer
    },
    setTerrainsAdded: (state) => {
      state.terrainsAdded = true
      state.isAddingTerrains = false
    },
    startAddingTerrains: (state) => {
      state.isAddingTerrains = true
    },
    setIs3D: (state, { payload }) => {
      state.is3D = payload.is3D
    },
    setIsFullScreen: (state, { payload }) => {
      state.isFullScreen = payload.isFullScreen
    },
  },
  extraReducers: (builder) => {
    // There's no way around the next eslint disable.
    // But it doesn't hurt in this case.
    // eslint-disable-next-line no-use-before-define
    builder.addCase(changeTerrain.pending, (state) => {
      state.isChangingTerrain = true
    })

    // There's no way around the next eslint disable.
    // But it doesn't hurt in this case.
    // eslint-disable-next-line no-use-before-define
    builder.addCase(changeTerrain.fulfilled, (state) => {
      state.isChangingTerrain = false
    })
  },
})

/**
 * Thunks
 * ======
 */
const createMapbox = createAsyncThunk(
  `${name}/createMapbox`,
  ({ containerEl, initialLng, initialLat, initialZoom }, { dispatch }) => {
    const mapbox = new mapboxgl.Map({
      container: containerEl,
      style: terrains[initialTerrain].url,
      center: [initialLng, initialLat],
      pitchWithRotate: false,
      zoom: initialZoom,
      logoPosition: 'bottom-right',
    })

    return new Promise((resolve) => {
      const callback = () => {
        dispatch(
          mapboxSlice.actions.setMapbox({
            mapbox,
            mapboxContainer: containerEl,
          }),
        )

        mapbox.off('style.load', callback)
        resolve(mapbox)
      }

      mapbox.on('style.load', callback)
    })
  },
)

const registerTerrains = createAsyncThunk(
  `${name}/registerTerrains`,
  (_, { dispatch, getState }) => {
    dispatch(mapboxSlice.actions.startAddingTerrains())
    let counter = 0
    const mapbox = mapboxSelectors.getMapbox(getState())

    const terrainList = Object.entries(terrains)
      // we don't need to load the terrain that was provided when creating a
      // new mapbox instance
      .filter(([id]) => id !== initialTerrain)
      .map(([, terrain]) => terrain)

    // We want to know when all terrain sources have been added
    const promise = new Promise((resolve) => {
      const eventHandler = () => {
        counter++
        if (counter === terrainList.length) {
          resolve()
        }
      }

      mapbox.on('styledata', eventHandler)
    })

    terrainList.forEach(({ id, type, url }) => {
      mapbox.addSource(id, { url, type })
    })

    return promise.then(() => {
      dispatch(mapboxSlice.actions.setTerrainsAdded())
    })
  },
)

const changeTerrain = createAsyncThunk(
  `${name}/changeTerrain`,
  ({ terrainId }, { getState }) => {
    const state = getState()
    const mapbox = mapboxSelectors.getMapbox(state)
    const styleLoadedPromise = new Promise((resolve) => {
      mapbox.on('styledata', resolve)
    })

    const cleanedTerrainId = terrainId.replace('terrain-', '')
    mapbox.setStyle(terrains[cleanedTerrainId].url)

    return styleLoadedPromise
  },
)

const resetCompass = createAsyncThunk(
  `${name}/resetCompass`,
  (_, { getState }) => {
    const mapbox = mapboxSelectors.getMapbox(getState())
    mapbox.easeTo({
      bearing: 0,
      duration: 1000,
      essential: true,
    })
  },
)

const zoomIn = createAsyncThunk(`${name}/zoomIn`, (_, { getState }) => {
  const mapbox = mapboxSelectors.getMapbox(getState())
  mapbox.zoomIn()
})

const zoomOut = createAsyncThunk(`${name}/zoomOut`, (_, { getState }) => {
  const mapbox = mapboxSelectors.getMapbox(getState())
  mapbox.zoomOut()
})

const zoomTo = createAsyncThunk(`${name}/zoomTo`, ({ zoom }, { getState }) => {
  const mapbox = mapboxSelectors.getMapbox(getState())
  mapbox.zoomTo(zoom)
})

const zoomToFullRoute = createAsyncThunk(
  `${name}/zoomToFullRoute`,
  (_, { getState }) => {
    // Go through all coordinates and set visible bounds of map to contain it
    const coordinates = routeSelectors.getCoords(getState())
    const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])

    coordinates.forEach((coordinate) => {
      bounds.extend(coordinate)
    })

    const mapbox = mapboxSelectors.getMapbox(getState())
    const convertedBounds = bounds.toArray()
    mapbox.fitBounds(convertedBounds, {
      padding: {
        top: 50,
        bottom: 130,
        left: 350,
        right: 350,
      },
      duration: 1500,
      essential: true,
    })
  },
)

const zoomToCoordinates = createAsyncThunk(
  `${name}/zoomToCoordinates`,
  ({ lat, lng }, { getState }) => {
    const mapbox = mapboxSelectors.getMapbox(getState())
    mapbox.flyTo({
      center: [lng, lat],
      zoom: 14,
      speed: 1.5,
      essential: true,
    })
  },
)

const zoomToWaypoint = createAsyncThunk(
  `${name}/zoomToWaypoint`,
  ({ waypoint }, { dispatch }) =>
    dispatch(
      zoomToCoordinates({
        lat: waypoint.lat,
        lng: waypoint.lng,
      }),
    ),
)

const toggle3D = createAsyncThunk(
  `${name}/toggle3D`,
  (_, { dispatch, getState }) => {
    const state = getState()
    const new3D = !mapboxSelectors.getIs3D(state)
    const mapbox = mapboxSelectors.getMapbox(state)

    mapbox.easeTo({
      pitch: new3D ? 65 : 0,
      duration: 1000,
    })

    dispatch(mapboxSlice.actions.setIs3D({ is3D: new3D }))
  },
)

const changeTo2D = createAsyncThunk(
  `${name}/changeTo2D`,
  (_, { dispatch, getState }) => {
    const state = getState()
    const mapbox = mapboxSelectors.getMapbox(state)

    mapbox.easeTo({
      pitch: 0,
      duration: 1000,
    })

    dispatch(mapboxSlice.actions.setIs3D({ is3D: false }))
  },
)

const toggleFullScreen = createAsyncThunk(
  `${name}/toggleFullScreen`,
  (_, { dispatch, getState }) => {
    const state = getState()
    const currentlyInFullScreen = mapboxSelectors.getIsFullScreen(state)
    const mapboxContainer = mapboxSelectors.getMapboxContainer(state)

    if (!currentlyInFullScreen) {
      mapboxContainer
        .requestFullscreen()
        .then(() => {
          const nextIsFullScreen =
            document[getBrowserFullscreenElementProp()] != null
          dispatch(
            mapboxSlice.actions.setIsFullScreen({
              isFullScreen: nextIsFullScreen,
            }),
          )
        })
        .catch(() => {
          dispatch(
            mapboxSlice.actions.setIsFullScreen({
              isFullScreen: false,
            }),
          )
        })
    } else {
      document.exitFullscreen()
    }
  },
)

export const mapboxActions = {
  ...mapboxSlice.actions,
  createMapbox,
  registerTerrains,
  changeTo2D,
  changeTerrain,
  resetCompass,
  zoomIn,
  zoomOut,
  zoomTo,
  zoomToCoordinates,
  zoomToFullRoute,
  zoomToWaypoint,
  toggle3D,
  toggleFullScreen,
}

export const mapboxReducer = mapboxSlice.reducer
mapboxSlice.selectors = mapboxSelectors
