// countryside
import countrysidePreview1 from '../../assets/images/route-preview/countryside/landscape-01.jpg'
import countrysidePreview2 from '../../assets/images/route-preview/countryside/landscape-02.jpg'
import countrysidePreview3 from '../../assets/images/route-preview/countryside/landscape-03.jpg'
import countrysideTumb1 from '../../assets/images/route-preview/countryside/landscape-thumb-01.png'
import countrysideTumb2 from '../../assets/images/route-preview/countryside/landscape-thumb-02.png'
import countrysideTumb3 from '../../assets/images/route-preview/countryside/landscape-thumb-03.png'
// forest
import forestPreview1 from '../../assets/images/route-preview/forest/forest-01.jpg'
import forestPreview2 from '../../assets/images/route-preview/forest/forest-02.jpg'
import forestPreview3 from '../../assets/images/route-preview/forest/forest-03.jpg'
import forestTumb1 from '../../assets/images/route-preview/forest/forest-thumb-01.png'
import forestTumb2 from '../../assets/images/route-preview/forest/forest-thumb-02.png'
import forestTumb3 from '../../assets/images/route-preview/forest/forest-thumb-03.png'
// mountain
import mountainPreview1 from '../../assets/images/route-preview/mountain/mountain-01.jpg'
import mountainPreview2 from '../../assets/images/route-preview/mountain/mountain-02.jpg'
import mountainPreview3 from '../../assets/images/route-preview/mountain/mountain-03.jpg'
import mountainTumb1 from '../../assets/images/route-preview/mountain/mountain-thumb-01.png'
import mountainTumb2 from '../../assets/images/route-preview/mountain/mountain-thumb-02.png'
import mountainTumb3 from '../../assets/images/route-preview/mountain/mountain-thumb-03.png'
// shore
import shorePreview1 from '../../assets/images/route-preview/shore/water-01.jpg'
import shorePreview2 from '../../assets/images/route-preview/shore/water-02.jpg'
import shorePreview3 from '../../assets/images/route-preview/shore/water-03.jpg'
import shoreTumb1 from '../../assets/images/route-preview/shore/water-thumb-01.png'
import shoreTumb2 from '../../assets/images/route-preview/shore/water-thumb-02.png'
import shoreTumb3 from '../../assets/images/route-preview/shore/water-thumb-03.png'

const countrysideIdOne = process.env.REACT_APP_ROUTE_IMAGE_COUNTRY_1
const countrysideIdTwo = process.env.REACT_APP_ROUTE_IMAGE_COUNTRY_2
const countrysideIdThree = process.env.REACT_APP_ROUTE_IMAGE_COUNTRY_3

const forestIdOne = process.env.REACT_APP_ROUTE_IMAGE_FOREST_1
const forestIdTwo = process.env.REACT_APP_ROUTE_IMAGE_FOREST_2
const forestIdThree = process.env.REACT_APP_ROUTE_IMAGE_FOREST_3

const mountainIdOne = process.env.REACT_APP_ROUTE_IMAGE_MOUNTAIN_1
const mountainIdTwo = process.env.REACT_APP_ROUTE_IMAGE_MOUNTAIN_2
const mountainIdThree = process.env.REACT_APP_ROUTE_IMAGE_MOUNTAIN_3

const shoreIdOne = process.env.REACT_APP_ROUTE_IMAGE_SHORE_1
const shoreIdTwo = process.env.REACT_APP_ROUTE_IMAGE_SHORE_2
const shoreIdThree = process.env.REACT_APP_ROUTE_IMAGE_SHORE_3

export const previewImages = {
  forest: {
    IMAGE_PRESET_1: {
      asset: forestPreview1,
      thumbnail: forestTumb1,
      id: forestIdOne,
    },
    IMAGE_PRESET_2: {
      asset: forestPreview2,
      thumbnail: forestTumb2,
      id: forestIdTwo,
    },
    IMAGE_PRESET_3: {
      asset: forestPreview3,
      thumbnail: forestTumb3,
      id: forestIdThree,
    },
    IMAGE_CUSTOM: {
      id: 'custom-image',
    },
  },

  mountain: {
    IMAGE_PRESET_1: {
      asset: mountainPreview1,
      thumbnail: mountainTumb1,
      id: mountainIdOne,
    },
    IMAGE_PRESET_2: {
      asset: mountainPreview2,
      thumbnail: mountainTumb2,
      id: mountainIdTwo,
    },
    IMAGE_PRESET_3: {
      asset: mountainPreview3,
      thumbnail: mountainTumb3,
      id: mountainIdThree,
    },
    IMAGE_CUSTOM: {
      id: 'custom-image',
    },
  },

  shore: {
    IMAGE_PRESET_1: {
      asset: shorePreview1,
      thumbnail: shoreTumb1,
      id: shoreIdOne,
    },
    IMAGE_PRESET_2: {
      asset: shorePreview2,
      thumbnail: shoreTumb2,
      id: shoreIdTwo,
    },
    IMAGE_PRESET_3: {
      asset: shorePreview3,
      thumbnail: shoreTumb3,
      id: shoreIdThree,
    },
    IMAGE_CUSTOM: {
      id: 'custom-image',
    },
  },

  countryside: {
    IMAGE_PRESET_1: {
      asset: countrysidePreview1,
      thumbnail: countrysideTumb1,
      id: countrysideIdOne,
    },
    IMAGE_PRESET_2: {
      asset: countrysidePreview2,
      thumbnail: countrysideTumb2,
      id: countrysideIdTwo,
    },
    IMAGE_PRESET_3: {
      asset: countrysidePreview3,
      thumbnail: countrysideTumb3,
      id: countrysideIdThree,
    },
    IMAGE_CUSTOM: {
      id: 'custom-image',
    },
  },
}

export const formDefaultValues = {
  name: '',
  description: '',
  publish: false,
  pictureId: countrysideIdOne,
}
