import PropTypes from 'prop-types'
import { ErrorSignIcon } from '../../../assets'
import { Button, CloseButton, SIZE } from '../../buttons'
import { Card } from '../Card'
import styles from './cardMessage.module.scss'

export function CardMessage({ buttonText, text, onClose, onClick }) {
  return (
    <div className={styles.dialog}>
      <Card styleName={styles.card}>
        <div className={styles.content}>
          <div className={styles.close}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={styles.icon}>
            <ErrorSignIcon />
          </div>
          <p>{text}</p>
          {!!buttonText && (
            <Button size={SIZE.SMALL} type="button" onClick={onClick}>
              {buttonText}
            </Button>
          )}
        </div>
      </Card>
    </div>
  )
}

CardMessage.defaultProps = {
  buttonText: '',
  onClose: () => {},
  onClick: () => {},
  text: '',
}

CardMessage.propTypes = {
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
}
