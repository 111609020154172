import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import classes from './IconWithCircularProgress.module.scss'

export function CircleCanvas({ percentage, options }) {
  const canvasRef = useRef(null)
  const [currentAngle, setCurrentAngle] = useState(-Math.PI / 2)
  const [animating, setAnimating] = useState(false)

  const circleStrokeStyle = '#fff'
  const circleLineCap = 'round'
  const circleLineWidth = 4

  const createCircle = useCallback(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    // canvas size
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height

    // positioning
    const x = canvas.width / 2
    const y = canvas.height / 2
    const radius = Math.min(canvasWidth, canvasHeight) / 2

    // calculate angles
    const startAngle = -Math.PI / 2 // start at 12 o'clock
    const endAngle = startAngle + (percentage / 100) * (2 * Math.PI)

    // animate percentage value
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    // draw animated circle
    context.beginPath()
    context.arc(x, y, radius, startAngle, currentAngle)
    context.strokeStyle = circleStrokeStyle
    context.lineCap = circleLineCap
    context.lineWidth = circleLineWidth
    context.stroke()

    const difference = endAngle - currentAngle
    if (Math.abs(difference) < 1) {
      setAnimating(() => false)
    } else {
      setCurrentAngle(
        (oldValue) =>
          parseFloat(oldValue) + difference / options.inertia / options.spring,
      )
    }
  }, [percentage, currentAngle, setAnimating, options])

  useEffect(() => {
    if (animating) {
      requestAnimationFrame(() => createCircle())
    }
  }, [animating, percentage, createCircle])

  useEffect(() => {
    setAnimating(() => true)
  }, [percentage, setAnimating])

  return (
    <canvas
      className={classes.circleCanvas}
      ref={canvasRef}
      width={30}
      height={30}
    />
  )
}

CircleCanvas.defaultProps = {
  percentage: 100,
}

CircleCanvas.propTypes = {
  percentage: PropTypes.number,
  options: PropTypes.shape({
    spring: PropTypes.number.isRequired,
    inertia: PropTypes.number.isRequired,
  }).isRequired,
}
