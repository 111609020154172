import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { ImageTile } from '../ImageTile'
import ImageTileType from '../ImageTile/ImageTileType'
import styles from './imageTileGroup.module.scss'

export function ImageTileGroup({ tiles, onChange, selected }) {
  return (
    <div className={classNames(styles.group)}>
      {tiles.map((tile) => (
        <div key={tile.id}>
          <ImageTile
            id={tile.id}
            active={selected === tile.id}
            src={tile.src}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  )
}

ImageTileGroup.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape(ImageTileType)).isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
