const DEFAULT_ERROR_MESSAGE = 'An unexpected error occured'

export function fetchApiPorsche(url, options) {
  const apiRoot = process.env.REACT_APP_API_ROOT_PORSCHE

  const mergedOptions = {
    headers: {
      /*
        TODO DONT do this in production as it would leak the api key.
        Instead inject your api key via proxy / wordpress etc.
      */
      'X-API-KEY': 'AZOzUBaD3GH12U5yq6nK984X0CLWT7',
      'Content-Type': 'application/json',
      ...options.headers,
    },
    ...options,
  }

  return fetch(`${apiRoot}/${url}`, mergedOptions)
    .then((res) => {
      if (res.status !== 200) {
        try {
          return res.json().then((responseBody) => {
            throw new Error(
              responseBody.details?.message ||
                responseBody.details?.body.message ||
                DEFAULT_ERROR_MESSAGE,
            )
          })
        } catch (e) {
          throw new Error(DEFAULT_ERROR_MESSAGE)
        }
      }

      return res
    })
    .then((res) => res.json())
}
