import PropTypes from 'prop-types'
import classes from './WizardStepHeadline.module.scss'

export function WizardStepHeadline({ children }) {
  return <h2 className={classes.importerHeadline}>{children}</h2>
}

WizardStepHeadline.propTypes = {
  children: PropTypes.node.isRequired,
}
