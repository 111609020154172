// For some reason the import order prettier rule gets confused here
/* eslint-disable prettier/prettier */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
/* eslint-enable prettier/prettier */
import { DragMe, EndIcon, StartIcon } from '../../../assets'
import { useContextMenu } from '../../../components'
import {
  contextMenuSelectors,
  mapboxSelectors,
  routeSelectors,
} from '../../../lib'
import classes from './WaypointMarker.module.scss'

export function WaypointMarker({ index, id, location }) {
  const map = useSelector(mapboxSelectors.getMapbox)
  const contextMenu = useContextMenu(map)
  const isContextMenuOpen = useSelector(contextMenuSelectors.getIsOpen)
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const waypoint = waypoints.find((cur) => cur.id === id)
  const active = useSelector(contextMenuSelectors.getWaypointId) === id
  const showIcon = index < 1
  const passBy = waypoint?.type === 'passBy'

  const handleClick = (e) => {
    const clickPosition = { x: e.clientX, y: e.clientY }
    e.preventDefault() // prevent the default behaviour when clicked
    e.stopPropagation() // prevent the map from being clicked

    if (!isContextMenuOpen) {
      contextMenu.open(location, clickPosition, id)
    }
  }

  return (
    <div
      className={classnames(classes.waypointMarker, {
        [classes.contextMenuOpen]: active,
        [classes.passBy]: passBy,
      })}
      data-id={id}
      onContextMenu={handleClick}
      onClick={handleClick}
      onTouchStart={handleClick}
    >
      {showIcon && (
        <div className={classes.icon}>
          {index === -1 && <EndIcon />}
          {index === 0 && <StartIcon />}
        </div>
      )}
      {!showIcon && <div className={classes.index}>{index}</div>}
      <DragMe className={classes.dragIndicator} />
    </div>
  )
}

WaypointMarker.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
}
