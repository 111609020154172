import { useCallback, useState } from 'react'

export const useFollower = (followField, targetAngle, targetWidth) => {
  const [{ follower, followerAngle, followerWidth }, setState] = useState({
    follower: null,
    followerAngle: targetAngle,
    followerWidth: targetWidth,
  })

  const setFollower = useCallback(
    (nextFollower) => {
      if (!followField || !nextFollower?.style) {
        return setState((prevState) => ({
          ...prevState,
          follower: null,
        }))
      }

      const followFieldRect = followField.getBoundingClientRect()
      const followerX = followFieldRect.width / 2
      const followerY = followerX

      nextFollower.style.setProperty('--x', `${followerX}px`)
      nextFollower.style.setProperty('--y', `${followerY}px`)

      return setState((prevState) => ({
        ...prevState,
        follower: nextFollower,
      }))
    },
    [followField],
  )

  const setFollowerAngle = useCallback((nextAngle) => {
    setState((prevState) => ({
      ...prevState,
      followerAngle: nextAngle,
    }))
  }, [])

  const setFollowerWidth = useCallback((nextWidth) => {
    setState((prevState) => ({
      ...prevState,
      followerWidth: nextWidth,
    }))
  }, [])

  return {
    follower,
    followerAngle,
    followerWidth,
    setFollower,
    setFollowerAngle,
    setFollowerWidth,
  }
}
