export function fetchApiWp(url, { sessionId, headers, ...options }) {
  const apiRoot = process.env.REACT_APP_API_ROOT_WP

  const mergedOptions = {
    headers: {
      sessionId,
      'content-type': 'application/json',
      ...headers,
    },
    ...options,
  }

  return fetch(`${apiRoot}/${url}`, mergedOptions).then((res) => res.json())
}
