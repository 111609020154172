import classnames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styles from './Card.module.scss'

// eslint-disable-next-line prefer-arrow-callback
export const Card = forwardRef(function Card({ styleName, children }, ref) {
  const classNames = classnames(styleName, styles.card)
  return (
    <div className={classNames} ref={ref}>
      {children}
    </div>
  )
})

Card.defaultProps = {
  children: null,
  styleName: '',
}

Card.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  styleName: PropTypes.string,
}
