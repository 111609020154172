import PropTypes from 'prop-types'
import { CountryIcon, ForestIcon, MountainIcon, ShoreIcon } from '../../assets'
import { environments } from '../../lib'
import { CircleCanvas } from './CircleCanvas'
import classes from './IconWithCircularProgress.module.scss'

export function IconWithCircularProgress({ variant, percentage, text }) {
  // variant settings
  let icon
  switch (variant) {
    case environments.FOREST:
      icon = <ForestIcon className={classes.icon} />
      break
    case environments.MOUNTAIN:
      icon = <MountainIcon className={classes.icon} />
      break
    case environments.COUNTRY:
      icon = <CountryIcon className={classes.icon} />
      break
    case environments.SHORE:
      icon = <ShoreIcon className={classes.icon} />
      break
    default:
      icon = <CountryIcon className={classes.icon} />
  }

  return (
    <div className={classes.container}>
      <div className={classes.circle}>
        {icon}
        <CircleCanvas
          percentage={percentage}
          options={{
            spring: 5,
            inertia: 2,
          }}
        />
      </div>
      <p className={classes.percentage}>{`${percentage} %`}</p>
      <p className={classes.variant}>{text !== '' ? text : variant}</p>
    </div>
  )
}

IconWithCircularProgress.defaultProps = {
  variant: environments.COUNTRY,
  percentage: 0,
  text: '',
}

IconWithCircularProgress.propTypes = {
  variant: PropTypes.oneOf(Object.values(environments)),
  percentage: PropTypes.number,
  text: PropTypes.string,
}
