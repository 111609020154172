import { postPorsche } from '../api/index'

export const postTrip = async (
  { routeSessionId, routePreferences, waypoints },
  { signal },
) => {
  // No need to extract anything. We could think about extracting the
  // "proposedRoute" object and either copy the id into it or ignore the id.
  const coords = await postPorsche('route-proposals/trips', {
    body: {
      correlation: { sessionId: routeSessionId },
      routePreferences: {
        ...routePreferences,
        // HOTFIX: Dieser Wert kommt eigentlich aus dem redux store, aber es
        // gibt keine Einstellmoeglichkeit und bisher war er immer auf true.
        // Damit er jetzt fuer alle, die bereits einen persistierten State
        // haben, zu `false` wechselt, wird der Wert hier einfach
        // ueberschrieben
        scenicRouting: false,
      },
      tripPreferences: { waypoints },
    },
    signal,
  })

  return coords
}
