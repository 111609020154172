/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types'
import {
  BtnCheckIcon,
  BtnChevronRightIcon,
  BtnDownloadIcon,
  BtnLoaderIcon,
} from '../../../assets'
import { Button } from '../Button'
import styles from './iconButton.module.scss'

export const Icons = {
  CHEVRON_RIGHT: 'chevronRight',
  COMPLETE: 'complete',
  DOWNLOAD: 'download',
  LOADING: 'loading',
}

export function IconButton({
  children,
  disabled,
  icon,
  onClick,
  size,
  styleType,
  type,
}) {
  function renderIcon(iconName) {
    switch (iconName) {
      case Icons.CHEVRON_RIGHT:
        return <BtnChevronRightIcon className={styles.chevronRight} />
      case Icons.DOWNLOAD:
        return <BtnDownloadIcon className={styles.download} />
      case Icons.LOADING:
        return <BtnLoaderIcon className={styles.loader} />
      case Icons.COMPLETE:
        return <BtnCheckIcon />
      default: {
        /* The program never reaches there because Icons has a certain set of values.
         When adding new value X, the compiler tells us that there’s something wrong with the function. */
        throw new Error(icon)
      }
    }
  }

  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      size={size}
      styleType={styleType}
    >
      {children}
      <span className={children && styles.iconWrap}>{renderIcon(icon)}</span>
    </Button>
  )
}

IconButton.defaultProps = {
  disabled: false,
  type: 'button',
  onClick: () => null,
  size: 'large',
  styleType: 'primary',
  children: null,
}

IconButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(['download', 'loading', 'complete', 'chevronRight'])
    .isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small']),
  styleType: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
}
