import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  mapboxActions,
  mapboxContants,
  routeActions,
  routeSelectors,
  store,
  useDispatchAndComputeRoute,
  useResetWaypoint,
} from '../../lib'

export function useRemoveWaypoint() {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const resetWaypoint = useResetWaypoint()

  // eslint-disable-next-line consistent-return
  return useCallback(
    async (location) => {
      const state = store.getState()
      const waypoints = routeSelectors.getWaypoints(state)

      // Only recompute route if the waypoint is an actual location
      if (!location.lng) {
        const action =
          waypoints.length > 2 ? routeActions.removeWaypoint : resetWaypoint

        return dispatch(action({ id: location.id }))
      }

      const hasStops = waypoints.length > 2
      const actionCreator = hasStops
        ? routeActions.removeWaypoint
        : resetWaypoint
      const action = actionCreator({ id: location.id })

      const latLangWaypoints = routeSelectors.getLatLangWaypoints(state)
      const hasActualStops = latLangWaypoints.length > 2
      if (hasActualStops) {
        await dispatchAndComputeRoute(action)
      }
      // We don't want to load a new route when there's only one stop
      else {
        // Does nothing if there's no request, so it's safe to use here
        dispatch(routeActions.abortPostingRouteProposal())
        dispatch(action)
      }

      const latLngWaypointsAfterRemoval = routeSelectors.getLatLangWaypoints(
        store.getState(),
      )

      // For some reason zooming doesn't work when we don't move it to the next
      // JS event loop message :shrug:
      await new Promise((resolve) => setTimeout(resolve, 0))

      if (latLngWaypointsAfterRemoval.length > 1) {
        dispatch(mapboxActions.zoomToFullRoute())
      } else if (latLngWaypointsAfterRemoval.length === 1) {
        dispatch(
          mapboxActions.zoomToWaypoint({
            waypoint: latLngWaypointsAfterRemoval[0],
          }),
        )
      } else {
        dispatch(mapboxActions.zoomTo({ zoom: mapboxContants.INITIAL_ZOOM }))
      }

      return undefined
    },
    [resetWaypoint, dispatch, dispatchAndComputeRoute],
  )
}
