import PropTypes from 'prop-types'
import { MapBlackIcon, MapWhiteIcon } from '../../../assets/index'
import { ButtonControl } from './ButtonControl'

export function MapStyle({ active, onToggleShowTerrainOptions }) {
  return (
    <div>
      <ButtonControl active={active} onClick={onToggleShowTerrainOptions}>
        {active ? <MapWhiteIcon /> : <MapBlackIcon />}
      </ButtonControl>
    </div>
  )
}

MapStyle.propTypes = {
  active: PropTypes.bool.isRequired,
  onToggleShowTerrainOptions: PropTypes.func.isRequired,
}
