import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import {
  contextMenuSelectors,
  mapboxActions,
  mapboxContants,
  routeActions,
  routeSelectors,
  store,
  useDispatchAndComputeRoute,
  useResetWaypoint,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'
import { UnisizeRemove } from '../../assets'

function useRemoveButtonLabel() {
  const { formatMessage } = useIntl()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const waypointId = useSelector(contextMenuSelectors.getWaypointId)

  if (waypointId === waypoints[0].id) {
    return formatMessage({
      id: 'contextmenu.removestart',
      defaultMessage: 'Remove start',
    })
  }

  if (waypointId === waypoints[waypoints.length - 1].id) {
    return formatMessage({
      id: 'contextmenu.removeDestination',
      defaultMessage: 'Remove destination',
    })
  }

  if (waypointId) {
    return formatMessage({
      id: 'contextmenu.removeStop',
      defaultMessage: 'Remove this stop',
    })
  }

  return formatMessage({
    id: 'contextmenu.remove',
    defaultMessage: 'remove',
  })
}

export function RemoveButton({ onAfterRemove }) {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const latLngWaypoints = useSelector(routeSelectors.getLatLangWaypoints)
  const waypointId = useSelector(contextMenuSelectors.getWaypointId)
  const resetWaypoint = useResetWaypoint()
  const label = useRemoveButtonLabel()

  const removeWaypointAndClose = async () => {
    const hasStops = waypoints.length > 2
    const actionCreator = hasStops ? routeActions.removeWaypoint : resetWaypoint
    const action = actionCreator({ id: waypointId })

    const hasActualStops = latLngWaypoints.length > 2
    if (hasActualStops) {
      await dispatchAndComputeRoute(action)
    }
    // We don't want to load a new route when there's only one stop
    else {
      // Does nothing if there's no request, so it's safe to use here
      dispatch(routeActions.abortPostingRouteProposal())
      dispatch(action)
    }

    const latLngWaypointsAfterRemoval = routeSelectors.getLatLangWaypoints(
      store.getState(),
    )

    onAfterRemove()

    // For some reason zooming doesn't work when we don't move it to the next
    // JS event loop message :shrug:
    await new Promise((resolve) => setTimeout(resolve, 0))

    if (latLngWaypointsAfterRemoval.length > 1) {
      dispatch(mapboxActions.zoomToFullRoute())
    } else if (latLngWaypointsAfterRemoval.length === 1) {
      dispatch(
        mapboxActions.zoomToWaypoint({
          waypoint: latLngWaypointsAfterRemoval[0],
        }),
      )
    } else {
      dispatch(mapboxActions.zoomTo({ zoom: mapboxContants.INITIAL_ZOOM }))
    }
  }

  return (
    <Button size="small" onClick={removeWaypointAndClose}>
      <UnisizeRemove className={classes.buttonIcon} />
      <p className={classes.buttonText}>{label}</p>
    </Button>
  )
}

RemoveButton.propTypes = {
  onAfterRemove: PropTypes.func.isRequired,
}
