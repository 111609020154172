import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch } from 'react-redux'
import { CircularLoadingGreyToWhite, CopyToClipboardIcon } from '../../assets'
import { Button } from '../../components'
import {
  getWaypointTitleFromLatLng,
  mapboxActions,
  postGmapsLinkForWaypoints,
  routeActions,
  tutorialActions,
  useDispatchAndComputeRoute,
} from '../../lib'
import classes from './WizardGmapsImport.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

function Input({ register, setValue }) {
  const { formatMessage } = useIntl()

  function insertFromClipboard() {
    return navigator.clipboard
      .readText()
      .then((clipText) => setValue('link', clipText))
      .catch((e) => {
        // eslint-disable-next-line no-alert
        alert(
          formatMessage(
            {
              id: 'importdialog.paste_link_error',
              defaultMessage:
                'Something went wrong when pasting from the clipboard: {message}',
            },
            { message: e.message },
          ),
        )
      })
  }

  return (
    <div className={classes.inputContainer}>
      <input
        {...register('link')}
        required
        className={classes.input}
        type="url"
      />

      <button
        className={classes.pasteButton}
        type="button"
        onClick={insertFromClipboard}
      >
        <CopyToClipboardIcon />

        <span className={classes.pasteButtonLabel}>
          {formatMessage({
            id: 'importdialog.link_input_paste_button',
            defaultMessage: 'Insert from clipboard',
          })}
        </span>
      </button>
    </div>
  )
}

Input.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}

async function patchCoordinatesWithTitle(coordinates) {
  return Promise.all(coordinates.map(getWaypointTitleFromLatLng)).then(
    (titles) =>
      coordinates.map((curWaypoint, index) => {
        const title = titles[index]
        return { ...curWaypoint, title }
      }),
  )
}

export function WizardGmapsImport({ onClose }) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formState, handleSubmit, register, setError, setValue } = useForm()
  const { isSubmitting, errors, isValid } = formState
  const { link: error } = errors

  const importRoute = async ({ link }) => {
    try {
      const coordinates = await postGmapsLinkForWaypoints(link)
      const waypoints = await patchCoordinatesWithTitle(coordinates)

      onClose()
      await dispatchAndComputeRoute(routeActions.setWaypoints({ waypoints }))
      dispatch(tutorialActions.complete())
      dispatch(mapboxActions.zoomToFullRoute())
    } catch (e) {
      setError('link', { type: 'custom', message: e.message })
    }
  }

  return (
    <form onSubmit={handleSubmit(importRoute)} className={classes.container}>
      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.import_headline',
            defaultMessage: 'Import from google maps',
          })}
        </WizardStepHeadline>
      </div>

      <label className={classes.label} htmlFor="link">
        <span className={classes.inputLabel}>
          {formatMessage({
            id: 'importdialog.link_input_label',
            defaultMessage: 'link',
          })}
        </span>

        <Input register={register} setValue={setValue} />
      </label>

      {!isValid && <div className={classes.error}>{error?.message}</div>}

      <p className={classes.linkImportDescription}>
        {formatMessage({
          id: 'importdialog.link_import_description',
          defaultMessage:
            // eslint-disable-next-line max-len
            'Füge einfach den Link von deiner Google-Route ein um deine Route zu importieren und Sie in unserem Routen editor zu inspizieren, feinzutunen und in die Roads App zu teilen.',
        })}
      </p>

      <div className={classes.actions}>
        <Button type="submit" disabled={isSubmitting}>
          <span className={classes.submitButtonLabel}>
            {formatMessage({
              id: 'importdialog.gmaps_submit_button',
              defaultMessage: 'Import',
            })}
          </span>

          {isSubmitting && (
            <CircularLoadingGreyToWhite className={classes.submitButtonIcon} />
          )}
        </Button>
      </div>
    </form>
  )
}

WizardGmapsImport.propTypes = {
  onClose: PropTypes.func.isRequired,
}
