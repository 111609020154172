import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { defaultLocale, locale, messages } from './i18n-config'

export function I18n({ children }) {
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
    >
      {children}
    </IntlProvider>
  )
}

I18n.propTypes = {
  children: PropTypes.node.isRequired,
}
