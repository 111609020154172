import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { waypointUserInputSelectors } from '../../lib'
import { InputAddress } from './InputAddress'
import { InputLatLng } from './InputLatLng'
import { useFocusWhenInitiallyDirty } from './useFocusWhenInitiallyDirty'

const LAT_LNG_REGEXP = /^([0-9]{1,2}([.][0-9]+)?),\s?([0-9]{1,3}([.][0-9]+)?)$/

export function Input({ index, location, tabIndex, type, onChange }) {
  const waypointUserInput = useSelector(waypointUserInputSelectors.getState)
  const temporaryWaypoint = waypointUserInput[location?.id]
  const { title } = temporaryWaypoint || location
  const hasValidLatLng = title.match(LAT_LNG_REGEXP)
  const isDirty = !!temporaryWaypoint
  const [inputRef, setInputRef] = useState(null)

  // We don't want to react to this value changing, so we use a ref instead
  const cursorPosition = useRef(-1)
  const setCursorPosition = useCallback((next) => {
    cursorPosition.current = next
  }, [])

  useFocusWhenInitiallyDirty(location, inputRef)
  useEffect(
    () => {
      const { current: position } = cursorPosition
      if (inputRef && position > -1) {
        inputRef.setSelectionRange(position, position)
      }
    },
    // We want to do this only when the component mounts,
    // no need to listen to cursorPosition changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef],
  )

  if (hasValidLatLng) {
    return (
      <InputLatLng
        index={index}
        location={location}
        tabIndex={tabIndex}
        type={type}
        onChange={onChange}
        title={title}
        isDirty={isDirty}
        setCursorPosition={setCursorPosition}
        inputRef={inputRef}
        setInputRef={setInputRef}
      />
    )
  }

  return (
    <InputAddress
      index={index}
      location={location}
      tabIndex={tabIndex}
      type={type}
      onChange={onChange}
      title={title}
      isDirty={isDirty}
      setCursorPosition={setCursorPosition}
      inputRef={inputRef}
      setInputRef={setInputRef}
    />
  )
}

Input.defaultProps = {
  location: {},
}

Input.propTypes = {
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}
