import mapboxgl from 'mapbox-gl'
import { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  contextMenuActions,
  contextMenuSelectors,
  I18n,
  ReduxProvider,
} from '../../lib'
import { ContextMenu } from './ContextMenu'
import classes from './ContextMenu.module.scss'

export function useContextMenu(mapbox) {
  const contextMenuRef = useRef(null)
  const coordsRef = useRef(null)
  const handleClickOutsideRef = useRef(null)
  const handleClickOutsideEndRef = useRef(null)
  const currentPopupRef = useRef(null)
  const dispatch = useDispatch()
  const isDragging = useSelector(contextMenuSelectors.getIsDragging)

  const removeContextMenu = useCallback(() => {
    if (currentPopupRef.current) {
      currentPopupRef.current.remove()
      currentPopupRef.current = null
    }
  }, [])

  // remove eventlistener when the context menu is closed
  const removeListenerHandleClickOutside = useCallback(() => {
    document.removeEventListener('mousedown', handleClickOutsideRef.current)
    document.removeEventListener('touchstart', handleClickOutsideRef.current)
    document.removeEventListener('mouseup', handleClickOutsideEndRef.current)
    document.removeEventListener('touchend', handleClickOutsideEndRef.current)
    handleClickOutsideRef.current = null
    handleClickOutsideEndRef.current = null
  }, [])

  const close = useCallback(() => {
    removeListenerHandleClickOutside()
    removeContextMenu()
    dispatch(contextMenuActions.contextMenuClose())
  }, [removeListenerHandleClickOutside, removeContextMenu, dispatch])

  // create a mapbox popup as context menu
  const open = useCallback(
    (location, clickPosition, id = '') => {
      if (!isDragging) {
        removeContextMenu()

        // if the click is less than 150px from the top, the context menu should be
        // displayed below the click position.
        // Sides and bottom are handled properly by mapbox.
        let anchor = null
        if (clickPosition.y < 150) {
          anchor = 'top'
        }

        const div = document.createElement('div')
        div.className = classes.contextmenu
        const root = ReactDOM.createRoot(div)
        root.render(
          <ReduxProvider>
            <I18n>
              <ContextMenu location={location} close={close} />
            </I18n>
          </ReduxProvider>,
        )
        contextMenuRef.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: '300px',
          anchor,
          offset: 10,
        })
          .setLngLat([location.lng, location.lat])
          .setDOMContent(div)
          .addTo(mapbox)

        currentPopupRef.current = contextMenuRef.current

        handleClickOutsideEndRef.current = function handleClickOutsideEnd(
          event,
        ) {
          event.stopPropagation()

          if (
            event.clientX !== coordsRef.current?.x ||
            event.clientY !== coordsRef.current?.y
          ) {
            // User dragged map
            return
          }

          if (contextMenuRef.current._container?.contains(event.target)) {
            return
          }

          contextMenuRef.current.remove()
          removeListenerHandleClickOutside()
          removeContextMenu()
          dispatch(contextMenuActions.contextMenuClose())
        }

        // remove eventlistener when the context menu is closed
        handleClickOutsideRef.current = function handleClickOutside(event) {
          coordsRef.current = {
            x: event.clientX,
            y: event.clientY,
          }

          document.addEventListener('mouseup', handleClickOutsideEndRef.current)
          document.addEventListener(
            'touchend',
            handleClickOutsideEndRef.current,
          )
        }

        dispatch(contextMenuActions.contextMenuOpen(id))

        // add eventlistener to close the context menu when the user clicks outside
        document.addEventListener('mousedown', handleClickOutsideRef.current)
        document.addEventListener('touchstart', handleClickOutsideRef.current)
      }
    },
    [
      removeListenerHandleClickOutside,
      mapbox,
      removeContextMenu,
      close,
      dispatch,
      isDragging,
    ],
  )

  // remove eventlistener when the component unmounts
  useEffect(
    () => () => {
      if (contextMenuRef.current) {
        contextMenuRef.current.remove()
      }
      if (handleClickOutsideRef.current) {
        removeListenerHandleClickOutside()
      }
    },
    [removeListenerHandleClickOutside],
  )

  return { open, close }
}
