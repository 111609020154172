import { postPorsche } from '../api/index'
import { createWaypoint } from './routeSlice'

export async function postGmapsLinkForWaypoints(url) {
  const response = await postPorsche('routing/gmaps/waypoints-from-url', {
    body: { url },
  })

  const waypoints = response.map((waypointData) =>
    createWaypoint({
      lat: waypointData.lat,
      lng: waypointData.lon,
      title: waypointData.name,
    }),
  )

  return waypoints
}
