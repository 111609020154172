import { createSlice } from '@reduxjs/toolkit'

const name = 'routeSession'

/**
 *
 * Selectors
 * =================================================
 *
 */
const getState = (state) => state[name]
const getRouteSessionId = (state) => getState(state).routeSessionId

// Needs to be its own state so we keep the new session id around when the user
// clicks the undo button after saving a route and closing the save dialog
// without resetting the state
export const routeSessionSlice = createSlice({
  name,
  initialState: {
    routeSessionId: crypto.randomUUID(),
  },
  reducers: {
    generateNewRouteSessionId: (state) => {
      state.routeSessionId = crypto.randomUUID()
    },
  },
})

export const routeSessionSelectors = { getRouteSessionId }
export const routeSessionReducer = routeSessionSlice.reducer
export const routeSessionActions = routeSessionSlice.actions
