import arrowSymbol from '../../assets/images/arrow_white.png'

export function displayRoute({
  map,
  routeId,
  prevRouteId,
  prev2RouteId,
  coordinates,
}) {
  // Remove invisible line two calls back
  const prev2LayerId = `routeLayer_${prev2RouteId}`
  const prev2LayerAlreadyAdded = map.getLayer(prev2LayerId)
  if (prev2LayerAlreadyAdded) {
    map.removeLayer(prev2LayerId)
    map.removeLayer(`${prev2LayerId}_arrows`)
  }

  // Fade out previous line
  const prevLayerId = `routeLayer_${prevRouteId}`
  const prevLayerAlreadyAdded = map.getLayer(prevLayerId)
  if (prevLayerAlreadyAdded) {
    map.setPaintProperty(prevLayerId, 'line-opacity', 0)
    map.setPaintProperty(prevLayerId, 'line-width', 2)
    map.setPaintProperty(`${prevLayerId}_arrows`, 'icon-opacity', 0)
  }

  // When resetting after saving, there's no new route to draw
  if (!routeId) {
    return
  }

  const sourceId = `route_${routeId}`
  const sourceLoadedAlready = map.getSource(sourceId)
  if (!sourceLoadedAlready) {
    map.addSource(sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              properties: {},
              coordinates,
            },
          },
        ],
      },
    })
  }

  // Add line layer for new route
  const layerId = `routeLayer_${routeId}`
  const layerAlreadyAdded = map.getLayer(layerId)
  if (!layerAlreadyAdded) {
    map.addLayer({
      id: layerId,
      type: 'line',
      source: sourceId,
      paint: {
        'line-color': '#ff2300',
        'line-width': 4,
        'line-opacity': 0,
        'line-opacity-transition': {
          duration: 1000,
          delay: 0,
        },
        'line-width-transition': {
          duration: 1000,
          delay: 0,
        },
        'line-color-transition': {
          duration: 500,
          delay: 500,
        },
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
    })

    if (!map.hasImage('arrow')) {
      map.loadImage(arrowSymbol, (error, image) => {
        if (error) throw error
        map.addImage('arrow', image)
      })
    }

    map.addLayer({
      id: `${layerId}_arrows`,
      type: 'symbol',
      source: sourceId,
      minzoom: 13,
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'icon-image': 'arrow',
        'icon-size': 0.07,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-opacity': 1,
        'icon-opacity-transition': {
          duration: 1000,
          delay: 0,
        },
      },
    })
  }

  map.setPaintProperty(layerId, 'line-opacity', 1)
  map.setPaintProperty(layerId, 'line-color', '#d5001d')
}
