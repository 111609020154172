import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl-phraseapp'
import { usePopper } from 'react-popper'
import { /* Card, */ Tutorial } from '../../components/index'
import classes from './Waypoints.module.scss'

// eslint-disable-next-line prefer-arrow-callback
export const WaypointsTutorial = forwardRef(function WaypointsTutorial(
  { show, inputRef, popperRef },
  ref,
) {
  const { formatMessage } = useIntl()
  const { styles, attributes } = usePopper(inputRef, popperRef, {
    placement: 'right-end',
  })

  return ReactDOM.createPortal(
    <div
      className={classes.tutorial}
      ref={ref}
      style={styles.popper}
      {...attributes.popper}
    >
      <Tutorial
        show={show}
        arrow="left"
        title={formatMessage({
          id: 'tutorial.waypoints.title',
          defaultMessage: 'Route definition',
        })}
      >
        {formatMessage({
          id: 'tutorial.waypoints.text',
          defaultMessage: 'First enter your waypoints for the route.',
        })}
      </Tutorial>
    </div>,
    document.body,
  )
})

WaypointsTutorial.defaultProps = {
  inputRef: { current: null },
  popperRef: { current: null },
}

WaypointsTutorial.propTypes = {
  show: PropTypes.bool.isRequired,
  inputRef: PropTypes.shape({
    current: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }),
  popperRef: PropTypes.shape({
    current: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }),
}
