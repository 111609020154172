import classnames from 'classnames'
import PropTypes from 'prop-types'
import classes from './ButtonControl.module.scss'

export function ButtonControl({ children, active, onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={classnames(classes.container, {
        [classes.active]: active,
        [classes.disabled]: disabled,
      })}
    >
      {children}
    </button>
  )
}

ButtonControl.defaultProps = {
  active: false,
  disabled: false,
}

ButtonControl.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}
