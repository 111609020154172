import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { Notch } from '../../assets/index'
import { Card, Tutorial } from '../../components'
import { routeSelectors, tutorialSelectors } from '../../lib/index'
import { AltitudeProfile } from './AltitudeProfile'
import { ColoredNotch } from './ColoredNotch'
import classes from './DetailedRouteFacts.module.scss'
import { PercentageData } from './PercentageData'

export function DetailedRouteFacts() {
  const { formatMessage } = useIntl()
  const showDetailedFactsTutorial = useSelector(
    tutorialSelectors.getShowDetailedFactsTutorial,
  )
  const [stateProperties, setStateProperties] = useState({
    avgSpeedKmh: 0,
    drivingTimeSeconds: 0,
    environmentRatios: {
      countryside: 0,
      forest: 0,
      mountain: 0,
      shore: 0,
    },
    lengthInMeters: 0,
  })
  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
  })

  const receivedProperties = useSelector(routeSelectors.getProperties)
  const altitudeValues = useSelector(routeSelectors.getElevationSamples)

  useEffect(() => {
    if (receivedProperties) {
      const { drivingTimeSeconds } = receivedProperties
      const durationMinutes = Math.round(drivingTimeSeconds / 60)
      setDuration({
        hours:
          durationMinutes >= 1200
            ? Math.round(durationMinutes / 60)
            : Math.floor(durationMinutes / 60),
        minutes: durationMinutes >= 1200 ? 0 : durationMinutes % 60,
      })
      setStateProperties(receivedProperties)
    }
  }, [receivedProperties, setStateProperties])

  return (
    <>
      <div className={classes.tutorial}>
        <Tutorial
          show={showDetailedFactsTutorial}
          title={formatMessage({
            id: 'tutorial.detailedroutefacts.title',
            defaultMessage: 'Route attributes',
          })}
          arrow="right"
        >
          {formatMessage({
            id: 'tutorial.detailedroutefacts.text',
            defaultMessage:
              'Here you will find all the data about your personal driving pleasure.',
          })}
        </Tutorial>
      </div>
      <Card styleName={classes.routeFacts}>
        <div className={classes.wrapper}>
          <div className={classes.head}>
            <div>
              <p className={classes.pBig}>
                {`${new Intl.NumberFormat().format(
                  Math.round(stateProperties.lengthInMeters / 1000),
                )} km`}
              </p>
              <p>
                {formatMessage({
                  id: 'detailedroutefacts.distance',
                  defaultMessage: 'distance',
                })}
              </p>
            </div>
            <div className={classes.duration}>
              <p className={classes.pBig}>
                {duration.hours > 0 && <span>{duration.hours} h</span>}
                {duration.minutes > 0 && <span>{duration.minutes} min</span>}
              </p>
              <p>
                {formatMessage({
                  id: 'detailedroutefacts.duration',
                  defaultMessage: 'duration',
                })}
              </p>
            </div>
            <div>
              <p
                className={classes.pBig}
              >{`${stateProperties.avgSpeedKmh} km/h `}</p>
              <p>
                {formatMessage({
                  id: 'detailedroutefacts.speed',
                  defaultMessage: 'speed',
                })}
              </p>
            </div>
          </div>
          <Notch className={classes.notch} />
          <ColoredNotch
            countryFactor={stateProperties.environmentRatios.countryside}
            forestFactor={stateProperties.environmentRatios.forest}
            mountainFactor={stateProperties.environmentRatios.mountain}
            shoreFactor={stateProperties.environmentRatios.shore}
          />
          <swiper-container
            pagination="true"
            resistanceRatio="0"
            class={classes.container}
          >
            <swiper-slide>
              <PercentageData
                country={stateProperties.environmentRatios.countryside}
                mountain={stateProperties.environmentRatios.mountain}
                forest={stateProperties.environmentRatios.forest}
                shore={stateProperties.environmentRatios.shore}
              />
            </swiper-slide>
            <swiper-slide>
              <AltitudeProfile altitudeValues={altitudeValues} />
            </swiper-slide>
          </swiper-container>
        </div>
      </Card>
    </>
  )
}
