import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { contextMenuSelectors, routeSelectors } from '../../lib'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'

export function Headline() {
  const { formatMessage } = useIntl()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const waypointId = useSelector(contextMenuSelectors.getWaypointId)

  // determine the headline
  if (waypointId === waypoints[0].id) {
    return (
      <p className={classes.add}>
        {formatMessage({
          id: 'contextmenu.start',
          defaultMessage: 'start',
        })}
      </p>
    )
  }

  if (waypointId === waypoints[waypoints.length - 1].id) {
    return (
      <p className={classes.add}>
        {formatMessage({
          id: 'contextmenu.destination',
          defaultMessage: 'destination',
        })}
      </p>
    )
  }

  if (waypointId) {
    const index = waypoints.findIndex((point) => point.id === waypointId)
    const headline = formatMessage({
      id: 'contextmenu.stop',
      defaultMessage: 'stop',
    })

    return (
      <p className={classes.add}>
        {headline} {index}
      </p>
    )
  }

  return (
    <p className={classes.add}>
      {formatMessage({
        id: 'contextmenu.new_waypoint',
        defaultMessage: 'new waypoint',
      })}
    </p>
  )
}
