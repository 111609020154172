import { fetchApiWp } from './fetchApiWp'

export function postImageWp({ file, sessionId }) {
  return fetchApiWp('imageupload', {
    headers: { 'content-type': file.type },
    method: 'POST',
    body: file,
    sessionId,
  })
}
