import classnames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { LoadingDisplay, LoadingError } from '../components/index'
import {
  initializeAccessToken,
  routeSelectors,
  tutorialSelectors,
} from '../lib/index'
import {
  DetailedRouteFacts,
  GlobalRouteActions,
  ImportDialog,
  Map,
  RoutePropertyController,
  SaveDialog,
  Waypoints,
} from '../sections'
import classes from './App.module.scss'
import { useContinueLoadingFromPreviousSession } from './useContinueLoadingFromPreviousSession'
import useParentUser from './useParentUser'
import { useUserSessionIdFromEnvVar } from './useUserSessionIdFromEnvVar'

initializeAccessToken()

export function App() {
  const [saveModalVisible, setSaveModalVisible] = useState(false)
  const coords = useSelector(routeSelectors.getCoords)
  const hasEnoughWaypoints =
    useSelector(routeSelectors.getLatLangWaypoints).length > 1
  const hasCoords = !!coords?.length
  const isShowingTutorial = !useSelector(tutorialSelectors.getIsDone)
  const showWaypointsTutorial = useSelector(
    tutorialSelectors.getShowWaypointsTutorial,
  )
  const showTutorialBackground =
    (hasCoords && hasEnoughWaypoints && isShowingTutorial) ||
    showWaypointsTutorial
  const [importDialogOpen, setImportDialogOpen] = useState(false)

  const closeSaveDialog = useCallback(() => setSaveModalVisible(false), [])

  useParentUser()
  useContinueLoadingFromPreviousSession()
  useUserSessionIdFromEnvVar()

  return (
    <div
      className={classnames(classes.app, {
        [classes.showingTutorial]: isShowingTutorial,
        has_route: hasCoords && hasEnoughWaypoints,
      })}
    >
      <Map />

      {showTutorialBackground && <div className={classes.tutorialBackground} />}

      {hasCoords && hasEnoughWaypoints && !isShowingTutorial && (
        <GlobalRouteActions
          isSaveDialogOpen={saveModalVisible}
          onSaveClick={() => setSaveModalVisible(true)}
        />
      )}

      <div className={classes.waypoints}>
        <Waypoints onImportClick={() => setImportDialogOpen(true)} />
      </div>

      <div
        className={classnames(classes.routePropertyController, {
          [classes.show]: hasCoords && hasEnoughWaypoints,
        })}
      >
        <RoutePropertyController />
      </div>

      <div
        className={classnames(classes.detailedRouteFacts, {
          [classes.show]: hasCoords && hasEnoughWaypoints,
        })}
      >
        <DetailedRouteFacts />
      </div>

      {!isShowingTutorial && saveModalVisible && (
        <SaveDialog onClose={closeSaveDialog} />
      )}

      {importDialogOpen && (
        <ImportDialog onClose={() => setImportDialogOpen(false)} />
      )}

      <LoadingRoutes />
      <LoadingRoutesError />
    </div>
  )
}

function LoadingRoutes() {
  const loadingCoords = useSelector(routeSelectors.getLoadingCoords)
  // We need to debounce the value as cancelling a previous request when
  // starting a new request would lead to a short flip of this value
  const [debouncedLoadingCoords] = useDebounce(loadingCoords, 50)
  const loadedOnce = useRef(false)

  // This is done so we can apply a "hidden" class to the loading and error
  // UI only after initialization. Otherwise we'd see the animation on app load
  // which is somewhat ugly
  if (loadingCoords && !loadedOnce.current) {
    loadedOnce.current = true
  }

  return (
    <div
      className={classnames(classes.loadingDisplay, {
        [classes.showLoadingUI]: debouncedLoadingCoords,
        [classes.notInitialized]: !loadedOnce.current,
      })}
    >
      <LoadingDisplay />
    </div>
  )
}

function LoadingRoutesError() {
  const loadingCoords = useSelector(routeSelectors.getLoadingCoords)
  // We need to debounce the value as cancelling a previous request when
  // starting a new request would lead to a short flip of this value
  const [debouncedLoadingCoords] = useDebounce(loadingCoords, 50)
  const loadingCoordsError = useSelector(routeSelectors.getLoadingCoordsError)
  const errorredOnce = useRef(false)

  // This is done so we can apply a "hidden" class to the loading and error
  // UI only after initialization. Otherwise we'd see the animation on app load
  // which is somewhat ugly
  if (loadingCoordsError && !errorredOnce.current) {
    errorredOnce.current = true
  }

  return (
    <div
      className={classnames(classes.loadingError, {
        [classes.showLoadingUI]: !debouncedLoadingCoords && loadingCoordsError,
        [classes.notInitialized]: !errorredOnce.current,
      })}
    >
      {/* @TODO: Determine what to show here */ ''}
      <LoadingError />
    </div>
  )
}
