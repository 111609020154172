import PropTypes from 'prop-types'
import { MetricSize, MetricUnit } from './MetricEnum'

const MetricType = {
  label: PropTypes.string,
  size: PropTypes.oneOf([
    MetricSize.SMALL,
    MetricSize.MEDIUM,
    MetricSize.LARGE,
  ]),
  unit: PropTypes.oneOf([
    MetricUnit.DISTANCE,
    MetricUnit.DURATION,
    MetricUnit.SPEED,
  ]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default MetricType
