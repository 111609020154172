import PropTypes from 'prop-types'
import { Area, AreaChart } from 'recharts'
import styles from './AltitudeChart.module.scss'

export function AltitudeChart({ data }) {
  return (
    <div className={styles.altitudeChart}>
      <AreaChart width={246} height={65} data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#D5001C" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#D5001C" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="heightInMeter"
          stroke="#D5001C"
          xAxisId="dateAxis"
          yAxisId="priceAxis"
          dot={false}
          fill="url(#colorUv)"
        />
      </AreaChart>
      {/* Why we do not use the CartesianGrid of Recharts to display the grid?
          The CartesianGrid lines tend to be displayed blurry on non retina displays,
          due to decimal based values of the x axis, that's why we draw custom grid
          line over the chart.
         */}
      <div className={styles.cartesianGrid}>
        <hr />
        <hr />
        <hr className={styles.bottom} />
      </div>
    </div>
  )
}

AltitudeChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      meterAlongRoute: PropTypes.number.isRequired,
      heightInMeter: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
