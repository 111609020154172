import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../lib'

const useParentUser = () => {
  const dispatch = useDispatch()

  const askParentForUser = useCallback(() => {
    window.top.postMessage(
      {
        messageType: 'requestUser',
        payload: null,
      },
      '*',
    )
  }, [])

  const handleUserMessageReceive = useCallback(
    (event) => {
      if (event.data?.messageType === 'activeUser') {
        dispatch(userActions.setNewUser({ roadsUser: event.data.payload }))
      }
    },
    [dispatch],
  )

  useEffect(() => {
    window.addEventListener('message', handleUserMessageReceive)

    askParentForUser()

    return () => {
      window.removeEventListener('message', handleUserMessageReceive)
    }
  })
}

export default useParentUser
