import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { HeightDifferenceIcon } from '../../../assets'
import { AltitudeChart } from './AltitudeChart'
import styles from './AltitudeProfile.module.scss'

export function AltitudeProfile({ altitudeValues }) {
  const [drivingDistance, setDrivingDistance] = useState(0)
  const { formatMessage } = useIntl()

  /**
   * @desc total calculation of the driven uphill and downhill road sections over the complete driving distance
   * @param samplingPoints
   * @returns {{downhill: {unit: string, value: number}, uphill: {unit: string, value: number}}}
   */
  const altitudeChangesCalculation = (samplingPoints) => {
    const points1 = samplingPoints.slice(0, -1)
    const points2 = samplingPoints.slice(1)
    let uphill = 0
    let downhill = 0
    for (let i = 0; i < points1.length; i++) {
      const current = points1[i]
      const next = points2[i]
      const delta = current.heightInMeter - next.heightInMeter
      if (current.heightInMeter > next.heightInMeter) {
        downhill += Math.abs(delta)
      } else {
        uphill += Math.abs(delta)
      }
    }
    return {
      uphill: {
        value: uphill,
        unit: 'm',
      },
      downhill: {
        value: Math.abs(downhill),
        unit: 'm',
      },
    }
  }

  const memorizedAltitudeChanges = useMemo(
    () => altitudeChangesCalculation(altitudeValues),
    [altitudeValues],
  )

  useEffect(() => {
    if (altitudeValues.length > 0) {
      const meterAlongRoute =
        altitudeValues[altitudeValues.length - 1]?.meterAlongRoute
      setDrivingDistance(Math.round(meterAlongRoute / 1000))
    }
  }, [altitudeValues])

  return (
    <div className={styles.altitudeProfile}>
      <div className={styles.header}>
        <p>
          {formatMessage({
            id: 'detailedroutefacts.altitude_profile',
            defaultMessage: 'altitude profile',
          })}
        </p>
        <span className={styles.facts}>
          <p>
            <HeightDifferenceIcon className={styles.icon} />
            {`${Math.round(
              memorizedAltitudeChanges.uphill.value +
                memorizedAltitudeChanges.downhill.value,
            )} m`}
          </p>
        </span>
      </div>
      <AltitudeChart data={altitudeValues} />
      <div className={styles.subline}>
        <p>0 km</p>
        <p>{`${drivingDistance} km`}</p>
      </div>
    </div>
  )
}

AltitudeProfile.defaultProps = {
  altitudeValues: [],
}

AltitudeProfile.propTypes = {
  altitudeValues: PropTypes.arrayOf(
    PropTypes.shape({
      meterAlongRoute: PropTypes.number.isRequired,
      heightInMeter: PropTypes.number.isRequired,
    }),
  ),
}
