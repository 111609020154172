import { createSlice } from '@reduxjs/toolkit'
import { routeActions } from '../route/index'

const name = 'waypointUserInput'
const getState = (state) => state[name]

export const waypointUserInputSlice = createSlice({
  name,
  initialState: {},
  reducers: {
    editWaypoint: (state, action) => {
      const { id, changes } = action.payload
      state[id] = { ...state[id], ...changes }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeActions.editWaypoint, (state, { payload }) => {
      delete state[payload.id]
    })

    builder.addCase(routeActions.removeWaypoint, (state, { payload }) => {
      delete state[payload.removeId]
    })

    builder.addCase(routeActions.setWaypoints, () => ({}))
  },
})

waypointUserInputSlice.selectors = { getState }

export const waypointUserInputSelectors = waypointUserInputSlice.selectors
export const waypointUserInputActions = waypointUserInputSlice.actions
export const waypointUserInputReducer = waypointUserInputSlice.reducer
