import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { IconWithCircularProgress } from '../../components'
import { environments } from '../../lib'
import classes from './DetailedRouteFacts.module.scss'

export function PercentageData({ country, mountain, forest, shore }) {
  const { formatMessage } = useIntl()
  return (
    <div className={classes.percentageData}>
      <div className={classes.icons}>
        <IconWithCircularProgress
          variant={environments.COUNTRY}
          percentage={Math.round(country * 100)}
          text={formatMessage({
            id: 'detailedroutefacts.country',
            defaultMessage: 'country',
          })}
        />
        <IconWithCircularProgress
          variant={environments.MOUNTAIN}
          percentage={Math.round(mountain * 100)}
          text={formatMessage({
            id: 'detailedroutefacts.mountain',
            defaultMessage: 'mountain',
          })}
        />
        <IconWithCircularProgress
          variant={environments.FOREST}
          percentage={Math.round(forest * 100)}
          text={formatMessage({
            id: 'detailedroutefacts.forest',
            defaultMessage: 'forest',
          })}
        />
        <IconWithCircularProgress
          variant={environments.SHORE}
          percentage={Math.round(shore * 100)}
          text={formatMessage({
            id: 'detailedroutefacts.shore',
            defaultMessage: 'shore',
          })}
        />
      </div>
    </div>
  )
}

PercentageData.propTypes = {
  country: PropTypes.number.isRequired,
  mountain: PropTypes.number.isRequired,
  forest: PropTypes.number.isRequired,
  shore: PropTypes.number.isRequired,
}
