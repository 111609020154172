import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import {
  contextMenuSelectors,
  routeActions,
  routeSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'
import { UnisizeStart } from '../../assets'

export function TogglePassByOverButton({ onToggle }) {
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formatMessage } = useIntl()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const id = useSelector(contextMenuSelectors.getWaypointId)
  const waypoint = waypoints.find((cur) => cur.id === id)
  const isPassOver = waypoint.type !== 'passBy'

  const toggleWaypointPassType = () => {
    dispatchAndComputeRoute(routeActions.toggleWaypointPassType({ id }))
    onToggle()
  }

  return (
    <Button
      size="small"
      className={classes.button}
      onClick={toggleWaypointPassType}
    >
      <UnisizeStart className={classes.buttonIcon} />
      <p className={classes.buttonText}>
        {isPassOver
          ? formatMessage({
              id: 'contextmenu.passBy',
              defaultMessage: 'Convert to pass-by',
            })
          : formatMessage({
              id: 'contextmenu.passOver',
              defaultMessage: 'Convert to pass-over',
            })}
      </p>
    </Button>
  )
}

TogglePassByOverButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
}
