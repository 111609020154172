import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import {
  routeActions,
  routeSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'
import { UnisizeStart } from '../../assets'

const { editWaypoint } = routeActions

export function StartButton({ location, waypointTitle, onStartSet }) {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formatMessage } = useIntl()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const hasEnd = !!waypoints.slice(-1)[0]?.title

  // use clicked location as start of the route. If it's a roundtrip,
  // compute the route immediately, otherwise just set the start.
  const setStart = () => {
    const [lng, lat] = [location.lng, location.lat]
    const idFirstWaypoint = waypoints[0]?.id
    const action = editWaypoint({
      id: idFirstWaypoint || 'start',
      changes: {
        lng,
        lat,
        title: waypointTitle,
      },
    })

    if (hasEnd) {
      dispatchAndComputeRoute(action)
    } else {
      dispatch(action)
    }

    onStartSet()
  }

  return (
    <Button size="small" className={classes.button} onClick={setStart}>
      <UnisizeStart className={classes.buttonIcon} />
      <p className={classes.buttonText}>
        {formatMessage({
          id: 'contextmenu.start',
          defaultMessage: 'start',
        })}
      </p>
    </Button>
  )
}

StartButton.defaultProps = {
  location: {
    lat: 0,
    lng: 0,
  },
}

StartButton.propTypes = {
  waypointTitle: PropTypes.string.isRequired,
  onStartSet: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}
