import classnames from 'classnames'
import PropTypes from 'prop-types'
import classes from './WaypointItem.module.scss'

function AutocompleteSuggestions({
  suggestions,
  suggestionIndex,
  onMouseEnter,
}) {
  return (
    <div className={classes.autocompleteContainer}>
      {suggestions.map((suggestion, index) => (
        <div
          key={suggestion.id}
          onMouseEnter={() => onMouseEnter(index)}
          className={classnames({
            [classes.autocompleteItem]: true,
            [classes.autocompleteItemSelected]: index === suggestionIndex,
          })}
        >
          {suggestion.place_name}
        </div>
      ))}
    </div>
  )
}

AutocompleteSuggestions.defaultProps = {
  suggestions: [],
  suggestionIndex: 0,
  onMouseEnter: () => {},
}

AutocompleteSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      place_name: PropTypes.string,
    }),
  ),
  suggestionIndex: PropTypes.number,
  onMouseEnter: PropTypes.func,
}

export default AutocompleteSuggestions
