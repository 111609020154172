import { useCallback } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import {
  Card,
  NavigationCircle,
  ToggleWithText,
  Tutorial,
} from '../../components'
import { ProfileChooser } from '../../components/ProfileChooser'
import { RangeSlider } from '../../components/RangeSlider'
import {
  environments,
  profiles,
  routeActions,
  routeSelectors,
  tutorialSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'
import styles from './RoutePropertyController.module.scss'

const profileNames = Object.keys(profiles)

const useProfile = () => {
  const customCurvatureRatio = useSelector(routeSelectors.getCurvatureRatio)
  const customSlopeRatio = useSelector(routeSelectors.getSlopeRatio)
  const customFeelGoodSpeedKmh = useSelector(routeSelectors.getFeelGoodSpeedKmh)
  const customProfile = {
    curvatureRatio: customCurvatureRatio,
    slopeRatio: customSlopeRatio,
    feelGoodSpeedKmh: customFeelGoodSpeedKmh,
  }
  const profileId = useSelector(routeSelectors.getProfileId)
  const profile = profileId === 'custom' ? customProfile : profiles[profileId]

  return { profile, profileId }
}

export function RoutePropertyController() {
  const { formatMessage } = useIntl()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const showRoutePropertyTutorial = useSelector(
    tutorialSelectors.getShowRoutePropertyTutorial,
  )
  const preferredEnvironments = useSelector(
    routeSelectors.getPreferredEnvironments,
  )
  const { profile, profileId } = useProfile()
  const profileIndex =
    profileId === 'custom'
      ? profileNames.length
      : profileNames.indexOf(profileId)

  const toggleEnvironment = (environment) => {
    const payload = {
      preferredEnvironment: environment,
    }
    if (preferredEnvironments.includes(environment)) {
      dispatchAndComputeRoute(routeActions.removePreferredEnvironment(payload))
    } else {
      dispatchAndComputeRoute(routeActions.addPreferredEnvironment(payload))
    }
  }

  const updateCurvatureAndSlope = ({ x, y }) => {
    x = 1 - (x + 1) / 2
    y = 1 - (y + 1) / 2

    // This might trigger two requests of which the first one will be cancelled
    // right away. That's because changing an existing profile will also cause
    // a profile change as this will cause the profile to switch to "custom"
    dispatchAndComputeRoute(
      routeActions.setCurvatureAndSlopeAndSpeed({
        ...profile,
        curvatureRatio: x,
        slopeRatio: y,
      }),
    )
  }

  const updateFeelGoodSpeed = (speedFactor) => {
    const speed = Math.round(speedFactor * 170 + 30)

    // This might trigger two requests of which the first one will be cancelled
    // right away. That's because changing an existing profile will also cause
    // a profile change as this will cause the profile to switch to "custom"
    dispatchAndComputeRoute(
      routeActions.setCurvatureAndSlopeAndSpeed({
        ...profile,
        feelGoodSpeedKmh: speed,
      }),
    )
  }

  const onProfileChange = useCallback(
    (nextProfileIndex) => {
      const profileIds = Object.keys(profiles)
      const nextProfileId =
        nextProfileIndex === profileIds.length
          ? 'custom'
          : profileIds[nextProfileIndex]

      dispatchAndComputeRoute(
        routeActions.setProfileId({
          profileId: nextProfileId,
        }),
      )
    },
    [dispatchAndComputeRoute],
  )

  return (
    <div>
      <div className={styles.tutorial}>
        <Tutorial
          show={showRoutePropertyTutorial}
          title={formatMessage({
            id: 'tutorial.routepropertycontroller.title',
            defaultMessage: 'Driving preferences',
          })}
          arrow="right"
        >
          {formatMessage({
            id: 'tutorial.routepropertycontroller.text',
            defaultMessage:
              'Configure the route and personalize it according to your wishes and ideas.',
          })}
        </Tutorial>
      </div>

      <Card styleName={styles.routePropertyController}>
        <div className={styles.buttons}>
          <ToggleWithText
            variant={environments.COUNTRY}
            active={preferredEnvironments.includes(environments.COUNTRY)}
            onClick={() => toggleEnvironment(environments.COUNTRY)}
          >
            {formatMessage({
              id: 'routepropertycontroller.countryside',
              defaultMessage: 'countryside',
            })}
          </ToggleWithText>
          <ToggleWithText
            variant={environments.MOUNTAIN}
            active={preferredEnvironments.includes(environments.MOUNTAIN)}
            onClick={() => toggleEnvironment(environments.MOUNTAIN)}
          >
            {formatMessage({
              id: 'routepropertycontroller.mountain',
              defaultMessage: 'mountain',
            })}
          </ToggleWithText>
          <ToggleWithText
            variant={environments.FOREST}
            active={preferredEnvironments.includes(environments.FOREST)}
            onClick={() => toggleEnvironment(environments.FOREST)}
          >
            {formatMessage({
              id: 'routepropertycontroller.forest',
              defaultMessage: 'forest',
            })}
          </ToggleWithText>
          <ToggleWithText
            variant={environments.SHORE}
            active={preferredEnvironments.includes(environments.SHORE)}
            onClick={() => toggleEnvironment(environments.SHORE)}
          >
            {formatMessage({
              id: 'routepropertycontroller.shore',
              defaultMessage: 'shore',
            })}
          </ToggleWithText>
        </div>
        <div className={styles.ratioControllers}>
          <ProfileChooser
            activeIndex={profileIndex}
            onProfileChange={onProfileChange}
          />
          {profile && (
            <NavigationCircle
              values={{
                x: 1 - 2 * profile.curvatureRatio,
                y: 1 - 2 * profile.slopeRatio,
              }}
              valueChangeFunction={updateCurvatureAndSlope}
              options={{
                spring: 5,
                inertia: 2,
                moveSpring: 10,
                moveInertia: 1,
              }}
            />
          )}
          {profile && (
            <RangeSlider
              value={(profile.feelGoodSpeedKmh - 30) / 170}
              setValue={updateFeelGoodSpeed}
              options={{
                spring: 5,
                inertia: 2,
              }}
            />
          )}
        </div>
      </Card>
    </div>
  )
}
