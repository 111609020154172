/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import { AddIcon, ImportRouteIcon } from '../../assets'
import {
  // Card,
  LinkButton,
} from '../../components/index'
import {
  routeActions,
  routeSelectors,
  tutorialActions,
  tutorialSelectors,
} from '../../lib'
import WaypointItemSortable from './WaypointItemSortable'
import classes from './Waypoints.module.scss'
import { WaypointsList } from './WaypointsList'
import { WaypointsTutorial } from './WaypointsTutorial'

function getRoleByPosition(index, list) {
  if (index === 0) {
    return 'start'
  }

  if (index === list.length - 1) {
    return 'end'
  }

  return 'stop'
}

function Action({ onClick, icon, label }) {
  return (
    <button type="button" className={classes.add_waypoint} onClick={onClick}>
      <span className={classes.actionIcon}>{icon}</span>
      <span className={classes.actionLabel}>{label}</span>
    </button>
  )
}

Action.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export function Waypoints({ onImportClick }) {
  const dispatch = useDispatch()
  const [inputRef, setInputRef] = useState(null)
  const [popperRef, setPopperRef] = useState(null)
  const waypointList = useSelector(routeSelectors.getWaypoints)
  const { formatMessage } = useIntl()
  const actualWaypoints = useSelector(routeSelectors.getLatLangWaypoints)
  const showWaypointsTutorial = useSelector(
    tutorialSelectors.getShowWaypointsTutorial,
  )

  // if there are waypoints, skip the waypoint-tutorial
  useEffect(() => {
    if (actualWaypoints.length > 1 && showWaypointsTutorial) {
      dispatch(tutorialActions.nextStep())
    }
  }, [actualWaypoints, dispatch, showWaypointsTutorial])

  const handleAddWaypointClick = () => {
    dispatch(routeActions.addStop())
  }

  return (
    <div className={classes.preferences} ref={setInputRef}>
      <WaypointsTutorial
        show={showWaypointsTutorial}
        inputRef={inputRef}
        popperRef={popperRef}
        ref={setPopperRef}
      />

      <div className={classes.waypoints}>
        <WaypointsList>
          {waypointList.map((waypoint, index, list) => (
            <WaypointItemSortable
              type={getRoleByPosition(index, list)}
              key={waypoint.id}
              id={waypoint.id}
              location={waypoint}
              index={index}
              tabIndex={0}
            />
          ))}
        </WaypointsList>
      </div>

      <Action
        onClick={handleAddWaypointClick}
        icon={
          <span className={classes.addIcon}>
            <AddIcon />
          </span>
        }
        label={formatMessage({
          id: 'waypoints.add_stop',
          defaultMessage: 'add stop',
        })}
      />

      {!actualWaypoints.length && (
        <Action
          onClick={onImportClick}
          icon={
            <span className={classes.importIcon}>
              <ImportRouteIcon />
            </span>
          }
          label={formatMessage({
            id: 'waypoints.import_route',
            defaultMessage: 'Import route',
          })}
        />
      )}
    </div>
  )
}

Waypoints.propTypes = {
  onImportClick: PropTypes.func.isRequired,
}
