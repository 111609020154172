import { useCallback } from 'react'
import {
  getRelativePercentFromAbsolute,
  getRelativePercentFromCircleCoordinates,
} from './geometry'

export const useSetMoveToPosition = ({ followField, setMoveTo }) =>
  useCallback(
    (posX, posY, isAbsolute = true) => {
      let relativePosition = {
        x: posX,
        y: posY,
      }

      if (isAbsolute) {
        relativePosition = getRelativePercentFromAbsolute({
          absX: posX,
          absY: posY,
          followField,
        })
      }

      const mouseDelta = {
        x: relativePosition.x - 0.5,
        y: relativePosition.y - 0.5,
      }

      const angle = Math.atan2(mouseDelta.y, mouseDelta.x)
      const length =
        Math.sqrt(mouseDelta.x * mouseDelta.x + mouseDelta.y * mouseDelta.y) /
        0.5

      let moveToX
      let moveToY
      if (length <= 1) {
        moveToX = relativePosition.x
        moveToY = relativePosition.y
      } else {
        const relativeMoveToPosition = getRelativePercentFromCircleCoordinates({
          angle,
          length: 1,
          rounding: true,
        })
        moveToX = relativeMoveToPosition.x
        moveToY = relativeMoveToPosition.y
      }
      // console.log(moveToX, moveToY)
      setMoveTo(moveToX, moveToY)
    },
    [followField, setMoveTo],
  )
