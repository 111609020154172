import { useCallback } from 'react'
import { useStore } from 'react-redux'
import { routeActions, routeSelectors } from './routeSlice'

const { resetWaypoint } = routeActions

/**
 * This hook will add the `meta.newGroup` property. When we reset a start/stop
 * waypoint, and there's another waypoint left, then we can safely create a new
 * group. If we remove the last waypoint, we don't want to create a group as
 * "UNDO" would bring us back to a state without a route (one waypoint)
 */
export function useResetWaypoint() {
  const store = useStore()

  return useCallback(
    ({ id }) => {
      const state = store.getState()
      const latLngWaypoints = routeSelectors.getLatLangWaypoints(state)
      const isLoadingCoords = routeSelectors.getLoadingCoords(state)
      const hasLoadingCoordsError = routeSelectors.getLoadingCoordsError(state)
      const newGroup =
        latLngWaypoints.length === 2 &&
        // We don't want to create a new group when we're loading
        !isLoadingCoords &&
        !hasLoadingCoordsError

      return { meta: { newGroup }, ...resetWaypoint({ id }) }
    },
    [store],
  )
}
