import PropTypes from 'prop-types'
import QRCode from 'qrcode'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { CheckSignIcon, CopyToClipboardIcon } from '../../../assets'
import { IconButton, Icons } from '../../../components'
import styles from './Success.module.scss'

function Shareable({ shareLink }) {
  const canvasRef = useRef()
  const { formatMessage } = useIntl()
  const handleCopyToClipboard = () => navigator.clipboard.writeText(shareLink)

  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      // QR code doesn't work with an empty string
      // so we are using a blank space as a fallback
      shareLink || ' ',
      {
        margin: 0,
        width: 128,
        errorCorrectionLevel: 'L',
      },
      /* eslint-disable no-console */
      (error) => error && console.error(error),
    )
  }, [shareLink])

  return (
    <>
      <p className={styles.desc}>
        {formatMessage({
          id: 'savedialog.success_save_share',
          defaultMessage:
            'You can now view this route in your app and drive it or share it with your friends!',
        })}
      </p>
      <div className={styles.share}>
        <a href={shareLink} target="_blank" rel="noreferrer">
          {shareLink.replace(/^https?:\/\//, '')}
        </a>
        <span className={styles.shareIconWrap} onClick={handleCopyToClipboard}>
          <CopyToClipboardIcon className={styles.shareIcon} />
        </span>
      </div>
      <div className={styles.canvasWrap}>
        <canvas ref={canvasRef} />
      </div>
    </>
  )
}

Shareable.propTypes = {
  shareLink: PropTypes.string.isRequired,
}

export function Success({ onCreateNewRoute, shareLink }) {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <CheckSignIcon className={styles.icon} />
      <h3>
        {formatMessage({
          id: 'savedialog.success_save',
          defaultMessage: 'Road saved successfully!',
        })}
      </h3>

      {!!shareLink && <Shareable shareLink={shareLink} />}

      {!shareLink && (
        <p>
          {formatMessage({
            id: 'savedialog.success_shareable',
            defaultMessage:
              'You can now view this route in your app and drive it!',
          })}
        </p>
      )}

      <div className={styles.cta}>
        <IconButton onClick={onCreateNewRoute} icon={Icons.CHEVRON_RIGHT}>
          {formatMessage({
            id: 'savedialog.create_new_route',
            defaultMessage: 'Create new route.',
          })}
        </IconButton>
      </div>
    </div>
  )
}

Success.defaultProps = {
  onCreateNewRoute: () => {},
  shareLink: '',
}

Success.propTypes = {
  onCreateNewRoute: PropTypes.func,
  shareLink: PropTypes.string,
}
