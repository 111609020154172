import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { environments } from '../../lib'
import styles from './ColoredNotch.module.scss'

export function ColoredNotch({
  countryFactor,
  mountainFactor,
  shoreFactor,
  forestFactor,
}) {
  const [background, setBackground] = useState('red')
  const [backgroundAlt, setBackgroundAlt] = useState('red')
  const nextFirstBackgroundVisible = useRef(false)
  const [firstBackgroundVisible, setFirstBackgroundVisible] = useState(true)

  useEffect(() => {
    let routeFactors = [
      {
        name: environments.COUNTRY,
        factor: countryFactor,
      },
      {
        name: environments.MOUNTAIN,
        factor: mountainFactor,
      },
      {
        name: environments.SHORE,
        factor: shoreFactor,
      },
      {
        name: environments.FOREST,
        factor: forestFactor,
      },
    ]

    routeFactors.sort((a, b) => b.factor - a.factor)
    routeFactors = routeFactors.filter((factor) => factor.factor >= 0.25)
    const currentRoute = routeFactors
      .map((factor) => factor.name)
      .sort()
      .join('_')
    let backgroundString = 'var(--background_default)'
    if (routeFactors.length > 0) {
      if (routeFactors.length === 1) {
        backgroundString = `var(--background_${routeFactors[0].name})`
      } else if (routeFactors.length === 4) {
        backgroundString = 'var(--background_full)'
      } else {
        backgroundString = `var(--background_${currentRoute})`
      }
    }

    if (nextFirstBackgroundVisible.current) {
      setBackground(backgroundString)
      nextFirstBackgroundVisible.current = false
      setFirstBackgroundVisible(true)
    } else {
      setBackgroundAlt(backgroundString)
      nextFirstBackgroundVisible.current = true
      setFirstBackgroundVisible(false)
    }
  }, [
    countryFactor,
    mountainFactor,
    shoreFactor,
    forestFactor,
    setFirstBackgroundVisible,
    setBackground,
    setBackgroundAlt,
  ])

  return (
    <div
      className={styles.coloredNotch}
      style={{
        mask: 'url(notch-color.svg)',
        WebkitMaskImage: 'url(notch-color.svg)',
      }}
    >
      <div
        className={styles.background}
        style={{
          background,
          opacity: firstBackgroundVisible ? 1 : 0,
        }}
      />
      <div
        className={styles.backgroundAlt}
        style={{
          background: backgroundAlt,
        }}
      />
    </div>
  )
}

ColoredNotch.defaultProps = {
  countryFactor: 0,
  mountainFactor: 0,
  shoreFactor: 0,
  forestFactor: 0,
}

ColoredNotch.propTypes = {
  countryFactor: PropTypes.number,
  mountainFactor: PropTypes.number,
  shoreFactor: PropTypes.number,
  forestFactor: PropTypes.number,
}
