import React from 'react'
import ReactDOM from 'react-dom/client'
// eslint-disable-next-line import/no-unresolved
import { register } from 'swiper/element/bundle'
import './index.scss'
import { App } from './app'

register()

ReactDOM.createRoot(document.getElementById('root')).render(<App />)
