export const sanitizeAngle = (angle) => {
  if (angle < -Math.PI) {
    return angle + 2 * Math.PI
  }
  if (angle > Math.PI) {
    return angle - 2 * Math.PI
  }
  return angle
}

export const getAngleDifference = (tAngle, fAngle) => {
  let difference = tAngle - fAngle
  let differenceAbs = Math.abs(difference)
  const higherDifference = difference + 2 * Math.PI
  const lowerDifference = difference - 2 * Math.PI
  const higherDifferenceAbs = Math.abs(higherDifference)
  const lowerDifferenceAbs = Math.abs(lowerDifference)
  if (higherDifferenceAbs < differenceAbs) {
    difference = higherDifference
    differenceAbs = higherDifferenceAbs
  }
  if (lowerDifferenceAbs < differenceAbs) {
    difference = lowerDifference
  }

  return difference
}

const easingStart = 0.15
const easingEnd = 0.9
const minimumRadians = 0.4

export const getWidthFromLength = (length) => {
  // length = easing(length)
  if (length < easingStart) {
    return 2 * Math.PI + 0.1
  }
  if (length > easingEnd) {
    return minimumRadians
  }

  return (
    (Math.PI / 2 - minimumRadians) *
      (1 - (length - easingStart) / (easingEnd - easingStart)) +
    minimumRadians
  )
}

export const getLengthFactor = (length) =>
  length < easingStart ? 0 : (length - easingStart) / (1 - easingStart)

export const getXfactorFromAngle = (angle) =>
  Math.max(-1, Math.min(1, (Math.PI / 2 - Math.abs(angle)) / (Math.PI / 4)))

export const getYfactorFromAngle = (angle) =>
  (-1 +
    Math.max(
      0,
      (Math.abs(Math.abs(angle) - Math.PI / 2) - Math.PI / 4) / (Math.PI / 4),
    )) *
  Math.sign(angle)

export const getValuesFromCircleCoordinates = ({ angle, length }) => ({
  // Round values
  x:
    Math.round(getXfactorFromAngle(angle) * getLengthFactor(length) * 100) /
    100,
  y:
    Math.round(getYfactorFromAngle(angle) * getLengthFactor(length) * 100) /
    100,
})

export const getCircleCoordinatesFromValues = ({ x, y }) => {
  if (x === 0 && y === 0) {
    return {
      angle: 0,
      length: 0,
    }
  }

  let angle = 0

  const absLength = Math.min(1, Math.max(Math.abs(x), Math.abs(y)))
  const xOfy = y === 0 ? 2 : x / y // avoiding division by 0; any value > 1 could be used here
  const ySign = y === 0 ? 1 : Math.sign(y) // we need -1|1 here, not -1|0|1
  const yOfx = x === 0 ? 2 : y / x // avoiding division by 0; any value > 1 could be used here

  if (Math.abs(xOfy) <= 1) {
    angle = ySign * (-Math.PI / 2) + xOfy * (Math.PI / 4)
  } else if (x > 0) {
    angle = (-Math.PI / 4) * yOfx
  } else {
    angle = ySign * -Math.PI - yOfx * (Math.PI / 4)
  }

  return {
    angle,
    length: absLength * (1 - easingStart) + easingStart,
  }
}

export const getRelativePercentFromAbsolute = ({ absX, absY, followField }) => {
  const followFieldRect = followField.getBoundingClientRect()
  return {
    x: (absX - followFieldRect.x) / followFieldRect.width,
    y: (absY - followFieldRect.y) / followFieldRect.height,
  }
}

export const getRelativePercentFromCircleCoordinates = ({
  angle,
  length,
  rounding = false,
}) => ({
  x: rounding
    ? Math.round((0.5 + 0.5 * length * Math.cos(angle)) * 100) / 100
    : 0.5 + 0.5 * length * Math.cos(angle),
  y: rounding
    ? Math.round((0.5 + 0.5 * length * Math.sin(angle)) * 100) / 100
    : 0.5 + 0.5 * length * Math.sin(angle),
})

export const getCircleCoordinatesFromRelativePercent = ({ x, y }) => {
  const mouseDelta = {
    x: x - 0.5,
    y: y - 0.5,
  }

  return {
    angle: Math.atan2(mouseDelta.y, mouseDelta.x),
    length: Math.min(
      1,
      Math.sqrt(mouseDelta.x * mouseDelta.x + mouseDelta.y * mouseDelta.y) /
        0.5,
    ),
  }
}
