import { combineReducers } from '@reduxjs/toolkit'
import { contextMenuReducer, contextMenuSlice } from '../contextMenu/index'
import { mapboxReducer, mapboxSlice } from '../mapbox/index'
import {
  routeReducer,
  routeSessionReducer,
  routeSessionSlice,
  routeSlice,
} from '../route/index'
import { tutorialReducer, tutorialSlice } from '../tutorial/index'
import { userReducer, userSlice } from '../user/index'
import {
  waypointUserInputReducer,
  waypointUserInputSlice,
} from '../waypointUserInput/index'

export const rootReducer = combineReducers({
  [mapboxSlice.name]: mapboxReducer,
  [routeSessionSlice.name]: routeSessionReducer,
  [routeSlice.name]: routeReducer,
  [userSlice.name]: userReducer,
  [contextMenuSlice.name]: contextMenuReducer,
  [tutorialSlice.name]: tutorialReducer,
  [waypointUserInputSlice.name]: waypointUserInputReducer,
})
