import React, { useRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { ImageTileGroup, ImageUpload } from '../../../components'
import { routeSelectors, userSelectors } from '../../../lib'
import { previewImages } from '../constants'
import { useImageStatusStore } from '../useImageStatusStore'
import styles from './RouteImageSelector.module.scss'

export function RouteImageSelector() {
  const { formatMessage } = useIntl()
  const form = useFormContext()
  const { field } = useController({
    name: 'pictureId',
    control: form.control,
  })
  const routeImageId = field.value
  const sessionId = useSelector(userSelectors.getRoadsUserSessionId)

  const highestEnvironmentRatio = useSelector(
    routeSelectors.getHighestEnvironmentRatio,
  )
  const routePreviewImageIds = previewImages[highestEnvironmentRatio]
  const tiles = Object.values(routePreviewImageIds)
    .slice(0, -1)
    .map(({ id, thumbnail }) => ({ id, src: thumbnail }))

  const PREDEFINED_ROUTE_IDS = tiles.map((tile) => tile.id)

  const imageUploadRef = useRef()
  const imageStatus = useImageStatusStore((state) => state.image)
  const postImage = useImageStatusStore((state) => state.postImage)
  const setImageError = useImageStatusStore((state) => state.setImageError)
  const selectFirstImage = () => field.onChange(PREDEFINED_ROUTE_IDS[0])
  const selectCustomImage = () => {
    field.onChange(imageStatus.data.response.picture.id)
  }
  const processNewFile = async ({ file }) => {
    const { picture } = await postImage({ file, sessionId })
    field.onChange(picture.id)
  }

  return (
    <div className={styles.routeImageSelector}>
      <h4>
        {formatMessage({
          id: 'savedialog.choose_temporary_image',
          defaultMessage: 'Choose a temporary image.',
        })}
      </h4>
      <div className={styles.group}>
        <ImageTileGroup
          tiles={tiles}
          onChange={field.onChange}
          selected={routeImageId}
        />

        <div className={styles.imageUpload}>
          <ImageUpload
            ref={imageUploadRef}
            active={!PREDEFINED_ROUTE_IDS.includes(routeImageId)}
            uploading={imageStatus.loading}
            onClick={selectCustomImage}
            onError={({ file, error }) => setImageError({ file, error })}
            onImage={processNewFile}
            onRemove={selectFirstImage}
          />
        </div>
      </div>
    </div>
  )
}
