/* eslint-disable no-console */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { BtnChevronLeftIcon } from '../../assets'
import classes from './WizardStep.module.scss'

export function WizardStep({
  isPrevScreen,
  isCurrentScreen,
  onBack,
  children,
}) {
  const { formatMessage } = useIntl()

  return (
    <div
      className={classnames(classes.wizardStep, {
        [classes.prevScreen]: isPrevScreen,
        [classes.currentScreen]: isCurrentScreen,
      })}
    >
      {onBack && (
        <button type="button" onClick={onBack} className={classes.backButton}>
          <BtnChevronLeftIcon />
          <span className={classes.backButtonLabel}>
            {formatMessage({
              id: 'importdialog.wizard_step_back_button',
              defaultMessage: 'Back',
            })}
          </span>
        </button>
      )}

      {children}
    </div>
  )
}

WizardStep.defaultProps = {
  onBack: null,
}

WizardStep.propTypes = {
  children: PropTypes.any.isRequired,
  isPrevScreen: PropTypes.bool.isRequired,
  isCurrentScreen: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
}
