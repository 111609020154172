import { createSlice } from '@reduxjs/toolkit'

const name = 'user'
const getState = (state) => state[name]

const getUser = (state) => getState(state).roadsUser
const getRoadsUser = (state) => getState(state).roadsUser
const getRoadsUserSessionId = (state) => getRoadsUser(state)?.sessionId

export const userSelectors = {
  getUser,
  getRoadsUser,
  getRoadsUserSessionId,
}

const initialState = {
  roadsUser: {},
  location: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setNewUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { roadsUser } = action.payload
      state.roadsUser = roadsUser
    },
    setLocation: (state, action) => {
      const { lng, lat } = action.payload
      state.location = {
        lng,
        lat,
      }
    },
  },
})

export const userActions = userSlice.actions
export const { setNewUser, setLocation } = userActions

export const userReducer = userSlice.reducer
