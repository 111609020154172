import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { Button } from '../../components'
import { ReactComponent as SuccessIcon } from './successIcon.svg'
import classes from './WizardGpxSuccess.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

export function WizardGpxSuccess({ onClose }) {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.container}>
      <div className={classes.loadingIcon}>
        <SuccessIcon />
      </div>

      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.import_success_headline',
            defaultMessage: 'Imported ROAD successfully',
          })}
        </WizardStepHeadline>
      </div>

      <p className={classes.loadingDescription}>
        {formatMessage({
          id: 'importdialog.file_import_success_description',
          defaultMessage:
            // eslint-disable-next-line max-len
            'Du kannst eine Route nun weiter auf deine persöhnlichen Vorlieben einstellen um dein Fahrerlebnis zu maximieren.',
        })}
      </p>

      <div className={classes.actions}>
        <Button type="submit" onClick={onClose}>
          {formatMessage({
            id: 'importdialog.file_import_success_close_button',
            defaultMessage: 'Close',
          })}
        </Button>
      </div>
    </div>
  )
}

WizardGpxSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
}
