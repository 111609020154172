import {
  imageTerrainDark,
  imageTerrainLight,
  imageTerrainSatellite,
} from '../../assets/index'

export const terrains = {
  lightMode: {
    id: 'terrain-lightMode',
    type: 'raster-dem',
    url: 'mapbox://styles/interactivepioneers/cl8ven0bl007i14t5lppzzyjv',
    image: imageTerrainLight,
    label: 'light mode',
  },
  darkMode: {
    id: 'terrain-darkMode',
    type: 'raster-dem',
    url: 'mapbox://styles/interactivepioneers/cl8ldjc1p000f14mvanuii2g6',
    image: imageTerrainDark,
    label: 'dark mode',
  },
  satellite: {
    id: 'terrain-satellite',
    type: 'raster-dem',
    url: 'mapbox://styles/interactivepioneers/cldu5luk4000l01qityw3dngv',
    image: imageTerrainSatellite,
    label: 'satellite',
  },
}
