import PropTypes from 'prop-types'

const ImageTileType = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  src: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export default ImageTileType
