import classnames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { AiArrowCircleIcon } from '../../../assets'
import { fetchNameAndDescSuggestions, routeSelectors } from '../../../lib'
import classes from './PrefillWithSuggestionsCheckbox.module.scss'

const MAX_ALLOWED_COORDS = 10000

export function PrefillWithSuggestionsCheckbox() {
  const [loading, setLoading] = useState(false)
  const [hasBeenCreated, setHasBeenCreated] = useState(false)
  const [labeltext, setLabelText] = useState('')
  const { formatMessage, locale } = useIntl()
  const form = useFormContext()
  const routeId = useSelector(routeSelectors.getId)
  const coords = useSelector(routeSelectors.getCoords)
  const tooManyCoords = coords?.length > MAX_ALLOWED_COORDS
  const tooManyCoordsMessage = formatMessage(
    {
      id: 'savedialog.ai_suggestion_too_many_coordinates',
      defaultMessage:
        "Can't load suggestions for routes with more than {amount} coordinates",
    },
    { amount: MAX_ALLOWED_COORDS },
  )

  async function fetchAndPrefillSuggestions() {
    setLoading(true)

    try {
      const { title, description } = await fetchNameAndDescSuggestions({
        locale,
        proposalId: routeId,
      })
      form.setValue('name', title)
      form.setValue('description', description)
      form.trigger()
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        formatMessage({
          id: 'savedialog.ai_suggestion_loading_error',
          defaultMessage:
            'Something unexpected happened, please try again later!',
        }),
      )
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      setLoading(false)
      setHasBeenCreated(true)
    }
  }

  const getLabelText = useCallback(() => {
    if (loading) {
      return formatMessage({
        id: 'savedialog.ai_suggestion_buttonlabel_generating',
        defaultMessage: 'Is generating',
      })
    }
    if (hasBeenCreated) {
      return formatMessage({
        id: 'savedialog.ai_suggestion_buttonlabel_recreate',
        defaultMessage: 'Re-create texts',
      })
    }
    return formatMessage({
      id: 'savedialog.ai_suggestion_buttonlabel',
      defaultMessage: 'Fill automatically',
    })
  }, [formatMessage, loading, hasBeenCreated])

  useEffect(() => {
    setLabelText(getLabelText())
  }, [loading, hasBeenCreated, getLabelText])

  useEffect(() => {
    if (routeId) {
      setHasBeenCreated(false)
    }
  }, [routeId])

  return (
    <div
      className={classnames(classes.prefillWithSuggestionsCheckbox, {
        [classes.loading]: loading,
      })}
    >
      <AiArrowCircleIcon className={classes.loadingIcon} />
      <button
        id="prefillWithSuggestionsButton"
        type="button"
        title={tooManyCoords ? tooManyCoordsMessage : undefined}
        disabled={loading || tooManyCoords}
        className={classes.fetchAndPrefillSuggestions}
        onClick={fetchAndPrefillSuggestions}
      >
        {formatMessage({
          id: 'savedialog.ai_suggestion_buttonname',
          defaultMessage: 'AI',
        })}
      </button>
      <label htmlFor="prefillWithSuggestionsButton" className={classes.label}>
        {labeltext}
      </label>
    </div>
  )
}
