import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { TextArea } from '../../../components'

export function DescriptionField() {
  const form = useFormContext()
  const { formatMessage } = useIntl()
  const { errors } = form.formState

  return (
    <TextArea
      name="description"
      id="description"
      label={formatMessage({
        id: 'savedialog.description',
        defaultMessage: 'description',
      })}
      error={errors?.description?.message}
      placeholder={formatMessage({
        id: 'savedialog.placeholder_description_of_road',
        defaultMessage: 'Make users understand what to expect of this road.',
      })}
      rows={4}
      {...form.register('description')}
    />
  )
}
