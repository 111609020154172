import { useCallback, useState } from 'react'

export const useIndicator = () => {
  const [{ indicator, indicatorContext }, setState] = useState({
    indicator: null,
    indicatorContext: null,
  })

  const setIndicator = useCallback((el) => {
    if (!el) {
      return
    }

    const context = el.getContext('2d')
    context.lineWidth = 4
    context.lineCap = 'round'
    context.strokeStyle = '#ffffff'
    setState({
      indicator: el,
      indicatorContext: context,
    })
  }, [])

  const drawIndicator = useCallback(
    (angle, width) => {
      if (!indicator?.width) {
        return
      }

      const startAngle = angle - width / 2
      const endAngle = angle + width / 2

      indicatorContext.clearRect(0, 0, indicator.width, indicator.height)
      indicatorContext.beginPath()
      indicatorContext.arc(
        indicator.width / 2,
        indicator.height / 2,
        indicator.height / 2 - 2,
        startAngle,
        endAngle,
      )
      indicatorContext.stroke()
    },
    [indicator?.width, indicator?.height, indicatorContext],
  )

  return {
    indicator,
    indicatorContext,
    setIndicator,
    drawIndicator,
  }
}
