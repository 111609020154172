import { useCallback, useEffect } from 'react'
import {
  getAngleDifference,
  getCircleCoordinatesFromRelativePercent,
  getRelativePercentFromAbsolute,
  getValuesFromCircleCoordinates,
  sanitizeAngle,
} from './geometry'

const tick = ({
  angleDifference,
  widthDifference,
  drawIndicator,
  followerAngle,
  followerWidth,
  options,
  setFollowerAngle,
  setFollowerWidth,
  targetX,
  targetY,
  targetDifference,
  setTargetPosition,
}) => {
  // console.log('tick!')
  const followerAngleTempo = angleDifference / options.inertia
  const followerWidthTempo = widthDifference / options.inertia
  const nextFollowerAngle = sanitizeAngle(
    followerAngle + followerAngleTempo / options.spring,
  )
  const nextFollowerWidth = followerWidth + followerWidthTempo / options.spring

  // console.log(targetDifference)
  if (targetDifference) {
    const nextTargetTempoX = targetDifference.x / options.moveInertia
    const nextTargetTempoY = targetDifference.y / options.moveInertia
    const nextTargetPositionX = targetX + nextTargetTempoX / options.moveSpring
    const nextTargetPositionY = targetY + nextTargetTempoY / options.moveSpring

    // console.log(nextTargetPositionX, nextTargetPositionY)
    setTargetPosition(nextTargetPositionX, nextTargetPositionY, false)
  }

  setFollowerAngle(nextFollowerAngle)
  setFollowerWidth(nextFollowerWidth)
  drawIndicator(nextFollowerAngle, nextFollowerWidth)
}

export const useMouseEventHandlers = ({
  dragging,
  drawIndicator,
  followField,
  followerAngle,
  followerWidth,
  following,
  options,
  setFollowerAngle,
  setFollowerWidth,
  setFollowing,
  setTargetPosition,
  target,
  targetX,
  targetY,
  targetAngle,
  targetWidth,
  setDragging,
  setMouseGrabPosition,
  mouseGrabPosition,
  circle,
  moveToX,
  moveToY,
  moving,
  setMoving,
  valueChangeFunction,
}) => {
  useEffect(() => {
    let angleDifference = null
    let widthDifference = null
    let targetDifference = null

    if (following || moving) {
      angleDifference = getAngleDifference(targetAngle, followerAngle)
      widthDifference = targetWidth - followerWidth

      if (
        Math.abs(angleDifference) < 0.02 &&
        Math.abs(widthDifference) < 0.02 &&
        !dragging &&
        !moving
      ) {
        setFollowing(false)
      }
    }

    if (moving) {
      targetDifference = {
        x: moveToX - targetX,
        y: moveToY - targetY,
      }

      if (
        Math.abs(targetDifference.x) <= 0.004 &&
        Math.abs(targetDifference.y) <= 0.004
      ) {
        setMoving(false)
      }
    }

    if (moving || following) {
      requestAnimationFrame(() =>
        tick({
          angleDifference,
          widthDifference,
          drawIndicator,
          followerAngle,
          followerWidth,
          options,
          setFollowerAngle,
          setFollowerWidth,
          targetX,
          targetY,
          targetDifference,
          setTargetPosition,
        }),
      )
    }
  }, [
    dragging,
    drawIndicator,
    followerAngle,
    followerWidth,
    following,
    options,
    setFollowerAngle,
    setFollowerWidth,
    setFollowing,
    target,
    targetX,
    targetY,
    target?.style.cssText,
    targetAngle,
    targetWidth,
    setTargetPosition,
    moveToX,
    moveToY,
    moving,
    setMoving,
  ])

  const mouseDownHandler = useCallback(
    (event) => {
      const targetRect = target.getBoundingClientRect()
      setDragging(true)
      setMoving(false)
      setMouseGrabPosition({
        x: event.clientX - targetRect.left,
        y: event.clientY - targetRect.top,
      })
    },
    [target, setDragging, setMoving, setMouseGrabPosition],
  )

  const mouseUpHandler = useCallback(
    (event) => {
      if (dragging) {
        const relativePosition = getRelativePercentFromAbsolute({
          absX: event.clientX - mouseGrabPosition.x,
          absY: event.clientY - mouseGrabPosition.y,
          followField,
        })

        const circleCoordinates =
          getCircleCoordinatesFromRelativePercent(relativePosition)

        const releasedValues = getValuesFromCircleCoordinates(circleCoordinates)

        setDragging(false)
        setMouseGrabPosition({
          x: 0,
          y: 0,
        })
        valueChangeFunction(releasedValues)
      }
    },
    [
      setDragging,
      dragging,
      setMouseGrabPosition,
      mouseGrabPosition,
      followField,
      valueChangeFunction,
    ],
  )

  const mouseMoveHandler = useCallback(
    (event) => {
      if (dragging) {
        setTargetPosition(
          event.clientX - mouseGrabPosition.x,
          event.clientY - mouseGrabPosition.y,
        )
        if (!following) {
          setFollowing(true)
        }
      }
    },
    [dragging, following, setFollowing, setTargetPosition, mouseGrabPosition],
  )

  const mouseClickHandler = useCallback(
    (event) => {
      if (event.target === circle.current) {
        // const moveToPosition = setMoveToPosition(event.clientX, event.clientY)
        // console.log('absolute position', event.clientX, event.clientY)
        const relativePosition = getRelativePercentFromAbsolute({
          absX: event.clientX,
          absY: event.clientY,
          followField,
        })
        // console.log('relativePosition', relativePosition)
        const circleCoordinates =
          getCircleCoordinatesFromRelativePercent(relativePosition)
        // console.log('circle coords', circleCoordinates)
        const clickedValues = getValuesFromCircleCoordinates(circleCoordinates)
        // console.log('clicked values', clickedValues)
        valueChangeFunction(clickedValues)
      }
    },
    [circle, valueChangeFunction, followField],
  )

  return {
    mouseDownHandler,
    mouseUpHandler,
    mouseMoveHandler,
    mouseClickHandler,
  }
}
