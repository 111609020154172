import 'mapbox-gl/dist/mapbox-gl.css'
// eslint-disable-next-line import/order
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax,import/no-unresolved
import { memo, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useContextMenu } from '../../../components'
import {
  contextMenuSelectors,
  displayRoute,
  mapboxActions,
  mapboxContants,
  mapboxSelectors,
  routeSelectors,
} from '../../../lib'
import { useDrawWaypointMarkers } from '../useDrawWaypointMarkers'
import { MapboxControls } from './MapboxControls'
import { useLongPress } from './useLongPress'
import './MapboxMap.global.scss'
import classes from './MapboxMap.module.scss'

// Porsche Zentrum Stuttgart
const initialPosition = {
  lat: 48.811392259951475,
  lng: 9.181070815888194,
}

// eslint-disable-next-line prefer-arrow-callback
export const MapboxMap = memo(function MapboxMap() {
  const dispatch = useDispatch()
  const isContextMenuOpen = useSelector(contextMenuSelectors.getIsOpen)

  const mapbox = useSelector(mapboxSelectors.getMapbox)
  const isChangingTerrain = useSelector(mapboxSelectors.getIsChangingTerrain)
  const routeId = useSelector(routeSelectors.getId)
  const coordinates = useSelector(routeSelectors.getCoords)
  const mapBoxContainer = useRef()
  const mapBoxElement = useRef()
  const latLngWaypoints = useSelector(routeSelectors.getLatLangWaypoints)
  const contextMenu = useContextMenu(mapbox)
  const loadingCoords = useSelector(routeSelectors.getLoadingCoords)

  // We intentionally decided to not keep this value in redux.
  // On the one hand it's impossible to deduce the previous route id
  // from the redux state as we don't know how many times a user
  // has performed an undo/redo action.
  // On the other hand, this value does not need to be persisted, it's
  // used for displaying purposes only. So keeping it co-located with
  // the "view" part of the app and as close to the place where
  // the route is re-rendered makes most sense.
  const displayedRouteIds = useRef([])

  useDrawWaypointMarkers()

  useEffect(() => {
    const [prev2RouteId, prevRouteId] = displayedRouteIds.current

    const canDrawRoute =
      mapbox &&
      ((displayedRouteIds.current.length && !routeId) ||
        (routeId && coordinates)) &&
      // We also want to redraw when the terrain has been changed, see:
      // https://stackoverflow.com/a/36169495
      //
      // This value will flip from false to true, and then back to false,
      // as changing the terrain is an asynchronous process. As soon as
      // it flips back to false, it will trigger this useEffect.
      !isChangingTerrain

    // Remove the displayed route
    if (
      loadingCoords ||
      (latLngWaypoints.length < 2 && routeId && canDrawRoute)
    ) {
      dispatch(mapboxActions.changeTo2D())
      displayRoute({
        map: mapbox,
        routeId: '',
        prevRouteId,
        prev2RouteId,
        coordinates,
      })
      displayedRouteIds.current = [displayedRouteIds.current[1], '']
    } else if (canDrawRoute) {
      dispatch(mapboxActions.changeTo2D())
      displayRoute({
        map: mapbox,
        routeId,
        prevRouteId,
        prev2RouteId,
        coordinates,
      })

      displayedRouteIds.current = [displayedRouteIds.current[1], routeId]
    }
  }, [
    coordinates,
    isChangingTerrain,
    mapbox,
    routeId,
    latLngWaypoints,
    dispatch,
    loadingCoords,
  ])

  const longPressEvents = useLongPress(
    (event) => contextMenu.open(event.lngLat, 'map'),
    { threshold: 400 },
  )

  // add context menu on right click or long press on touch devices if not already added
  useEffect(() => {
    let position = { lat: -1, lng: -1 }

    function registerMousePosition(e) {
      position = e.lngLat
    }

    function handleToggleContextMenu(event) {
      const canvas = document.querySelector('.mapboxgl-canvas')
      // We only want this handler to do anything if clicked directly on the
      // map, not on a marker. For some reason this event is triggered before
      // the click on the marker, even though we `stopPropagation`.
      const clickedOnCanvas = event.originalEvent.target === canvas

      if (
        clickedOnCanvas &&
        !isContextMenuOpen &&
        position.lat === event.lngLat.lat &&
        position.lng === event.lngLat.lng
      ) {
        contextMenu.open(event.lngLat, event.point)
      }

      position = { lat: -1, lng: -1 }
    }

    function handleOnTouchStart(event) {
      longPressEvents.onTouchStart(event)
    }

    function handleOnTouchEnd(event) {
      longPressEvents.onTouchEnd(event)
    }

    function handleOnTouchMove(event) {
      longPressEvents.onTouchMove(event)
    }

    if (mapbox) {
      mapbox.on('contextmenu', handleToggleContextMenu)
      if (isMobile) {
        mapbox.on('touchstart', handleOnTouchStart)
        mapbox.on('touchend', handleOnTouchEnd)
        mapbox.on('touchmove', handleOnTouchMove)
      } else {
        // Must be the same event as used by `useContextMenu` to register the
        // outside click!
        mapbox.on('mousedown', registerMousePosition)
        mapbox.on('mouseup', handleToggleContextMenu)
      }
    }

    return () => {
      if (mapbox) {
        mapbox.off('contextmenu', handleToggleContextMenu)
        if (isMobile) {
          mapbox.off('touchstart', handleOnTouchStart)
          mapbox.off('touchend', handleOnTouchEnd)
          mapbox.off('touchmove', handleOnTouchMove)
        } else {
          mapbox.on('mousedown', registerMousePosition)
          mapbox.off('mouseup', handleToggleContextMenu)
        }
      }
    }
  }, [mapbox, isContextMenuOpen, contextMenu, longPressEvents])

  return (
    <div ref={mapBoxContainer} className={classes.mapContainer}>
      <div
        className={classes.map}
        ref={async (el) => {
          // initialize map only once
          if (mapbox || !el || el === mapBoxElement.current) return

          mapBoxElement.current = el
          const { payload: mapboxInstance } = await dispatch(
            mapboxActions.createMapbox({
              containerEl: el,
              initialLng: initialPosition.lng,
              initialLat: initialPosition.lat,
              initialZoom: mapboxContants.INITIAL_ZOOM,
            }),
          )

          dispatch(mapboxActions.registerTerrains())

          mapboxInstance.addControl(
            new mapboxgl.ScaleControl({
              maxWidth: 120,
              unit: 'metric',
            }),
            'bottom-left',
          )

          mapboxInstance.addControl(new MapboxControls(), 'bottom-left')

          if (latLngWaypoints.length === 1) {
            // if only one waypoint is set, zoom to that waypoint
            dispatch(
              mapboxActions.zoomToWaypoint({
                waypoint: latLngWaypoints[0],
              }),
            )
          } else if (latLngWaypoints.length > 1) {
            // if more than one waypoint is set, zoom to the route
            dispatch(mapboxActions.zoomToFullRoute())
          }
        }}
      />
    </div>
  )
})
