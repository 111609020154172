import classNames from 'classnames'
import React from 'react'
import styles from './imageTile.module.scss'
import ImageTileType from './ImageTileType'

export function ImageTile({ active, id, onChange, src }) {
  const onClick = () => onChange(id)

  return (
    <div
      className={classNames('imageTile', styles.imageTile, {
        [styles.active]: active,
      })}
    >
      <div
        id={id}
        style={{
          backgroundImage: `url(${src})`,
        }}
        onClick={onClick}
        className={classNames(styles.image, active && styles.active)}
      />
    </div>
  )
}

ImageTile.defaultProps = {
  active: false,
  onChange: () => null,
}

ImageTile.propTypes = ImageTileType
