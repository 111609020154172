import { useCallback, useState } from 'react'

export const useMoveTo = () => {
  const [{ moveToX, moveToY, moving }, setState] = useState({
    moveToX: 0,
    moveToY: 0,
    moving: false,
  })

  const setMoveTo = useCallback(
    (x, y) =>
      setState((prevState) => ({ ...prevState, moveToX: x, moveToY: y })),
    [],
  )

  const setMoving = useCallback(
    (nextMoving) =>
      setState((prevState) => ({ ...prevState, moving: nextMoving })),
    [],
  )

  return {
    moveToX,
    moveToY,
    moving,
    setMoveTo,
    setMoving,
  }
}
