import { PersistGate } from 'redux-persist/integration/react'
import { ReduxProvider } from '../components/index'
import { I18n, persistor } from '../lib/index'
import { App } from './App'
import { ReactQueryProvider } from './ReactQueryProvider'

export function AppWrapper() {
  return (
    <ReduxProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ReactQueryProvider>
          <I18n>
            <App />
          </I18n>
        </ReactQueryProvider>
      </PersistGate>
    </ReduxProvider>
  )
}
