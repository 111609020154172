import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, mapboxActions, mapboxSelectors } from '../../../lib/index'
import { Compass } from './Compass'
import { FullScreen } from './FullScreen'
import classes from './MapControls.module.scss'
import { MapStyle } from './MapStyle'
import { TerrainOptions } from './TerrainOptions'
import { ThreeD } from './ThreeD'
import { Zoom } from './Zoom'
import { ZoomToFullRoute } from './ZoomToFullRoute'

export function MapControls() {
  const dispatch = useDispatch()
  const is3D = useSelector(mapboxSelectors.getIs3D)
  const isFullScreen = useSelector(mapboxSelectors.getIsFullScreen)
  const [showTerrainOptions, setShowTerrainOptions] = useState(false)

  return (
    <div className={classes.container}>
      {showTerrainOptions && (
        <I18n>
          <div className={classes.terrainOptions}>
            <TerrainOptions
              onTerrainClick={(terrainId) => {
                dispatch(mapboxActions.changeTerrain({ terrainId }))
              }}
            />
          </div>
        </I18n>
      )}

      <div className={classes.controls}>
        <Compass onClick={() => dispatch(mapboxActions.resetCompass())} />

        <Zoom
          onZoomIn={() => dispatch(mapboxActions.zoomIn())}
          onZoomOut={() => dispatch(mapboxActions.zoomOut())}
        />

        <ZoomToFullRoute
          onZoomToFullRoute={() => dispatch(mapboxActions.zoomToFullRoute())}
        />

        <MapStyle
          active={showTerrainOptions}
          onToggleShowTerrainOptions={() => {
            setShowTerrainOptions(!showTerrainOptions)
          }}
        />

        <ThreeD
          active={is3D}
          on3DToggle={() => dispatch(mapboxActions.toggle3D())}
        />

        <FullScreen
          active={isFullScreen}
          onFullScreenToggle={() => dispatch(mapboxActions.toggleFullScreen())}
        />
      </div>
    </div>
  )
}
