import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { TriangleDownWhiteIcon } from '../../../assets/index'
import { terrains } from '../../../lib/index'
import classes from './TerrainOptions.module.scss'

export function TerrainOptions({ onTerrainClick }) {
  const { formatMessage } = useIntl()
  return (
    <div className={classes.terrainOptions}>
      <div className={classes.triangleDown}>
        <TriangleDownWhiteIcon />
      </div>

      {Object.values(terrains).map(({ id, image, label }) => (
        <TerrainOption
          key={id}
          id={id}
          image={image}
          label={formatMessage({
            id: `terrains.${id}`,
            defaultMessage: { label },
          })}
          onClick={() => onTerrainClick(id)}
        />
      ))}
    </div>
  )
}

TerrainOptions.propTypes = {
  onTerrainClick: PropTypes.func.isRequired,
}

function TerrainOption({ id, image, label, alt, onClick }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classes.terrainOption} onClick={onClick}>
      <img className={classes.terrainImage} src={image} alt={alt} />
      <span
        className={classnames(classes.terrainLabel, {
          [classes.lightMode]: id === 'terrain-lightMode',
        })}
      >
        {label}
      </span>
    </div>
  )
}

TerrainOption.defaultProps = {
  alt: '',
}

TerrainOption.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  alt: PropTypes.string,
}
