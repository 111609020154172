import { yupResolver } from '@hookform/resolvers/yup'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import * as yup from 'yup'
import { Card, CardMessage, Modal } from '../../../components'
import { routeActions, routeSelectors, routeSessionActions } from '../../../lib'
import { formDefaultValues, previewImages } from '../constants'
import { Form } from '../Form'
import { RoutePreview } from '../RoutePreview'
import { Success } from '../Success'
import { useImageStatusStore } from '../useImageStatusStore'
import classes from './SaveDialog.module.scss'
import { useSaveRouteMutation } from './useSaveRouteMutation'

export function SaveDialog({ onClose: _onClose }) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const routeId = useSelector(routeSelectors.getId)
  const routeSaved = useSelector(routeSelectors.getRouteSaved)
  const highestEnvironmentRatio = useSelector(
    routeSelectors.getHighestEnvironmentRatio,
  )

  const imageStatus = useImageStatusStore((state) => state.image)
  const { mutateAsync: saveRoute, ...saveRouteMutation } =
    useSaveRouteMutation()

  const [showError, setShowError] = useState(false)

  formDefaultValues.pictureId =
    previewImages[highestEnvironmentRatio].IMAGE_PRESET_1.id

  // Yup is a schema builder for runtime value parsing and validation
  const schema = yup
    .object({
      name: yup.string().required(
        formatMessage({
          id: 'global.required',
          defaultMessage: 'Please fill in the required field.',
        }),
      ),
      description: yup.string().required(
        formatMessage({
          id: 'global.required',
          defaultMessage: 'Please fill in the required field.',
        }),
      ),
      pictureIds: yup.string(),
      publish: yup.boolean(),
    })
    .required()

  const form = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  })

  const onClose = useCallback(() => {
    _onClose()

    if (form.formState.isSubmitSuccessful) {
      form.reset(formDefaultValues)
    }
  }, [_onClose, form])

  const closeErrorDisplay = () => setShowError(false)
  const onSubmit = async (values) => {
    try {
      await saveRoute({
        name: values.name,
        description: values.description,
        publish: values.publish,
        pictureId: values.pictureId,
        proposalId: routeId,
      })

      dispatch(routeSessionActions.generateNewRouteSessionId())
      dispatch(routeActions.markAsSaved())
    } catch (e) {
      form.setError('root.serverError', { type: 'server', message: e.message })
    }
  }

  const formError = form.formState.errors.root?.serverError.message
  const anyError = formError || imageStatus.error

  useEffect(() => {
    if (anyError) {
      setShowError(true)
    }
  }, [anyError])

  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.keyCode !== 27) return
      onClose()
    }

    document.addEventListener('keydown', closeOnEsc)
    return () => document.removeEventListener('keydown', closeOnEsc)
  }, [onClose])

  useEffect(() => {
    form.reset(formDefaultValues)
  }, [routeId, form])

  const onCreateNewRoute = () => {
    dispatch(routeActions.resetState())
    dispatch(UndoActionCreators.clearHistory())
    onClose()
  }

  return createPortal(
    <FormProvider {...form}>
      <Modal onClose={onClose}>
        <div className={cn(classes.root, classes.show)}>
          <Card styleName={classes.card}>
            <div className={classes.content}>
              <div className={cn(classes.left, classes.show)}>
                <RoutePreview customRouteImage={imageStatus.data.file} />
              </div>
              <div className={classes.right}>
                <div
                  className={cn(classes.steps, {
                    [classes.complete]: routeSaved,
                  })}
                >
                  <div
                    className={cn(classes.form, {
                      [classes.hide]: routeSaved,
                    })}
                  >
                    <Form onSubmit={onSubmit} />
                  </div>

                  <div
                    className={cn(classes.success, {
                      [classes.show]: routeSaved,
                    })}
                  >
                    <Success
                      shareLink={saveRouteMutation.data?.route?.link}
                      onCreateNewRoute={onCreateNewRoute}
                    />
                  </div>
                </div>
              </div>
            </div>

            {showError && anyError && (
              <div className={classes.error}>
                <CardMessage
                  buttonText={formatMessage({
                    id: 'global.close',
                    defaultMessage: 'close',
                  })}
                  text={anyError?.toString() || ''}
                  onClick={closeErrorDisplay}
                  onClose={closeErrorDisplay}
                />
              </div>
            )}
          </Card>
        </div>
      </Modal>
    </FormProvider>,
    document.body,
  )
}

SaveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}
