import { useEffect, useRef } from 'react'

export const useRegisterMouseEvents = ({
  target,
  mouseDownHandler,
  mouseUpHandler,
  mouseMoveHandler,
}) => {
  const initialized = useRef(false)

  useEffect(() => {
    if (target && !initialized.current) {
      initialized.current = true
      target.addEventListener('mousedown', mouseDownHandler)
      window.addEventListener('mouseup', mouseUpHandler)
      window.addEventListener('mousemove', mouseMoveHandler)

      return () => {
        target.removeEventListener('mousedown', mouseDownHandler)
        window.removeEventListener('mouseup', mouseUpHandler)
        window.removeEventListener('mousemove', mouseMoveHandler)
        initialized.current = false
      }
    }

    return undefined
  }, [target, mouseDownHandler, mouseUpHandler, mouseMoveHandler])
}
