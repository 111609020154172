import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../lib'

export function useUserSessionIdFromEnvVar() {
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (process.env.REACT_APP_USER_SESSION_ID) {
        dispatch(
          userActions.setNewUser({
            roadsUser: {
              sessionId: process.env.REACT_APP_USER_SESSION_ID,
            },
          }),
        )
      }
    },
    // We want this to run only once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
