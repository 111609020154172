import { useIntl } from 'react-intl-phraseapp'
import { CircularLoadingGreyToWhite } from '../../assets/index'
import classes from './LoadingDisplay.module.scss'

export function LoadingDisplay() {
  const { formatMessage } = useIntl()
  return (
    <div className={classes.loadingDisplay}>
      <div className={classes.icon}>
        <CircularLoadingGreyToWhite />
      </div>

      <div className={classes.label}>
        {formatMessage({
          id: 'gloabl.loading',
          defaultMessage: 'loading',
        })}
      </div>
    </div>
  )
}
