import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { BtnUndoIcon } from '../../assets'
import { Button, IconButton, Icons, SIZE } from '../../components/index'
import { mapboxActions, routeSelectors } from '../../lib/index'
import classes from './GlobalRouteActions.module.scss'

export function GlobalRouteActions({ isSaveDialogOpen, onSaveClick }) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const canRedo = useSelector(routeSelectors.getCanRedo)
  const stepBackCount = useSelector(routeSelectors.getStepBackCount)
  const stepForwardCount = useSelector(routeSelectors.getStepForwardCount)
  const routeId = useSelector(routeSelectors.getId)
  const isLoadingCoords = useSelector(routeSelectors.getLoadingCoords)
  const loadingCoordsError = useSelector(routeSelectors.getLoadingCoordsError)
  const jumpBackCount = stepBackCount * -1
  const undo = () => {
    dispatch(UndoActionCreators.jump(jumpBackCount))
    dispatch(mapboxActions.zoomToFullRoute())
  }
  const redo = () => {
    dispatch(UndoActionCreators.jump(stepForwardCount))
    dispatch(mapboxActions.zoomToFullRoute())
  }
  const routeSaved = useSelector(routeSelectors.getRouteSaved)
  const disableSaveDialogButton =
    !routeId ||
    (routeSaved && !isSaveDialogOpen) ||
    isLoadingCoords ||
    !!loadingCoordsError

  return (
    <div className={classes.container}>
      <Button
        disabled={stepBackCount === 0}
        onClick={undo}
        size={SIZE.SMALL}
        styleType="secondary"
        iconOnly
      >
        <BtnUndoIcon />
      </Button>

      <Button
        className={classes.redoButton}
        disabled={!canRedo}
        onClick={redo}
        size={SIZE.SMALL}
        styleType="secondary"
        icon={Icons.REDO}
        iconOnly
      >
        <BtnUndoIcon />
      </Button>

      <IconButton
        disabled={disableSaveDialogButton}
        size={SIZE.SMALL}
        onClick={onSaveClick}
        icon={Icons.DOWNLOAD}
        className={classes.saveButton}
      >
        {formatMessage({ id: 'global.save', defaultMessage: 'save' })}
      </IconButton>
    </div>
  )
}

GlobalRouteActions.propTypes = {
  isSaveDialogOpen: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
}
