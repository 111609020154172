import { arrayMove } from '@dnd-kit/sortable'
import polyline from '@mapbox/polyline'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import findLast from 'lodash/fp/findLast'
import pipe from 'lodash/fp/pipe'
import undoable from 'redux-undo'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { environments } from '../environments/environments'
import { postRoundtrip } from './postRoundtrip'
import { postTrip } from './postTrip'
import { profiles } from './profiles'
import { routeSessionSelectors } from './routeSessionSlice'

const name = 'route'

/**
 * Utils
 */
export function createWaypoint({ lat, lng, title, type = 'passOver' } = {}) {
  const id = crypto.randomUUID()

  if (!title && (!lat || !lng)) {
    return { id, title: '', lat, lng, type: 'passOver' }
  }

  if (title) {
    return { id, title, lat, lng, type }
  }

  return { id, lat, lng, title: `${lat}, ${lng}`, type }
}

/**
 *
 * Selectors
 * =================================================
 *
 */
const getState = (state) => state[name]
const getPastStates = (state) => getState(state).past
const getPresentState = (state) => getState(state).present
const getFutureStates = (state) => getState(state).future

const getProposalData = (state) => state.proposalData
const getRouteData = (state) => state.routeData
const getRouteSaved = (state) => state.routeSaved

// profiles
const getProfileId = (state) => getProposalData(state).profileId
const getCustomProfile = (state) => {
  const { curvatureRatio, slopeRatio, feelGoodSpeedKmh } =
    getProposalData(state)
  return { curvatureRatio, slopeRatio, feelGoodSpeedKmh }
}

// waypoints
const getWaypoints = (state) => getProposalData(state).waypoints

/* lat lng waypoints
 * =================
 *
 * We want the latLng waypoints to be recalculated when only lat lng waypoints
 * change in the waypoints array. Therefore we need a custom equality check
 * which ensures that that's the case
 */
const extractLatLngWaypoints = (waypoints) =>
  waypoints.filter((waypoint) => waypoint.lat && waypoint.lng)
const latLngWaypointEqualityCheck = (prevValue, nextValue) => {
  const prevLatLngWaypoints = extractLatLngWaypoints(prevValue)
  const nextLatLngWaypoints = extractLatLngWaypoints(nextValue)

  if (prevLatLngWaypoints.length !== nextLatLngWaypoints.length) {
    return false
  }

  return prevLatLngWaypoints.every((prevWaypoint, index) => {
    const nextWaypoint = nextLatLngWaypoints[index]
    const sameId = prevWaypoint.id === nextWaypoint.id
    const sameLat = prevWaypoint.lat === nextWaypoint.lat
    const sameLng = prevWaypoint.lng === nextWaypoint.lng
    const sameType = prevWaypoint.type === nextWaypoint.type
    return sameId && sameLat && sameLng && sameType
  })
}
const createCustomLatLngSelector = createSelectorCreator(
  defaultMemoize,
  latLngWaypointEqualityCheck,
)
const getLatLangWaypoints = createCustomLatLngSelector(
  getWaypoints,
  (waypoints) => extractLatLngWaypoints(waypoints),
)

// route preferences
const getIsRoundtrip = (state) => getProposalData(state).isRoundtrip
const getRoutePreferences = (state) => getProposalData(state).routePreferences
const getFallbackStrategy = (state) =>
  getRoutePreferences(state).fallbackStrategy
const getCurvatureRatio = (state) => getRoutePreferences(state).curvatureRatio
const getSlopeRatio = (state) => getRoutePreferences(state).slopeRatio
const getFeelGoodSpeedKmh = (state) =>
  getRoutePreferences(state).feelGoodSpeedKmh
const getPreferredEnvironments = (state) =>
  getRoutePreferences(state).preferredEnvironments
const getVehicleType = (state) => getRoutePreferences(state).vehicleType
const getScenicRouting = (state) => getRoutePreferences(state).scenicRouting
const getAvoidances = (state) => getRoutePreferences(state).avoidances

// roundtrip preferences
const getRoundtripPreferences = (state) =>
  getProposalData(state).roundtripPreferences
const getBearingInDegree = (state) =>
  getRoundtripPreferences(state).bearingInDegree
const getDurationInSeconds = (state) =>
  getRoundtripPreferences(state).durationInSeconds

// route data
const getId = (state) => getRouteData(state)?.id
const getTitle = (state) => getRouteData(state).title
const getEncodedCoords = (state) => getRouteData(state).encoded
const getCoords = (state) => getRouteData(state).coords
const getElevationSamples = (state) =>
  getRouteData(state).properties.elevationProfile?.sampling?.points
const getLoadingCoords = (state) => getRouteData(state).loadingCoords
const getLoadingCoordsError = (state) => getRouteData(state).loadingCoordsError
const getProperties = (state) => getRouteData(state).properties
const getHighestEnvironmentRatio = (state) => {
  const { environmentRatios } = getRouteData(state).properties
  return Object.keys(environmentRatios).reduce((a, b) =>
    environmentRatios[a] > environmentRatios[b] ? a : b,
  )
}

// Undo / Redo selectors
// const getCanRedo = (state) => !!getFutureStates(state).length
const getCanRedo = (state) => {
  const presetState = getPresentState(state)
  const currentId = getId(presetState)

  const nextRouteState = findLast((futureState) => {
    const futureId = getId(futureState)
    return futureId !== currentId
  }, getFutureStates(state))

  return !!nextRouteState
}

// For more information, read the docs in src/lib/route/routeSlice.md
const getStepBackCount = (state) => {
  const pastStates = getPastStates(state)
  return pastStates.length > 1 ? 1 : 0
}

const getStepForwardCount = (state) => {
  const futureStates = getFutureStates(state)
  return futureStates.length ? 1 : 0
}

export const routeSelectors = {
  getCanRedo,
  getStepBackCount,
  getStepForwardCount,

  // The following selectors only make sense in the context of the "present".
  // The other selectors will deal with past and future state, so that's why
  // it's nice to have the selectors available internally that can handle past
  // and future state as well. But the rest of the app shouldn't be aware of
  // past/future as that's an implementation detail and would introduce tighter
  // coupling. Anything related to past/future should be handled by a selector
  // or through actions!
  getRouteSaved: pipe(getPresentState, getRouteSaved),
  getProfileId: pipe(getPresentState, getProfileId),
  getCustomProfile: pipe(getPresentState, getCustomProfile),
  getWaypoints: pipe(getPresentState, getWaypoints),
  getLatLangWaypoints: pipe(getPresentState, getLatLangWaypoints),
  getIsRoundtrip: pipe(getPresentState, getIsRoundtrip),
  getRoutePreferences: pipe(getPresentState, getRoutePreferences),
  getFallbackStrategy: pipe(getPresentState, getFallbackStrategy),
  getCurvatureRatio: pipe(getPresentState, getCurvatureRatio),
  getSlopeRatio: pipe(getPresentState, getSlopeRatio),
  getFeelGoodSpeedKmh: pipe(getPresentState, getFeelGoodSpeedKmh),
  getPreferredEnvironments: pipe(getPresentState, getPreferredEnvironments),
  getVehicleType: pipe(getPresentState, getVehicleType),
  getScenicRouting: pipe(getPresentState, getScenicRouting),
  getAvoidances: pipe(getPresentState, getAvoidances),
  getRoundtripPreferences: pipe(getPresentState, getRoundtripPreferences),
  getBearingInDegree: pipe(getPresentState, getBearingInDegree),
  getDurationInSeconds: pipe(getPresentState, getDurationInSeconds),
  getId: pipe(getPresentState, getId),
  getTitle: pipe(getPresentState, getTitle),
  getCoords: pipe(getPresentState, getCoords),
  getEncodedCoords: pipe(getPresentState, getEncodedCoords),
  getElevationSamples: pipe(getPresentState, getElevationSamples),
  getLoadingCoords: pipe(getPresentState, getLoadingCoords),
  getLoadingCoordsError: pipe(getPresentState, getLoadingCoordsError),
  getProperties: pipe(getPresentState, getProperties),
  getHighestEnvironmentRatio: pipe(getPresentState, getHighestEnvironmentRatio),
}

/**
 *
 * Thunks
 * =================================================
 *
 */
let postRouteProposalAbortController
export const abortPostRouteProposal = () => {
  if (postRouteProposalAbortController) {
    postRouteProposalAbortController.abort()
    postRouteProposalAbortController = null
  }
}

export const abortPostingRouteProposal = createAsyncThunk(
  `${name}/abortPostingRouteProposal`,
  (_, { dispatch }) => {
    abortPostRouteProposal()

    // It's safe to use this as it'll be defined when this hook will be called
    // eslint-disable-next-line no-use-before-define
    dispatch(routeActions.setLoadingCoords({ loading: false }))
  },
)

export const postRouteProposal = createAsyncThunk(
  'route/postRouteProposalTrip',
  async (_, { dispatch, getState: getCurrentState }) => {
    const state = getCurrentState()
    const presentState = getPresentState(state)
    const routeSessionId = routeSessionSelectors.getRouteSessionId(state)
    const waypoints = getLatLangWaypoints(presentState)
    const routePreferences = getRoutePreferences(presentState)
    const roundtripPreferences = getRoundtripPreferences(presentState)
    const isRoundtrip = getIsRoundtrip(presentState)
    const profileId = getProfileId(presentState)
    const isCustomProfile = profileId === 'custom'
    const profile = isCustomProfile
      ? getCustomProfile(presentState)
      : profiles[profileId]

    abortPostRouteProposal()
    postRouteProposalAbortController = new AbortController()

    // It's safe to use this as it'll be defined when this hook will be called
    // eslint-disable-next-line no-use-before-define
    dispatch(routeActions.setLoadingCoords({ loading: true }))

    const tripData = isRoundtrip
      ? {
          roundtripPreferences: {
            ...roundtripPreferences,
            startLocation: waypoints[0],
          },
        }
      : { waypoints }

    // The custom profile is empty as those values come from the actual redux
    // state and we don't want to override the actual data with `undefined`
    const profileData = !isCustomProfile ? profile : {}

    const payload = {
      routeSessionId,
      ...tripData,
      routePreferences: {
        ...routePreferences,
        ...profileData,
      },
    }

    const fetchFn = isRoundtrip ? postRoundtrip : postTrip

    return fetchFn(payload, {
      signal: postRouteProposalAbortController.signal,
    }).then((response) => {
      const { precision, data } = response.proposedRoute.geometry.polyline
      const decodedPolyline = polyline
        .decode(data, precision)
        .map((coords) => coords.reverse())

      return {
        ...response,
        proposedRoute: {
          ...response.proposedRoute,
          geometry: {
            ...response.proposedRoute.geometry,
            polyline: decodedPolyline,
          },
          encoded: response.proposedRoute.geometry.polyline,
        },
      }
    })
  },
)

/**
 *
 * Slice
 * =================================================
 *
 */
const getInitialState = () => ({
  routeSaved: false,
  proposalData: {
    isRoundtrip: false,
    profileId: Object.keys(profiles)[0],
    routePreferences: {
      fallbackStrategy: 'random',
      curvatureRatio: 0.5,
      slopeRatio: 0.5,
      feelGoodSpeedKmh: 300,
      preferredEnvironments: [environments.COUNTRY],
      vehicleType: 'sport',
      scenicRouting: true,
      avoidances: ['motorway'],
    },
    roundtripPreferences: {
      bearingInDegree: 360,
      durationInSeconds: 7200, // 2 hours
    },
    waypoints: [
      createWaypoint(), // Start
      createWaypoint(), // End
    ],
  },
  routeData: {
    id: '',
    title: '',
    properties: {
      avgSpeedKmh: 0,
      drivingTimeSeconds: 0,
      environmentRatios: {
        countryside: 0,
        forest: 0,
        mountain: 0,
        shore: 0,
      },
      lengthInMeters: 0,
    },
    coords: null,
    loadingCoords: false,
    loadingCoordsError: null,
  },
})

export const routeSlice = createSlice({
  name,
  initialState: getInitialState(),
  reducers: {
    toggleIsRoundtrip: (state) => {
      state.proposalData.isRoundtrip = !state.proposalData.isRoundtrip
    },
    setProfileId: (state, { payload }) => {
      const { profileId } = payload
      state.proposalData.profileId = profileId
    },
    // waypoint actions
    reorderWaypoints: (state, action) => {
      const { activeIndex, overIndex } = action.payload
      state.proposalData.waypoints = arrayMove(
        state.proposalData.waypoints,
        activeIndex,
        overIndex,
      )
    },
    setWaypoints: (state, action) => {
      const { waypoints } = action.payload
      state.proposalData.waypoints = waypoints
    },
    // Add payload waypoint to waypoints list as second-to-last item
    addStop: (state, action) => {
      const { lat, lng, title } = action.payload || {}

      const newWaypoint = createWaypoint({ title, lat, lng })
      state.proposalData.waypoints = [
        ...state.proposalData.waypoints.slice(0, -1),
        newWaypoint,
        state.proposalData.waypoints[state.proposalData.waypoints.length - 1],
      ]
    },
    addNewEnd: (state, action) => {
      const { lat, lng, title } = action.payload || {}

      state.proposalData.waypoints = [
        ...state.proposalData.waypoints,
        createWaypoint({ title, lat, lng }),
      ]
    },
    removeWaypoint: (state, action) => {
      const { id } = action.payload
      state.proposalData.waypoints = state.proposalData.waypoints.filter(
        (waypoint) => waypoint.id !== id,
      )
    },

    // You most likely don't want to use this action, but the one returned
    // by the `useResetWaypoint` hook!
    resetWaypoint: (state, { payload }) => {
      const { id } = payload
      const updatedWaypoints = state.proposalData.waypoints.map((waypoint) =>
        waypoint.id !== id ? waypoint : createWaypoint(),
      )
      state.proposalData.waypoints = updatedWaypoints
    },

    editWaypoint: (state, action) => {
      const { id, changes } = action.payload
      const index = state.proposalData.waypoints.findIndex(
        (waypoint) => waypoint.id === id,
      )
      state.proposalData.waypoints[index] = Object.assign(
        state.proposalData.waypoints[index],
        changes,
      )
    },

    toggleWaypointPassType: (state, action) => {
      const { id } = action.payload
      const index = state.proposalData.waypoints.findIndex(
        (waypoint) => waypoint.id === id,
      )
      const waypoint = state.proposalData.waypoints[index]
      state.proposalData.waypoints[index].type =
        waypoint.type === 'passBy' ? 'passOver' : 'passBy'
    },

    // route preferences actions
    setFallbackStrategy: (state, { payload }) => {
      const { fallbackStrategy } = payload
      state.proposalData.routePreferences.fallbackStrategy = fallbackStrategy
    },
    setCurvatureRatio: (state, { payload }) => {
      const { curvatureRatio } = payload
      state.proposalData.profileId = 'custom'
      state.proposalData.routePreferences.curvatureRatio = curvatureRatio
    },
    setSlopeRatio: (state, { payload }) => {
      const { slopeRatio } = payload
      state.proposalData.profileId = 'custom'
      state.proposalData.routePreferences.slopeRatio = slopeRatio
    },
    setCurvatureAndSlopeRatio: (state, { payload }) => {
      const { curvatureRatio, slopeRatio } = payload
      state.proposalData.profileId = 'custom'
      state.proposalData.routePreferences.curvatureRatio = curvatureRatio
      state.proposalData.routePreferences.slopeRatio = slopeRatio
    },
    setFeelGoodSpeedKmh: (state, { payload }) => {
      const { feelGoodSpeedKmh } = payload
      state.proposalData.profileId = 'custom'
      state.proposalData.routePreferences.feelGoodSpeedKmh = feelGoodSpeedKmh
    },
    setCurvatureAndSlopeAndSpeed: (state, { payload }) => {
      const { curvatureRatio, slopeRatio, feelGoodSpeedKmh } = payload
      state.proposalData.profileId = 'custom'
      state.proposalData.routePreferences.curvatureRatio = curvatureRatio
      state.proposalData.routePreferences.slopeRatio = slopeRatio
      state.proposalData.routePreferences.feelGoodSpeedKmh = feelGoodSpeedKmh
    },
    addPreferredEnvironment: (state, { payload }) => {
      const { preferredEnvironment } = payload
      state.proposalData.routePreferences.preferredEnvironments.push(
        preferredEnvironment,
      )
    },
    removePreferredEnvironment: (state, { payload }) => {
      const { preferredEnvironment } = payload
      const existingPreferredEnvironments =
        state.proposalData.routePreferences.preferredEnvironments
      state.proposalData.routePreferences.preferredEnvironments =
        existingPreferredEnvironments.filter(
          (currentPE) => currentPE !== preferredEnvironment,
        )
    },
    setVehicleType: (state, { payload }) => {
      const { vehicleType } = payload
      state.proposalData.routePreferences.vehicleType = vehicleType
    },
    setScenicRouting: (state, { payload }) => {
      const { scenicRouting } = payload
      state.proposalData.routePreferences.scenicRouting = scenicRouting
    },
    addAvoidance: (state, { payload }) => {
      const avoidance = { payload }
      state.proposalData.avoidances.push(avoidance)
    },
    removeAvoidance: (state, { payload }) => {
      const avoidance = { payload }
      state.proposalData.avoidances = state.proposalData.avoidances.filter(
        (currentAvoidance) => currentAvoidance !== avoidance,
      )
    },
    setTitle: (state, { payload }) => {
      state.routeData.title = payload.title
    },
    setCoords: (state, { payload }) => {
      state.routeData.coords = payload.coords
    },
    resetState: () => getInitialState(),
    markAsSaved: (state) => {
      state.routeSaved = true
    },
    setLoadingCoords: (state, { payload }) => {
      const { loading } = payload
      state.routeData.loadingCoords = loading
    },
    resetLoadingCoordsError: (state) => {
      state.routeData.loadingCoordsError = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postRouteProposal.rejected, (state, { error }) => {
      // We don't want to set an error if we're still loading
      // as that means we're just cancelled the previous request
      if (error.name !== 'AbortError') {
        state.routeData.loadingCoords = false
        state.routeData.loadingCoordsError = error
      }
    })
    builder.addCase(postRouteProposal.fulfilled, (state, { payload }) => {
      const { id } = payload
      const { encoded, geometry } = payload.proposedRoute
      const { polyline: coords, properties } = geometry

      state.routeData.id = id
      state.routeData.properties = properties
      state.routeData.coords = coords
      state.routeData.encoded = encoded
      state.routeData.loadingCoordsError = null
      state.routeData.loadingCoords = false
      state.routeSaved = false
    })
  },
})

/**
 *
 * Reducer
 * =================================================
 *
 */

// For more information, read the docs in src/lib/route/routeSlice.md
let prevActionType
let groupCounter = 0
function groupBy(action) {
  const shouldNotFilter =
    prevActionType === 'route/postRouteProposalTrip/fulfilled'

  // This will prevent a new group from being added but will ensure that a new
  // group will be added if any other action comes in after this one as we
  // don't override the `prevActionType`
  if (routeSlice.actions.markAsSaved.match(action)) {
    return `GROUP_${groupCounter}`
  }

  prevActionType = action.type
  if (shouldNotFilter || action.meta?.newGroup) {
    groupCounter++
  }

  const groupName = `GROUP_${groupCounter}`
  return groupName
}

// For more information, read the docs in src/lib/route/routeSlice.md
function filter(action) {
  return (
    // Include all thunk actions
    action.type.match(/^route[/]postRouteProposal/) ||
    // Include all actions that manipulate a route
    action.meta?.willPostRoute ||
    // Include action that manipulates "marked as saved" state
    routeSlice.actions.markAsSaved.match(action) ||
    // Intentionally create a new group, although we're not loading a new route
    action.meta?.newGroup
  )
}

// For more information, read the docs in src/lib/route/routeSlice.md
const undoRedoReducer = undoable(routeSlice.reducer, {
  groupBy,
  filter,
  undoType: '@@redux-undo/UNDO',
  redoType: '@@redux-undo/REDO',
  jumpType: '@@redux-undo/JUMP',
  jumpToPastType: '@@redux-undo/JUMP_TO_PAST',
  jumpToFutureType: '@@redux-undo/JUMP_TO_FUTURE',
})

export const routeReducer = undoRedoReducer

/**
 *
 * Actions
 * =================================================
 *
 */
export const routeActions = {
  ...routeSlice.actions,
  abortPostingRouteProposal,
  postRouteProposal,
}
