/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { CircularLoadingGreyToTransparent } from '../../assets'
import {
  getClosestStreetCoordForWaypoint,
  useWaypointSuggestionsQuery,
  waypointUserInputActions,
  waypointUserInputSelectors,
} from '../../lib'
import AutocompleteSuggestions from './AutocompleteSuggestions'
import { SuggestionsPopper } from './SuggestionsPopper'
import { useProximity } from './useProximity'
import { useWaypointPlaceholder } from './useWaypointPlaceholder'
import classes from './WaypointItem.module.scss'

const FIRST_SUGGESTION_INDEX = 0

function useSelectionIndex(suggestionCount) {
  const [suggestionIndex, selectSuggestion] = useState(0)

  const selectFirstSuggestion = () =>
    selectSuggestion(() => FIRST_SUGGESTION_INDEX)

  const selectPreviousSuggestion = () =>
    selectSuggestion(Math.max(FIRST_SUGGESTION_INDEX, suggestionIndex - 1))

  const selectNextSuggestion = () => {
    const maxIndex = suggestionCount
      ? suggestionCount - 1
      : FIRST_SUGGESTION_INDEX

    selectSuggestion(Math.min(maxIndex, suggestionIndex + 1))
  }

  return {
    suggestionIndex,
    selectSuggestion,
    selectFirstSuggestion,
    selectPreviousSuggestion,
    selectNextSuggestion,
  }
}

export function InputAddress({
  index,
  location,
  tabIndex,
  type,
  onChange,
  title,
  isDirty,
  setCursorPosition,
  inputRef,
  setInputRef,
}) {
  const dispatch = useDispatch()
  const [isFocussed, setIsFocussed] = useState(false)
  const waypointPlaceholder = useWaypointPlaceholder(index)
  const waypointUserInput = useSelector(waypointUserInputSelectors.getState)
  const temporaryWaypoint = waypointUserInput[location?.id]
  const [debounceTitle] = useDebounce(title, 350)
  const proximity = useProximity(type)

  const waypointSuggestions = useWaypointSuggestionsQuery(
    debounceTitle,
    proximity,
    {
      // we don't want to load suggestions when
      // the current value is a selected suggestion
      disabled: !temporaryWaypoint,
    },
  )

  const suggestionCount = waypointSuggestions.data?.length
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      waypointSuggestions.data?.length - 1
    : null

  const {
    suggestionIndex,
    selectSuggestion,
    selectFirstSuggestion,
    selectPreviousSuggestion,
    selectNextSuggestion,
  } = useSelectionIndex(suggestionCount)

  const displaySuggestions =
    !waypointSuggestions.isFetching &&
    isDirty &&
    isFocussed &&
    waypointSuggestions.data?.length > 0

  const setUserInput = (event) => {
    dispatch(
      waypointUserInputActions.editWaypoint({
        id: location.id,
        changes: { title: event.target.value },
      }),
    )

    setCursorPosition(inputRef.selectionStart)
  }

  const resolveAndApplySuggestion = async () => {
    /* eslint-disable camelcase */
    const { place_name, geometry } = waypointSuggestions.data[suggestionIndex]
    const [lng, lat] = geometry.coordinates
    let nextLocation = { title: place_name, lng, lat }

    try {
      const closestStreetCoords = await getClosestStreetCoordForWaypoint(
        nextLocation,
      )
      nextLocation = { title: place_name, ...closestStreetCoords }
      /* eslint-enable camelcase */
    } catch (e) {} // eslint-disable-line no-empty

    onChange(nextLocation)
    setCursorPosition(-1)
  }

  const handleKey = (event) => {
    if (!displaySuggestions) {
      return
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      selectPreviousSuggestion()
      return
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault()
      selectNextSuggestion()
      return
    }

    if (!['Tab', 'Enter'].includes(event.key)) {
      // text changed
      selectFirstSuggestion()
      return
    }

    event.preventDefault()
    resolveAndApplySuggestion()
  }

  return (
    <>
      <input
        tabIndex={tabIndex}
        type="text"
        ref={setInputRef}
        value={title}
        className={classes.title}
        placeholder={waypointPlaceholder}
        onKeyDown={handleKey}
        onChange={setUserInput}
        onBlur={() => {
          setIsFocussed(false)

          if (displaySuggestions) {
            resolveAndApplySuggestion()
          }

          setCursorPosition(-1)
        }}
        onFocus={() => {
          setIsFocussed(true)
        }}
      />

      {waypointSuggestions.isFetching && (
        <CircularLoadingGreyToTransparent className={classes.loadingSpinner} />
      )}

      {displaySuggestions &&
        ReactDOM.createPortal(
          <SuggestionsPopper inputRef={inputRef}>
            <AutocompleteSuggestions
              suggestions={waypointSuggestions.data?.slice(0, 5) || []}
              suggestionIndex={suggestionIndex}
              onMouseEnter={selectSuggestion}
            />
          </SuggestionsPopper>,
          document.body,
        )}
    </>
  )
}

InputAddress.defaultProps = {
  location: {},
  title: '',
  inputRef: null,
}

InputAddress.propTypes = {
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  setCursorPosition: PropTypes.func.isRequired,
  title: PropTypes.string,
  isDirty: PropTypes.bool.isRequired,
  inputRef: PropTypes.instanceOf(HTMLInputElement),
  setInputRef: PropTypes.func.isRequired,
}
