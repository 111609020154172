/* eslint-disable no-console,no-unused-vars */
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch } from 'react-redux'
import { CheckIcon } from '../../assets'
import { Button } from '../../components'
import { postGpxFileForWaypoints, useDispatchAndComputeRoute } from '../../lib'
import classes from './WizardGpxImport.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

function FileInput({ control }) {
  const { formatMessage } = useIntl()

  return (
    <Controller
      control={control}
      rules={{
        required: true,
        pattern: {
          value: /\.gpx$/,
          message: 'Only files with the ".gpx" file ending are supported!',
        },
      }}
      name="file"
      type="file"
      render={({
        field: { value, onChange, onBlur },
        formState: {
          errors: { file: error },
        },
      }) => (
        <>
          <label htmlFor="file">
            <div className={classes.fileNameContainer}>
              <span className={classes.fileName}>{value?.name}</span>

              {value && !error && (
                <CheckIcon className={classes.fileNameCheckIcon} />
              )}
            </div>

            <span className={classes.input}>
              {formatMessage({
                id: 'importdialog.file_import_button_label',
                defaultMessage: 'Choose file',
              })}
            </span>
          </label>

          {error && <div className={classes.error}>{error.message}</div>}

          <input
            onChange={(event) => onChange(event.target.files[0])}
            onBlur={onBlur}
            value={value?.fileName}
            className={classes.realInput}
            type="file"
            accept=".gpx"
            id="file"
          />
        </>
      )}
    />
  )
}

FileInput.propTypes = {
  control: PropTypes.object.isRequired,
}

export function WizardGpxImport({ onLoad, onError, onDone }) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' })

  const importRoute = async (values) => {
    console.log('> values', values)
    const { file } = values
    onLoad()

    try {
      const { waypoints, polyline, decodedPolyline } =
        await postGpxFileForWaypoints(file)

      // @TODO: Determine what to do here exactly
      // ...
      // dispatch(mapboxActions.zoomToFullRoute())
      onDone()
    } catch (e) {
      onError(e)
    }
  }

  return (
    <form className={classes.container} onSubmit={handleSubmit(importRoute)}>
      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.import_headline',
            defaultMessage: 'Import route',
          })}
        </WizardStepHeadline>
      </div>

      <p className={classes.inputLabel}>
        {formatMessage({
          id: 'importdialog.file_input_label',
          defaultMessage: 'GPX file',
        })}
      </p>

      <FileInput control={control} />

      <p className={classes.fileImportDescription}>
        {formatMessage({
          id: 'importdialog.file_import_description',
          defaultMessage:
            // eslint-disable-next-line max-len
            'Lade eine GPX-Datei von deinem Mac, oder deinem PC hoch um deine Route zu importieren und Sie in unserem Routen editor zu inspizieren, feinzutunen und in die Roads App zu teilen.',
        })}
      </p>

      <div className={classes.actions}>
        <Button type="submit" disabled={!isDirty || !isValid}>
          <span className={classes.submitButtonLabel}>
            {formatMessage({
              id: 'importdialog.gmaps_submit_button',
              defaultMessage: 'Import',
            })}
          </span>
        </Button>
      </div>
    </form>
  )
}

WizardGpxImport.propTypes = {
  onDone: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
}
