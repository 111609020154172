/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { waypointUserInputSelectors } from '../../lib'

export function useFocusWhenInitiallyDirty(location, inputRef) {
  const waypointUserInput = useSelector(waypointUserInputSelectors.getState)
  const temporaryWaypoint = waypointUserInput[location?.id]
  const isDirty = !!temporaryWaypoint

  // Focus when persisted input state is dirty
  // This should be the case for a single input only,
  // if this ever happens for more than a single input, then that's a bug!
  useEffect(
    () => {
      if (inputRef && isDirty) {
        inputRef.focus()
      }
    },
    // We don't want to react to `isDirty` changes,
    // as we're already focusing the field
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef],
  )
}
