import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  CountryIcon,
  ForestIcon,
  MountainIcon,
  ShoreIcon,
  StartIcon,
} from '../../assets'
import { environments } from '../../lib'
import classes from './ToggleWithText.module.scss'

export function ToggleWithText({
  variant,
  children,
  active,
  disabled,
  onClick,
}) {
  let icon
  let variantClass
  switch (variant) {
    case environments.FOREST:
      icon = <ForestIcon />
      variantClass = classes.forest
      break
    case environments.MOUNTAIN:
      icon = <MountainIcon />
      variantClass = classes.mountain
      break
    case environments.COUNTRY:
      icon = <CountryIcon />
      variantClass = classes.country
      break
    case environments.SHORE:
      icon = <ShoreIcon />
      variantClass = classes.shore
      break
    default:
      icon = <StartIcon />
  }

  return (
    <button
      type="button"
      className={classnames(
        classes.container,
        { [classes.disabled]: disabled },
        { [classes.active]: active },
        { [variantClass]: variant },
      )}
      onClick={onClick}
    >
      <span className={classes.iconContainer}>{icon}</span>
      <span className={classes.text}>{children}</span>
    </button>
  )
}

ToggleWithText.defaultProps = {
  variant: 'default',
  active: false,
  disabled: false,
  onClick: () => {},
}

ToggleWithText.propTypes = {
  variant: PropTypes.oneOf(Object.values(environments)),
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
