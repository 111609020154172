import { useCallback, useState } from 'react'

export const useFollow = () => {
  const [{ followField, followRadius, following }, _setFollow] = useState({
    followField: null,
    followRadius: -1,
    following: false,
  })

  const setFollowField = useCallback((el) => {
    if (!el) {
      return _setFollow((prevFollow) => ({
        ...prevFollow,
        followField: null,
        followRadius: 0,
      }))
    }

    return _setFollow((prevFollow) => ({
      ...prevFollow,
      followField: el,
      followRadius: el.getBoundingClientRect().width / 2,
    }))
  }, [])

  const setFollowing = useCallback((nextFollowing) => {
    _setFollow((prevFollow) => ({
      ...prevFollow,
      following: nextFollowing,
    }))
  }, [])

  return {
    followField,
    followRadius,
    following,
    setFollowField,
    setFollowing,
  }
}
