import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import {
  getCircleCoordinatesFromValues,
  getRelativePercentFromCircleCoordinates,
} from './geometry'
import classes from './NavigationCircle.module.scss'
import { useFollow } from './useFollow'
import { useFollower } from './useFollower'
import { useIndicator } from './useIndicator'
import { useMouseEventHandlers } from './useMouseEventHandlers'
import { useMoveTo } from './useMoveTo'
import { useRegisterMouseEvents } from './useRegisterMouseEvents'
import { useSetMoveToPosition } from './useSetMoveToPosition'
import { useSetTargetPosition } from './useSetTargetPosition'
import { useTarget } from './useTarget'

export function NavigationCircle({ values, valueChangeFunction, options }) {
  const { setIndicator, drawIndicator } = useIndicator()
  const [initialized, setInitialized] = useState(false)
  const [propertyValues, setPropertyValues] = useState({
    x: 0,
    y: 0,
  })

  const circle = useRef()

  const {
    target,
    targetX,
    targetY,
    targetAngle,
    targetWidth,
    setTarget,
    setTargetCoords,
    setTargetAngle,
    setTargetWidth,
  } = useTarget()

  const { moveToX, moveToY, moving, setMoveTo, setMoving } = useMoveTo()

  const { followField, followRadius, following, setFollowField, setFollowing } =
    useFollow()

  const {
    followerAngle,
    followerWidth,
    setFollower,
    setFollowerAngle,
    setFollowerWidth,
  } = useFollower(followField, targetAngle, targetWidth)

  const [dragging, setDragging] = useState(false)
  const [mouseGrabPosition, setMouseGrabPosition] = useState({ x: 0, y: 0 })

  const setTargetPosition = useSetTargetPosition({
    target,
    followField,
    followRadius,
    mouseGrabPosition,
    setTargetCoords,
    setTargetAngle,
    setTargetWidth,
    setPropertyValues,
  })

  const setMoveToPosition = useSetMoveToPosition({
    followField,
    followRadius,
    setMoveTo,
  })

  const {
    mouseDownHandler,
    mouseUpHandler,
    mouseMoveHandler,
    mouseClickHandler,
  } = useMouseEventHandlers({
    dragging,
    drawIndicator,
    followField,
    followerAngle,
    followerWidth,
    following,
    options,
    setFollowerAngle,
    setFollowerWidth,
    setFollowing,
    setTargetPosition,
    target,
    targetX,
    targetY,
    targetAngle,
    targetWidth,
    setDragging,
    setMouseGrabPosition,
    mouseGrabPosition,
    setMoveToPosition,
    circle,
    moveToX,
    moveToY,
    moving,
    setMoving,
    valueChangeFunction,
  })

  useEffect(() => {
    if (followField && !initialized) {
      setTargetPosition(0.5, 0.5, false)
      drawIndicator(0, 2 * Math.PI)
      setInitialized(() => true)
    }

    if (initialized) {
      const moveToCircleCoordinates = getCircleCoordinatesFromValues({
        x: values.x,
        y: values.y,
      })
      // console.log('circle coordinates from values', moveToCircleCoordinates)
      const moveToPosition = getRelativePercentFromCircleCoordinates({
        angle: moveToCircleCoordinates.angle,
        length: moveToCircleCoordinates.length,
        rounding: true,
      })
      // console.log('relative position from circle coordinates', moveToPosition)

      setMoveToPosition(moveToPosition.x, moveToPosition.y, false)
      setMoving(true)
      setFollowing(true)
    }
  }, [
    followField,
    setTargetPosition,
    drawIndicator,
    initialized,
    setInitialized,
    setMoveToPosition,
    values,
    setMoving,
    setFollowing,
  ])

  useRegisterMouseEvents({
    target,
    circle,
    mouseDownHandler,
    mouseUpHandler,
    mouseMoveHandler,
    mouseClickHandler,
  })
  const { formatMessage } = useIntl()

  return (
    <div className={classes.navigationCircleContainer}>
      <div
        className={classes.labelTop}
        style={{
          opacity: 0.6 + 0.4 * Math.max(0, propertyValues.y),
        }}
      >
        {formatMessage({
          id: 'routepropertycontroller.flatland',
          defaultMessage: 'flatland',
        })}
      </div>
      <div
        className={classes.labelLeft}
        style={{
          opacity: 0.6 + 0.4 * Math.max(0, propertyValues.x * -1),
        }}
      >
        {formatMessage({
          id: 'routepropertycontroller.curvy',
          defaultMessage: 'curvy',
        })}
      </div>
      <div
        className={classes.labelRight}
        style={{
          opacity: 0.6 + 0.4 * Math.max(0, propertyValues.x),
        }}
      >
        {formatMessage({
          id: 'routepropertycontroller.straight',
          defaultMessage: 'straight',
        })}
      </div>
      <div
        className={classes.labelBottom}
        style={{
          opacity: 0.6 + 0.4 * Math.max(0, propertyValues.y * -1),
        }}
      >
        {formatMessage({
          id: 'routepropertycontroller.hilly',
          defaultMessage: 'mountain',
        })}
      </div>
      <button
        type="button"
        ref={circle}
        className={classes.circleField}
        onClick={mouseClickHandler}
      >
        <div ref={setFollowField} className={classes.followField}>
          <div ref={setTarget} className={classes.target} />
          <div ref={setFollower} className={classes.follower} />
        </div>

        <canvas
          ref={setIndicator}
          width="120"
          height="120"
          className={classes.indicator}
        />
      </button>
    </div>
  )
}

NavigationCircle.propTypes = {
  values: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  valueChangeFunction: PropTypes.func.isRequired,
  options: PropTypes.shape({
    spring: PropTypes.number.isRequired,
    inertia: PropTypes.number.isRequired,
    moveSpring: PropTypes.number.isRequired,
    moveInertia: PropTypes.number.isRequired,
  }).isRequired,
}
