import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { Checkbox } from '../../../components'

export function PublishCheckbox({ field: { onChange, value } }) {
  const { formatMessage } = useIntl()

  return (
    <Checkbox
      onChange={onChange}
      checked={value}
      id="publish"
      name="publish"
      label={formatMessage({
        id: 'savedialog.label_publish',
        defaultMessage: 'Activate this to grant public access to your route.',
      })}
    />
  )
}

PublishCheckbox.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}
