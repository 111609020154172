import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { fetchApiWp, userSelectors } from '../../../lib'

export function useSaveRouteMutation() {
  const roadsUser = useSelector(userSelectors.getRoadsUser)
  const { formatMessage } = useIntl()

  return useMutation({
    mutationFn: async ({
      description,
      name,
      pictureId,
      proposalId,
      publish,
    }) => {
      const body = JSON.stringify({
        name,
        description,
        publish,
        pictureIds: [pictureId],
        proposalId,
      })

      // eslint-disable-next-line no-unreachable
      let result
      try {
        result = await fetchApiWp('saveroute', {
          sessionId: roadsUser?.sessionId,
          method: 'POST',
          body,
        })
      } catch (e) {
        throw new Error(
          formatMessage({
            id: 'savedialog.error_saving_route',
            defaultMessage:
              'There was an error while trying to save your route. Please try again later.',
          }),
        )
      }

      if (!result?.success === true) {
        throw new Error(
          formatMessage({
            id: 'savedialog.error_session_expired',
            defaultMessage: 'Your session expired. Please log in again.',
          }),
        )
      }

      return result
    },
  })
}
