import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  mapboxActions,
  routeActions,
  routeSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'

export const useApplySuggestion = (location) => {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const waypointList = useSelector(routeSelectors.getWaypoints)

  return useCallback(
    async (nextLocation) => {
      const action = routeActions.editWaypoint({
        id: location.id,
        changes: nextLocation,
      })

      // When there's only one waypoint and it's not a roundtrip,
      // we don't want to fetch a trip as that requires min. 2 waypoints
      const actualWaypoints = waypointList.filter(
        (actualWaypoint) => !!actualWaypoint.lat,
      )
      const [firstActualWaypoint] = actualWaypoints

      if (
        // Either more than one
        actualWaypoints.length > 1 ||
        // Or has one and user is not editing the first one
        (actualWaypoints.length === 1 && location.id !== firstActualWaypoint.id)
      ) {
        await dispatchAndComputeRoute(action)
        dispatch(mapboxActions.zoomToFullRoute())
        return
      }

      const { lat, lng } = nextLocation
      dispatch(mapboxActions.zoomToCoordinates({ lng, lat }))
      dispatch(action)
    },
    [location, waypointList, dispatch, dispatchAndComputeRoute],
  )
}
