import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { mapboxActions, routeActions, routeSelectors } from '../../lib/index'
import classes from './LoadingError.module.scss'

export function LoadingError() {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const stepBackCount = useSelector(routeSelectors.getStepBackCount)
  const jumpBackCount = stepBackCount * -1

  const undo = () => {
    if (stepBackCount !== 0) {
      dispatch(UndoActionCreators.jump(jumpBackCount))
      dispatch(mapboxActions.zoomToFullRoute())
    } else {
      dispatch(routeActions.resetLoadingCoordsError())
    }
  }

  return (
    <div className={classes.loadingError}>
      <div className={classes.label}>{`

      ${formatMessage({
        id: 'global.something_went_wrong',
        defaultMessage: 'Something went wrong',
      })}`}</div>

      <button className={classes.okButton} type="button" onClick={undo}>
        {formatMessage({ id: 'global.ok', defaultMessage: 'ok' })}
      </button>
    </div>
  )
}
