import { postPorsche } from '../api/index'

export const postRoundtrip = async (
  { routeSessionId, routePreferences, roundtripPreferences },
  { signal },
) => {
  // No need to extract anything. We could think about extracting the
  // "proposedRoute" object and either copy the id into it or ignore the id.
  const coords = await postPorsche('route-proposals/roundtrips', {
    body: {
      correlation: { sessionId: routeSessionId },
      routePreferences,
      roundtripPreferences,
    },
    signal,
  })

  return coords
}
