import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'
import { Sort } from '../../assets'
import LocationType from './LocationType'
import WaypointItem from './WaypointItem'
import classes from './WaypointItemSortable.module.scss'

function WaypointItemSortable({ id, location, tabIndex, type, index }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      className={classes.waypointSortable}
      ref={setNodeRef}
      style={style}
      {...attributes}
      data-dragging={isDragging}
      tabIndex={-1}
    >
      <div className={classes.dragHandle} {...listeners}>
        <Sort className={classes.sortIcon} />
      </div>
      <WaypointItem
        type={type}
        location={location}
        tabIndex={tabIndex}
        index={index}
      />
    </div>
  )
}

WaypointItemSortable.defaultProps = {
  tabIndex: 1,
  type: 'stop',
}

WaypointItemSortable.propTypes = {
  id: PropTypes.string.isRequired,
  location: LocationType.isRequired,
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
}

export default WaypointItemSortable
