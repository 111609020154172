import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ZoomToRoute } from '../../../assets'
import { routeSelectors } from '../../../lib'
import { ButtonControl } from './ButtonControl'
import classes from './ZoomToFullRoute.module.scss'

export function ZoomToFullRoute({ onZoomToFullRoute }) {
  const isLoadingCoords = useSelector(routeSelectors.getLoadingCoords)
  const loadingCoordsFailed = useSelector(routeSelectors.getLoadingCoordsError)
  const insufficientWaypoints =
    useSelector(routeSelectors.getLatLangWaypoints).length < 2
  const disabled =
    isLoadingCoords || loadingCoordsFailed || insufficientWaypoints

  return (
    <div className={classes.container}>
      <ButtonControl disabled={disabled} onClick={onZoomToFullRoute}>
        <ZoomToRoute />
      </ButtonControl>
    </div>
  )
}

ZoomToFullRoute.propTypes = {
  onZoomToFullRoute: PropTypes.func.isRequired,
}
