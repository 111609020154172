import PropTypes from 'prop-types'
import { useState } from 'react'
import { usePopper } from 'react-popper'
import classes from './WaypointItem.module.scss'

export function SuggestionsPopper({ children, inputRef }) {
  const [popperRef, setPopperRef] = useState(null)
  const { styles, attributes } = usePopper(inputRef, popperRef, {
    placement: 'bottom-start',
  })

  return (
    <div
      className={classes.autocompletePopperContainer}
      ref={setPopperRef}
      style={{ ...styles.popper, width: inputRef?.offsetWidth }}
      {...attributes.popper}
    >
      {children}
    </div>
  )
}

SuggestionsPopper.propTypes = {
  children: PropTypes.any.isRequired,
  inputRef: PropTypes.instanceOf(HTMLInputElement).isRequired,
}
