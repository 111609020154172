import { closestCenter, DndContext } from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  routeActions,
  routeSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'
import classes from './WaypointsList.module.scss'

function hasCrossedActualWaypoint(waypoints, activeIndex, overIndex) {
  const crossedWaypoints = waypoints.slice(
    Math.min(activeIndex, overIndex),
    Math.max(activeIndex, overIndex) + 1,
  )
  const actualWaypointsCount = crossedWaypoints.reduce((acc, cur) => {
    if (cur.lat && cur.lng) {
      return acc + 1
    }

    return acc
  }, 0)

  // Only when two waypoints with actual lat/lng are effected, it means an
  // actual waypoint has been crossed
  return actualWaypointsCount > 1
}

export function WaypointsList({ children }) {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const waypointList = useSelector(routeSelectors.getWaypoints)
  const scrollableList = useRef(null)
  const [dragging, setDragging] = useState(false)
  const [listAtStart, setListAtStart] = useState(true)
  const [listAtEnd, setListAtEnd] = useState(true)

  const setScrollIndicators = useCallback(() => {
    const container = scrollableList.current
    setListAtStart(() => container.scrollTop === 0)
    setListAtEnd(() => {
      // For some reason there were ~0.3 pixels missing,
      // so adding +1 fixes that
      const tolerance = 1

      return (
        container.scrollTop + container.offsetHeight + tolerance >
        container.scrollHeight
      )
    })
  }, [])

  useEffect(() => {
    setScrollIndicators()
  }, [setScrollIndicators, waypointList])

  const activateDragging = () => {
    setDragging(true)
  }

  const deactivateDragging = () => {
    setDragging(false)
  }

  const handleScroll = () => {
    setScrollIndicators()
  }

  const handleDragEnd = (event) => {
    deactivateDragging()
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      const activeIndex = waypointList.findIndex(
        (waypoint) => waypoint.id === active.id,
      )
      const activeWaypoint = waypointList[activeIndex]
      const overIndex = waypointList.findIndex(
        (waypoint) => waypoint.id === over.id,
      )

      const dispatchFn =
        !activeWaypoint.lat ||
        !activeWaypoint.lng ||
        // We don't need to evaluate this if
        // the moved waypoint is not a real waypoint
        !hasCrossedActualWaypoint(waypointList, activeIndex, overIndex)
          ? dispatch
          : dispatchAndComputeRoute

      dispatchFn(
        routeActions.reorderWaypoints({
          activeIndex,
          overIndex,
        }),
      )
    }
  }

  return (
    <div
      className={classnames({
        [classes.sortableWrapper]: true,
        [classes.sortableAtStart]: listAtStart,
        [classes.sortableAtEnd]: listAtEnd,
        [classes.sortableWrapperDragging]: dragging,
      })}
    >
      <div
        className={classnames({
          [classes.sortable]: true,
        })}
        onScroll={handleScroll}
        ref={scrollableList}
      >
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={activateDragging}
          onDragEnd={handleDragEnd}
          onDragCancel={deactivateDragging}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={waypointList}
            strategy={verticalListSortingStrategy}
          >
            {children}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  )
}

WaypointsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}
