import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { ImportIcon, UrlIcon } from '../../assets'
import { ReactComponent as ButtonArrowIcon } from './ButtonArrow.svg'
import classes from './WizardStart.module.scss'
import { WizardStepHeadline } from './WizardStepHeadline'

export function WizardStart({ onImportGmapsClick, onImportGpxClick }) {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.wizardStart}>
      <div className={classes.headline}>
        <WizardStepHeadline>
          {formatMessage({
            id: 'importdialog.import_headline',
            defaultMessage: 'Import from google maps',
          })}
        </WizardStepHeadline>
      </div>

      <button
        onClick={onImportGmapsClick}
        className={classnames(classes.importButton, classes.gmapsImportButton)}
        type="button"
      >
        <span className={classes.importButtonIcon}>
          <UrlIcon />
        </span>

        <span className={classes.importButtonLabel}>
          {formatMessage({
            id: 'importdialog.import_gmaps_button',
            defaultMessage: 'Import Googlemaps Link',
          })}

          <span className={classes.importButtonLabelArrow}>
            <ButtonArrowIcon />
          </span>
        </span>
      </button>

      <button
        disabled
        title={formatMessage({
          id: 'importdialog.import_gpx_button_disabled',
          defaultMessage:
            'This feature is currently in development. Stay tuned!',
        })}
        onClick={onImportGpxClick}
        className={classnames(classes.importButton, classes.gpxImportButton)}
        type="button"
      >
        <span className={classes.importButtonIcon}>
          <ImportIcon />
        </span>

        <span className={classes.importButtonLabel}>
          {formatMessage({
            id: 'importdialog.import_gpx_button',
            defaultMessage: 'Import GPX-File',
          })}

          <span className={classes.importButtonLabelArrow}>
            <ButtonArrowIcon />
          </span>
        </span>
      </button>

      <p className={classes.optionsDescription}>
        {formatMessage({
          id: 'importdialog.options_description',
          defaultMessage:
            // eslint-disable-next-line max-len
            'Wähle eine der Optionen um deine Route zu importieren, und Sie in unserem Routen editor zu inspizieren, feinzutunen und in die Roads App zu teilen.',
        })}
      </p>
    </div>
  )
}

WizardStart.propTypes = {
  onImportGmapsClick: PropTypes.func.isRequired,
  onImportGpxClick: PropTypes.func.isRequired,
}
