import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CompassIcon } from '../../../assets/index'
import { mapboxSelectors } from '../../../lib/index'
import classes from './Compass.module.scss'

export function Compass({ onClick }) {
  const [rotation, setRotation] = useState(0)
  const mapbox = useSelector(mapboxSelectors.getMapbox)

  useEffect(() => {
    if (!mapbox) return

    mapbox.on('rotate', () => {
      const rounded = -Math.floor(mapbox.getBearing())
      setRotation(rounded)
    })
  }, [mapbox])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={{ transform: `rotate(${rotation}deg)` }}
      className={classnames(classes.container, {
        [classes.rotated]: rotation !== 0,
      })}
      onClick={onClick}
    >
      <CompassIcon />
    </div>
  )
}

Compass.propTypes = {
  onClick: PropTypes.func.isRequired,
}
