import classnames from 'classnames'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { EndIcon, RemoveIcon, StartIcon } from '../../assets'
import { Input } from './Input'
import LocationType from './LocationType'
import { useApplySuggestion } from './useApplySuggestion'
import { useRemoveWaypoint } from './useRemoveWaypoint'
import classes from './WaypointItem.module.scss'

const WaypointItem = memo(({ location, type, tabIndex, index }) => {
  const removeWaypoint = useRemoveWaypoint()
  const applySuggestion = useApplySuggestion(location)

  return (
    <div className={classes.waypoint} tabIndex={-1}>
      <div className={classes.limiter}>
        <div className={classes.ui}>
          <div className={classnames(classes.roleIcon, classes[type])}>
            {type === 'start' && <StartIcon />}
            {type === 'end' && <EndIcon />}
            {type === 'stop' && <p className={classes.index}>{index}</p>}
          </div>

          <div className={classes.titleContainer}>
            <Input
              index={index}
              location={location}
              tabIndex={tabIndex}
              type={type}
              onChange={applySuggestion}
            />
          </div>

          <div className={classes.removeButtonContainer}>
            <button
              type="button"
              onClick={() => removeWaypoint(location)}
              className={classes.removeButton}
            >
              <RemoveIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

WaypointItem.defaultProps = {
  type: 'stop',
  tabIndex: 1,
}

WaypointItem.propTypes = {
  location: LocationType.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['stop', 'start', 'end']),
  tabIndex: PropTypes.number,
}

export default WaypointItem
