/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { waypointUserInputActions } from '../../lib'
import AutocompleteSuggestions from './AutocompleteSuggestions'
import { SuggestionsPopper } from './SuggestionsPopper'
import { useWaypointPlaceholder } from './useWaypointPlaceholder'
import classes from './WaypointItem.module.scss'

const LAT_LNG_REGEXP = /^([0-9]{1,2}([.][0-9]+)?),\s?([0-9]{1,3}([.][0-9]+)?)$/

const parseLatLng = (raw) => {
  const [, inputLat, , inputLng] = raw.match(LAT_LNG_REGEXP) || []
  const lat = parseFloat(inputLat)
  const lng = parseFloat(inputLng)

  return { lat, lng }
}

export function InputLatLng({
  index,
  location,
  tabIndex,
  onChange,
  title,
  isDirty,
  setCursorPosition,
  inputRef,
  setInputRef,
}) {
  const dispatch = useDispatch()
  const [isFocussed, setIsFocussed] = useState(false)
  const waypointPlaceholder = useWaypointPlaceholder(index)

  return (
    <>
      <input
        tabIndex={tabIndex}
        type="text"
        ref={setInputRef}
        value={title}
        className={classes.title}
        placeholder={waypointPlaceholder}
        onKeyDown={(event) => {
          if (!['Tab', 'Enter'].includes(event.key)) {
            return
          }

          event.preventDefault()
          const { lat, lng } = parseLatLng(event.target.value)
          onChange({ lat, lng, title: `${lat}, ${lng}` })
          setCursorPosition(-1)
          inputRef.blur()
        }}
        onChange={(event) => {
          dispatch(
            waypointUserInputActions.editWaypoint({
              id: location.id,
              changes: { title: event.target.value },
            }),
          )
          setCursorPosition(inputRef.selectionStart)
        }}
        onBlur={() => {
          setIsFocussed(false)

          if (isDirty) {
            const { lat, lng } = parseLatLng(title)
            onChange({ lat, lng, title: `${lat}, ${lng}` })
            setCursorPosition(-1)
          }
        }}
        onFocus={() => setIsFocussed(true)}
      />

      {isDirty &&
        isFocussed &&
        ReactDOM.createPortal(
          <SuggestionsPopper inputRef={inputRef}>
            <AutocompleteSuggestions
              suggestions={[{ id: title, place_name: title }]}
              suggestionIndex={0}
            />
          </SuggestionsPopper>,
          document.body,
        )}
    </>
  )
}

InputLatLng.defaultProps = {
  location: {},
  title: '',
  inputRef: null,
}

InputLatLng.propTypes = {
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.any.isRequired,
  onChange: PropTypes.any.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  setCursorPosition: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  title: PropTypes.string,
  inputRef: PropTypes.instanceOf(HTMLInputElement),
  setInputRef: PropTypes.func.isRequired,
}
