import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { getPorsche } from '../lib/index'

/* eslint-disable no-console */
const logger = {
  log: console.log,
  warn: console.warn,
  error: () => {},
}
/* eslint-enable no-console */

const queryFn = ({ queryKey }) => {
  const [resource] = queryKey
  return getPorsche(resource)
}

export const queryClient = new QueryClient({
  logger,
  defaultOptions: {
    queries: {
      queryFn,
      refetchOnWindowFocus: false,
    },
  },
})

export function ReactQueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
