import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import styles from './RangeSlider.module.scss'

export function RangeSlider({ value, setValue, options }) {
  const [internalValue, setInternalValue] = useState(0.5)
  const [animating, setAnimating] = useState(false)
  const [dragging, setDragging] = useState(false)
  const { formatMessage } = useIntl()

  const tick = useCallback(() => {
    const difference = value - internalValue
    if (Math.abs(difference) < 0.001) {
      setAnimating(() => false)
    } else {
      setInternalValue(
        (oldValue) =>
          parseFloat(oldValue) + difference / options.inertia / options.spring,
      )
    }
  }, [internalValue, value, setInternalValue, setAnimating, options])

  useEffect(() => {
    if (animating) {
      requestAnimationFrame(() => tick())
    }
  }, [animating, value, tick])

  useEffect(() => {
    setAnimating(() => true)
  }, [value, setAnimating])

  const onChange = (event) => {
    setInternalValue(() => event.target.value)
  }

  const onMouseUp = () => {
    if (dragging) {
      setValue(internalValue)
      setAnimating(() => true)
    }
    setDragging(() => false)
  }

  const onMouseDown = () => {
    setDragging(() => true)
    setAnimating(() => false)
  }

  return (
    <div className={styles.sliderContainer}>
      <input
        type="range"
        min="0"
        max="1"
        value={internalValue}
        onChange={onChange}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
        step="any"
        className={styles.slider}
      />
      <div className={styles.labels}>
        <span
          style={{
            opacity:
              1 - 0.7 * Math.min(1, Math.max(0, (internalValue - 0.2) / 0.6)),
          }}
        >
          {formatMessage({
            id: 'routepropertycontroller.slow',
            defaultMessage: 'slow',
          })}
        </span>
        <span
          style={{
            opacity:
              0.3 + 0.7 * Math.min(1, Math.max(0, (internalValue - 0.2) / 0.6)),
          }}
        >
          {formatMessage({
            id: 'routepropertycontroller.speedy',
            defaultMessage: 'speedy',
          })}
        </span>
      </div>
    </div>
  )
}

RangeSlider.defaultProps = {
  value: 0.5,
}

RangeSlider.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.shape({
    spring: PropTypes.number.isRequired,
    inertia: PropTypes.number.isRequired,
  }).isRequired,
}
