import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { CircularLoadingGreyToWhite, CloseSmallIcon } from '../../assets'
import { routeSelectors } from '../../lib'
import styles from './imageUpload.module.scss'

// Constants
const MAX_FILESIZE = 5000000
const MAX_FILES = 1
// TODO: Refactor to env file

export const ImageUpload = forwardRef(
  ({ active, onClick, onRemove, onError, onImage, uploading }, ref) => {
    const thumbWrapRef = useRef()
    const { formatMessage } = useIntl()
    const [files, setFiles] = useState([])
    const routeId = useSelector(routeSelectors.getId)

    useEffect(() => {
      if (routeId) {
        setFiles([])
      }
    }, [routeId])

    const ERROR_MAP = {
      'file-invalid-type': formatMessage({
        id: 'imageUploadError.file-invalid-type',
        defaultMessage:
          'The file format is not supported. Please ensure that you only use png or jpg formats.',
      }),
      'file-too-large': formatMessage(
        {
          id: 'imageUploadError.file-too-large',
          defaultMessage: `Your file is bigger than the max filesize of { maxFilesize }MB.`,
        },
        { maxFilesize: MAX_FILESIZE / 1000000 },
      ),
    }
    const onDrop = (accFiles, rejectedFiles) => {
      setFiles(
        accFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )

      if (accFiles.length > 0) {
        onImage({ file: accFiles[0] })
      }

      if (!accFiles.length && rejectedFiles.length) {
        const [rejectedFile] = rejectedFiles
        const { errors, file } = rejectedFile
        const [{ code }] = errors
        onError({
          file,
          error: ERROR_MAP[code] || code,
        })
      }
    }

    const { getRootProps, getInputProps, acceptedFiles, inputRef } =
      useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': [],
          'image/webp': [],
          'image/heic': [],
          'image/heif': [],
          'image/jfif': [],
        },
        maxSize: MAX_FILESIZE,
        maxFiles: MAX_FILES,
        multiple: false,
        onDrop,
      })

    const remove = useCallback(() => {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
      setFiles([])
      onRemove(null)
    }, [acceptedFiles, inputRef, onRemove])

    /* Allows to call resetDefault methodes from parent component */
    useImperativeHandle(
      ref,
      () => ({
        resetDefault() {
          remove()
        },
      }),
      [remove],
    )

    function createThumbnail(fileList) {
      return fileList?.map((file) => (
        <div className={styles.thumb} key={file.name}>
          <div
            className={styles.thumbInner}
            onClick={() => {
              onClick()
            }}
          >
            <img src={file.preview} className={styles.image} alt="" />
          </div>
          <button
            className={styles.thumbButton}
            onClick={() => {
              remove()
            }}
            type="button"
          >
            <CloseSmallIcon />
          </button>
        </div>
      ))
    }

    return (
      <div className={classNames(styles.imageUpload, active && styles.active)}>
        <div {...getRootProps({ className: styles.dropzone })}>
          <input autoComplete="off" {...getInputProps()} />
          <div className={styles.icon} />
        </div>
        <div
          ref={thumbWrapRef}
          className={classNames(
            styles.thumbsWrap,
            files?.length === 0 && 'hidden',
          )}
        >
          {createThumbnail(files)}
        </div>
        <div
          className={classNames(styles.loader, {
            hidden: !uploading,
          })}
        >
          <CircularLoadingGreyToWhite className={styles.loaderIcon} />
        </div>
      </div>
    )
  },
)

ImageUpload.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onImage: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
}
