import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch, useSelector } from 'react-redux'
import { UnisizeEnd } from '../../assets'
import {
  getClosestStreetCoordForWaypoint,
  routeActions,
  routeSelectors,
  useDispatchAndComputeRoute,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'

const { editWaypoint } = routeActions

export function DestinationButton({
  location,
  waypointTitle,
  onDestinationSet,
}) {
  const dispatch = useDispatch()
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formatMessage } = useIntl()
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const hasStart = !!waypoints[0]?.title

  const setEnd = async () => {
    const { lng, lat } = await getClosestStreetCoordForWaypoint(location)
    const idLastWaypoint = waypoints.slice(-1)[0]?.id
    const action = editWaypoint({
      id: idLastWaypoint || 'end',
      changes: {
        lng,
        lat,
        title: waypointTitle,
      },
    })

    if (hasStart) {
      dispatchAndComputeRoute(action)
    } else {
      dispatch(action)
    }

    onDestinationSet()
  }

  return (
    <Button size="small" className={classes.button} onClick={setEnd}>
      <UnisizeEnd className={classes.buttonIcon} />
      <p className={classes.buttonText}>
        {formatMessage({
          id: 'contextmenu.destination',
          defaultMessage: 'destination',
        })}
      </p>
    </Button>
  )
}

DestinationButton.defaultProps = {
  location: {
    lat: 0,
    lng: 0,
  },
}

DestinationButton.propTypes = {
  waypointTitle: PropTypes.string.isRequired,
  onDestinationSet: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}
