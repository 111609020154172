import { useIntl } from 'react-intl-phraseapp'

export function useWaypointPlaceholder(index, waypointsCount) {
  const { formatMessage } = useIntl()

  if (index === 0) {
    return formatMessage({
      id: 'waypoints.select_start',
      defaultMessage: 'Select start',
    })
  }

  if (index === waypointsCount) {
    return formatMessage({
      id: 'waypoints.select_destination',
      defaultMessage: 'Select destination',
    })
  }

  return formatMessage({
    id: 'waypoints.select_stop',
    defaultMessage: 'Select stop',
  })
}
