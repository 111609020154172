import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl-phraseapp'
import { ArrowButton } from '../../assets'
import { profiles } from '../../lib/index'
import styles from './ProfileChooser.module.scss'

export function ProfileChooser({ activeIndex, onProfileChange }) {
  const { formatMessage } = useIntl()

  const labels = [
    ...Object.keys(profiles).map((key) =>
      formatMessage({
        id: profiles[key].label,
        defaultMessage: profiles[key].defaultMessage,
      }),
    ),
    formatMessage({
      id: 'routepropertycontroller.custom',
      defaultMessage: 'custom',
    }),
  ]
  const sliderRef = useRef()
  const slidesRef = useRef()
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const slides = slidesRef.current
    const children = slides.querySelectorAll('span')
    const sliderWidth = sliderRef.current.offsetWidth
    const childrenWidths = [...children].map((slide) => slide.offsetWidth)
    const nextOffset = childrenWidths.reduce((acc, cur, index) => {
      if (index < activeIndex) {
        return acc - cur
      }

      if (index === activeIndex) {
        return acc - cur / 2
      }

      return acc
    }, sliderWidth / 2)

    setOffset(nextOffset)
  }, [activeIndex])

  function prev() {
    onProfileChange(activeIndex - 1)
  }

  function next() {
    onProfileChange(activeIndex + 1)
  }

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={prev}
        className={classnames([styles.sliderButton, styles.sliderButtonPrev])}
        disabled={activeIndex === 0}
      >
        <ArrowButton />
      </button>

      <div ref={sliderRef} className={styles.slider}>
        <div
          className={styles.slides}
          ref={slidesRef}
          style={{ transform: `translateX(${offset}px)` }}
        >
          {labels.map((label, index) => (
            <span
              onClick={() => onProfileChange(index)}
              className={classnames(styles.slide, {
                [styles.active]: activeIndex === index,
              })}
              key={label}
            >
              {label}
            </span>
          ))}
        </div>
      </div>

      <button
        type="button"
        onClick={next}
        className={classnames([styles.sliderButton, styles.sliderButtonNext])}
        disabled={activeIndex === Object.keys(profiles).length}
      >
        <ArrowButton />
      </button>
    </div>
  )
}

ProfileChooser.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  onProfileChange: PropTypes.func.isRequired,
}
