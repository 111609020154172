import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  contextMenuSelectors,
  getWaypointTitleFromLatLng,
  routeSelectors,
} from '../../lib'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'
import { DestinationButton } from './DestinationButton'
import { Headline } from './Headline'
import { NewDestinationButton } from './NewDestinationButton'
import { RemoveButton } from './RemoveButton'
import { StartButton } from './StartButton'
import { StopButton } from './StopButton'
import { TogglePassByOverButton } from './TogglePassByOverButton'

function useWaypointTitle(location, onTitleRetrieved) {
  // get title of the location the user clicked on.
  useEffect(() => {
    // We don't want to set the title if the user closed the context menu
    let isMounted = true
    const defaultTitle = `${location.lng}, ${location.lat}`

    getWaypointTitleFromLatLng({ lat: location.lat, lng: location.lng })
      .then((word) => isMounted && onTitleRetrieved(word))
      .catch(() => isMounted && onTitleRetrieved(defaultTitle))

    return () => {
      isMounted = false
    }
  }, [location, onTitleRetrieved])
}

function useOnEsc(onClose) {
  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.keyCode !== 27) return
      onClose()
    }

    document.addEventListener('keydown', closeOnEsc)
    return () => document.removeEventListener('keydown', closeOnEsc)
  }, [onClose])
}

export function ContextMenu({ location, close }) {
  const [waypointTitle, setWaypointTitle] = useState(null)
  const waypoints = useSelector(routeSelectors.getWaypoints)
  const waypointId = useSelector(contextMenuSelectors.getWaypointId)
  const hasStart = !!waypoints[0]?.title
  const hasEnd = !!waypoints.slice(-1)[0]?.title
  const isStop = waypoints[0].id !== waypointId && waypoints[-1] !== waypointId

  useWaypointTitle(location, setWaypointTitle)
  useOnEsc(close)

  // if there is a waypointId, the user clicked on an existing waypoint. In
  // this case, we want to show a button to remove the waypoint. Otherwise,
  // we want to show buttons to set the start, end or add a waypoint.
  const showRemoveButton = waypointId && waypoints.length > 1
  const userClickedOnMap = !showRemoveButton

  return (
    <>
      <div className={classes.closeButton} onClick={close} />
      <div className={classes.wrapper}>
        <Headline />

        <p className={classes.title}>{waypointTitle}</p>

        {showRemoveButton && !isStop && <RemoveButton onAfterRemove={close} />}

        {showRemoveButton && isStop && (
          <div className={classes.buttonStack}>
            <TogglePassByOverButton onToggle={close} />
            <RemoveButton onAfterRemove={close} />
          </div>
        )}

        {userClickedOnMap && (
          <div className={classes.buttonStack}>
            {waypointTitle && (!hasStart || !hasEnd) && (
              <StartButton
                location={location}
                waypointTitle={waypointTitle}
                onStartSet={close}
              />
            )}

            {waypointTitle && hasStart && hasEnd && (
              <StopButton
                location={location}
                waypointTitle={waypointTitle}
                onStopSet={close}
              />
            )}

            {waypointTitle && (!hasStart || !hasEnd) && (
              <DestinationButton
                location={location}
                waypointTitle={waypointTitle}
                onDestinationSet={close}
              />
            )}

            {waypointTitle && hasStart && hasEnd && (
              <NewDestinationButton
                location={location}
                waypointTitle={waypointTitle}
                onNewDestinationSet={close}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

ContextMenu.defaultProps = {
  location: {
    lat: 0,
    lng: 0,
  },
}

ContextMenu.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  close: PropTypes.func.isRequired,
}
