import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import { useDispatch } from 'react-redux'
import { tutorialActions } from '../../lib'
import { Button } from '../buttons'
import classes from './Tutorial.module.scss'

export function Tutorial({ show, title, children, arrow, width }) {
  const dispatch = useDispatch()
  const showNextTutorial = () => dispatch(tutorialActions.nextStep())
  const { formatMessage } = useIntl()

  return (
    <aside
      className={classnames(classes.container, {
        [classes.left]: arrow === 'left',
        [classes.right]: arrow === 'right',
        [classes.show]: show,
      })}
    >
      <span className={classes.arrow}>
        <Arrow />
      </span>

      <div className={classes.content} style={{ width }}>
        <h1 className={classes.title}>{title}</h1>
        <p className={classes.explanation}>{children}</p>
        <Button onClick={showNextTutorial}>
          {formatMessage({ id: 'tutorial.check', defaultMessage: 'Got it.' })}
        </Button>
      </div>
    </aside>
  )
}

Tutorial.defaultProps = {
  width: 360,
}

Tutorial.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  arrow: PropTypes.oneOf(['left', 'right']).isRequired,
  show: PropTypes.bool.isRequired,
  width: PropTypes.number,
}

function Arrow() {
  return (
    <svg
      width="17"
      height="32"
      viewBox="0 0 17 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M0.877244 17.799L16.4336 31.3553L16.4336 0L0.877244 13.5563C-0.294329 14.7279 -0.294329 16.6274 0.877244 17.799Z"
        fill="white"
      />
    </svg>
  )
}
