import { createSlice } from '@reduxjs/toolkit'

const name = 'contextMenu'
const getRootState = (state) => state[name]

export const contextMenuSelectors = {
  getWaypointId: (state) => getRootState(state).waypointId,
  getIsOpen: (state) => getRootState(state).isOpen,
  getIsDragging: (state) => getRootState(state).isDragging,
}

export const contextMenuSlice = createSlice({
  name,
  initialState: {
    waypointId: null,
    isOpen: false,
    isDragging: false,
  },
  reducers: {
    contextMenuOpen: (state, { payload }) => {
      state.waypointId = payload
      state.isOpen = true
    },
    contextMenuClose: (state) => {
      state.waypointId = null
      state.isOpen = false
    },
    startDragging: (state) => {
      state.isDragging = true
    },
    stopDragging: (state) => {
      state.isDragging = false
    },
  },
})

export const contextMenuActions = contextMenuSlice.actions
export const contextMenuReducer = contextMenuSlice.reducer
contextMenuSlice.selectors = contextMenuSelectors
