export const profiles = {
  speedy: {
    label: 'routepropertycontroller.speedy',
    defaultMessage: 'speedy',
    curvatureRatio: 0,
    slopeRatio: 0,
    feelGoodSpeedKmh: 170,
  },
  curvy: {
    label: 'routepropertycontroller.curvy',
    defaultMessage: 'curvy',
    curvatureRatio: 1,
    slopeRatio: 0.5,
    feelGoodSpeedKmh: 80,
  },
  balanced: {
    label: 'routepropertycontroller.balanced',
    defaultMessage: 'balanced',
    curvatureRatio: 0.5,
    slopeRatio: 0.5,
    feelGoodSpeedKmh: 100,
  },
}
