import { createSlice } from '@reduxjs/toolkit'

export const TUTORIAL_STEPS = {
  Waypoints: 'Waypoints',
  RouteProperties: 'RouteProperties',
  DetailedFacts: 'DetailedFacts',
  Done: 'Done',
}

const name = 'tutorial'
const stepKeys = Object.keys(TUTORIAL_STEPS)
const getRootState = (state) => state[name]
const getStepIndex = (state) => getRootState(state).stepIndex
const getCurrentStep = (state) => TUTORIAL_STEPS[stepKeys[getStepIndex(state)]]
const getShowWaypointsTutorial = (state) =>
  getCurrentStep(state) === TUTORIAL_STEPS.Waypoints
const getShowRoutePropertyTutorial = (state) =>
  getCurrentStep(state) === TUTORIAL_STEPS.RouteProperties
const getShowDetailedFactsTutorial = (state) =>
  getCurrentStep(state) === TUTORIAL_STEPS.DetailedFacts
const getIsDone = (state) =>
  getRootState(state).stepIndex === stepKeys.length - 1

export const tutorialSlice = createSlice({
  name,
  initialState: {
    stepIndex: 0,
  },
  reducers: {
    nextStep: (state) => {
      if (state.stepIndex < stepKeys.length - 1) {
        ++state.stepIndex
      }
    },
    complete: (state) => {
      state.stepIndex = stepKeys.length - 1
    },
  },
})

export const tutorialActions = tutorialSlice.actions
export const tutorialReducer = tutorialSlice.reducer
export const tutorialSelectors = {
  getCurrentStep,
  getIsDone,
  getShowDetailedFactsTutorial,
  getShowRoutePropertyTutorial,
  getShowWaypointsTutorial,
  getStepIndex,
}
tutorialSlice.selectors = tutorialSelectors
