// eslint-disable-next-line import/order
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax,import/no-unresolved
import MapboxGeocoder from '@mapbox/mapbox-sdk/services/geocoding'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl-phraseapp'

const select = (response) => response.features

// @TODO: This should probably be debounced!
const queryFn = ({ queryKey }) => {
  const geocoder = MapboxGeocoder({ accessToken: mapboxgl.accessToken })
  const [input, proximity, language] = queryKey
  return geocoder
    .forwardGeocode({
      query: input,
      proximity,
      language: [language || 'en'],
    })
    .send()
    .then((response) => response.body)
}

export const useWaypointSuggestionsQuery = (input, proximity, { disabled }) => {
  const { locale } = useIntl()

  return useQuery({
    select,
    enabled: input?.length > 2 && !disabled,
    queryKey: [input, proximity, locale],
    queryFn,
  })
}
