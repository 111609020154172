import { postPorsche } from '../api'

function translate({ translations, original }, locale) {
  return translations[locale] || original.text
}

export function fetchNameAndDescSuggestions({ locale, proposalId }) {
  return postPorsche(`route-proposals/${proposalId}/recommend-texts`).then(
    ({ title, description }) => ({
      title: translate(title, locale),
      description: translate(description, locale),
    }),
  )
}
