import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { CloseButton } from '../buttons'
import classes from './Modal.module.scss'

export function Modal({ children, onClose, portalRoot }) {
  return createPortal(
    <div className={classes.modal}>
      <div className={classes.modalBackground} onClick={onClose} />
      <aside className={classes.modalContent}>
        {children}
        <div className={classes.close}>
          <CloseButton onClick={onClose} />
        </div>
      </aside>
    </div>,
    portalRoot,
  )
}

Modal.defaultProps = {
  portalRoot: document.body,
}

Modal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  portalRoot: PropTypes.instanceOf(HTMLElement),
}
