import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { InputError } from '../../../assets'
import styles from './inputField.module.scss'

export const InputField = forwardRef(
  (
    {
      // TODO: Add autoCompleteType (compare to LocationType.js)
      autoComplete = 'on',
      error,
      id,
      name,
      isRequired,
      label,
      onBlur,
      onChange,
      placeholder,
      type,
      value,
    },
    ref,
  ) => (
    <div className={styles.formGroup}>
      {label && (
        <label htmlFor={id}>
          {label}
          {isRequired && '*'}
        </label>
      )}
      <div className={styles.wrap}>
        <input
          className={classNames(styles.inputField, error && styles.error)}
          autoComplete={autoComplete}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          required={isRequired}
          type={type}
          value={value}
        />
        <div className={styles.icons}>
          <i className={classNames(styles.errorIcon, !error && 'hidden')}>
            <InputError />
          </i>
        </div>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  ),
)

InputField.defaultProps = {
  autoComplete: 'on',
  error: undefined,
  isRequired: undefined,
  label: '',
  placeholder: undefined,
  onBlur: () => null,
  onChange: () => null,
  type: undefined,
  value: undefined,
}

InputField.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'new-password', 'off']),
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}
