import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl-phraseapp'
import { useSelector } from 'react-redux'
import { notchLine } from '../../../assets'
import { Metrics } from '../../../components'
import { MetricUnit } from '../../../components/Metric/MetricEnum'
import { routeSelectors } from '../../../lib'
import { previewImages } from '../constants'
import styles from './routePreview.module.scss'

export function RoutePreview({ customRouteImage }) {
  const { lengthInMeters, drivingTimeSeconds, avgSpeedKmh } = useSelector(
    routeSelectors.getProperties,
  )

  const highestEnvironmentRatio = useSelector(
    routeSelectors.getHighestEnvironmentRatio,
  )
  const routePreviewImageIds = previewImages[highestEnvironmentRatio]

  const metrics = {
    lengthInMeters: {
      value: lengthInMeters,
      unit: MetricUnit.DISTANCE,
    },
    drivingTimeSeconds: {
      value: drivingTimeSeconds,
      unit: MetricUnit.DURATION,
      size: 'large',
    },
    avgSpeedKmh: {
      value: avgSpeedKmh,
      unit: MetricUnit.SPEED,
    },
  }

  const { formatMessage } = useIntl()
  const form = useFormContext()
  const routeImageId = useWatch({ name: 'pictureId', control: form.control })
  const isFirstImage = routeImageId === routePreviewImageIds.IMAGE_PRESET_1.id
  const isSecondImage = routeImageId === routePreviewImageIds.IMAGE_PRESET_2.id
  const isThirdImage = routeImageId === routePreviewImageIds.IMAGE_PRESET_3.id
  const isCustomImage = !isFirstImage && !isSecondImage && !isThirdImage

  return (
    <div className={classNames(styles.routePreview)}>
      <div className={styles.background}>
        <div className={styles.filtered}>
          <img
            className={classNames(styles.clipped, {
              [styles.active]: isFirstImage,
            })}
            src={routePreviewImageIds.IMAGE_PRESET_1.asset}
            alt={formatMessage({
              id: 'savedialog.alt_countryside_route',
              defaultMessage: 'countryside route',
            })}
          />
          <img
            className={classNames(styles.clipped, {
              [styles.active]: isSecondImage,
            })}
            src={routePreviewImageIds.IMAGE_PRESET_2.asset}
            alt={formatMessage({
              id: 'savedialog.alt_curvy_route',
              defaultMessage: 'curvy route',
            })}
          />
          <img
            className={classNames(styles.clipped, {
              [styles.active]: isThirdImage,
            })}
            src={routePreviewImageIds.IMAGE_PRESET_3.asset}
            alt={formatMessage({
              id: 'savedialog.alt_coast_road',
              defaultMessage: 'coast road',
            })}
          />
          <img
            className={classNames(styles.clipped, {
              [styles.active]: isCustomImage,
            })}
            src={customRouteImage?.preview}
            alt={formatMessage({
              id: 'savedialog.alt_coast_road',
              defaultMessage: 'coast road',
            })}
          />
        </div>
        <div className={styles.backdrop} />

        <div className={styles.masked}>
          <img
            id="imagePreview1"
            src={routePreviewImageIds.IMAGE_PRESET_1.asset}
            className={classNames(styles.previewImage, {
              [styles.active]: isFirstImage,
            })}
            alt={formatMessage({
              id: 'savedialog.alt_countryside_route',
              defaultMessage: 'countryside route',
            })}
          />
          <img
            id="imagePreview2"
            src={routePreviewImageIds.IMAGE_PRESET_2.asset}
            className={classNames(styles.previewImage, {
              [styles.active]: isSecondImage,
            })}
            alt={formatMessage({
              id: 'savedialog.alt_curvy_route',
              defaultMessage: 'curvy route',
            })}
          />
          <img
            id="imagePreview3"
            src={routePreviewImageIds.IMAGE_PRESET_3.asset}
            className={classNames(styles.previewImage, {
              [styles.active]: isThirdImage,
            })}
            alt={formatMessage({
              id: 'savedialog.alt_coast_road',
              defaultMessage: 'coast road',
            })}
          />
          <img
            id="imagePreviewCustom"
            src={customRouteImage?.preview}
            className={classNames(
              styles.previewImage,
              styles.previewImageCustom,
              { [styles.active]: isCustomImage },
            )}
            alt={formatMessage({
              id: 'savedialog.alt_custom_route',
              defaultMessage: 'custom route',
            })}
          />
          {/* <img id="imagePreviewCustom" alt="Ihre persönliche Routenvorschau" /> */}
        </div>
        <img className={styles.notchLine} src={notchLine} alt="" />
      </div>
      <div className={styles.metrics}>
        {metrics && <Metrics {...metrics} />}
      </div>
    </div>
  )
}

RoutePreview.defaultProps = {
  customRouteImage: undefined,
}

RoutePreview.propTypes = {
  customRouteImage: PropTypes.instanceOf(File),
}
