import deMessages from './locales/de.json'
import enMessages from './locales/en.json'
import esMessages from './locales/es.json'

function getQueryParamLocale() {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('lang')
}

export const defaultLocale = 'en'

export const locale = getQueryParamLocale() || defaultLocale
document.documentElement.lang = locale

export const locales = {
  en: {
    name: 'English',
    messages: enMessages,
  },
  de: {
    name: 'Deutsch',
    messages: deMessages,
  },
  es: {
    name: 'Español',
    messages: esMessages,
  },
}

export const { messages } = locales[locale]
