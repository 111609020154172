import PropTypes from 'prop-types'
import { useIntl } from 'react-intl-phraseapp'
import {
  getClosestStreetCoordForWaypoint,
  routeActions,
  useDispatchAndComputeRoute,
} from '../../lib'
import { Button } from '../buttons'
import classes from './ContextMenu.module.scss'
import './ContextMenu.global.scss'
import { UnisizeAdd } from '../../assets'

const { addStop } = routeActions

export function StopButton({ location, waypointTitle, onStopSet }) {
  const dispatchAndComputeRoute = useDispatchAndComputeRoute()
  const { formatMessage } = useIntl()

  const addStopAndClose = async () => {
    const nextLocation = await getClosestStreetCoordForWaypoint(location).catch(
      () => location,
    )

    dispatchAndComputeRoute(
      addStop({
        lat: nextLocation.lat,
        lng: nextLocation.lng,
        title: waypointTitle,
      }),
    )

    onStopSet()
  }

  return (
    <Button size="small" className={classes.button} onClick={addStopAndClose}>
      <UnisizeAdd className={classes.buttonIcon} />
      <p className={classes.buttonText}>
        {formatMessage({
          id: 'contextmenu.stop',
          defaultMessage: 'stop',
        })}
      </p>
    </Button>
  )
}

StopButton.defaultProps = {
  location: {
    lat: 0,
    lng: 0,
  },
}

StopButton.propTypes = {
  waypointTitle: PropTypes.string.isRequired,
  onStopSet: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}
